import { Experience } from '@/app/experience/Experience'

export const common = {
  action: {
    add: 'Ajouter',
    close: 'Fermer',
    delete: 'Effacer',
    save: 'Sauvegarder',
    reserve: 'Réserver',
    reserveUrl: 'https://booking.libroreserve.com/237ea82c7a071c5?affiliate-code=WEB&lang=fr',
  },
  seo: {
    title: 'Le District Gourmet',
    description:
      'Gastronomie française, asiatique, méditerranéenne et saveurs locales. Prêt-à-manger, boulangerie, pâtisserie, boucherie',
    baseUrl: 'https://ledistrictgourmet.ca',
    image: '/share-image.png',
  },
  feature: {
    soon: '(Bientôt)',
  },
  experience: {
    title: 'Expérience',
    [Experience.CHEF]: 'CHEF',
    [Experience.DG]: 'DG',
  },
  footer: {
    termsOfUse: 'Conditions d’utilisation',
    copyright: '© {{ value, datetime }} Capwood',
    privacyPolicy: 'Politique de confidentialité',
    lifeStyle: 'Un milieu de vie',
  },
  login: 'Connexion',
  welcome: 'Bienvenue',
  nav: {
    main: {
      drink_eat: 'Boire et manger',
      market: 'Le marché',
      experience: 'Expérience',
      events: 'Événements',
      qg: 'Le QG',
    },
    util: {
      lang: 'Langue',
      profile: 'Profil',
    },
    exploreTerroirs: 'Explorer les terroirs',
  },
  schedule: {
    closed: 'Fermé',
    dailySchedule: 'Horaire du jour',
    onsite: 'Expérience Chef',
    takeout: 'Pour emporter',
    takeoutAndDelivery: 'Pour emporter et livraison',
    list: '{{val, list}}',
    slots: '{{open}}\u2009à\u2009{{close}}',
  },
  user: {
    profile: {
      action: {
        complete: 'Complétez votre profil',
        login: 'Connectez-vous',
      },
      content: {
        apartmentNumber: 'Nᵒ app',
        city: 'Ville',
        civicNumber: 'Nᵒ civique',
        email: 'Courriel',
        firstName: 'Prénom',
        isResident: 'Je réside au QG Sainte-Foy',
        lastName: 'Nom de famille',
        phoneNumber: 'Téléphone',
        postalCode: 'Code postal',
        streetAddress: 'Rue',
        subscribed:
          'Je désire recevoir par courriel, les offres, les concours et les récompenses réservés aux\n' +
          'membres de la communauté du QG Sainte-Foy',
        instructions: 'Veuillez compléter les champs requis avant de sauvegarder.',
      },
      error: {
        description: 'Un problème est survenu, veuillez réessayer.',
        invalid: 'Champ invalide',
        required: 'Champ requis',
        title: 'Erreur',
      },
      header: {
        title: 'Profil',
      },
    },
  },
  success: {
    itemAddedToCart: {
      title: 'Item ajouté au panier',
      description: '',
    },
  },
  warning: {
    outsideOperatingHours: {
      banner:
        'Nous sommes présentement en dehors des heures d’ouverture. Vous ne pouvez pas ajouter d’item au panier pour l’instant.',
      toast: {
        title: 'Hors des heures d’ouverture',
        description: 'Vous ne pouvez pas ajouter cet item pour l’instant.',
      },
    },
  },
}
