export enum AlertVariants {
  success = 'success',
  warning = 'warning',
}

export const Alert = {
  variants: {
    [AlertVariants.success]: {
      container: {
        bg: 'peche.200',
        paddingRight: { base: '4', md: '40' },
      },
      title: {
        fontWeight: '700',
        lineHeight: 6,
        color: 'bordeaux.700',
      },
      description: {
        lineHeight: 6,
        color: 'bordeaux.700',
      },
      icon: {
        color: 'bordeaux.700',
      },
    },
    [AlertVariants.warning]: {
      container: {
        bg: 'peche.200',
        paddingRight: { base: '4', md: '40' },
      },
      title: {
        fontWeight: '700',
        lineHeight: 6,
        color: 'bordeaux.700',
      },
      description: {
        lineHeight: 6,
        color: 'bordeaux.700',
      },
      icon: {
        color: 'bordeaux.700',
      },
    },
  },
}
