import * as React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { useOnClosePushHistory } from '@district-qg/frontend-common'

import { useMenuExperience } from '@/app/experience/ExperienceContext'
import { ON_SITE_ROUTES } from '@/routes'
import { ReactComponent as MobileLogo } from '@/ui/assets/images/le-qg-cadre.svg'
import { CloseButton } from '@/ui/components/CloseButton/CloseButton'
import { Header } from '@/ui/organisms/header/Header'
import { OnSiteCartButton } from '@/ui/pages/onsite/cart/components/OnSiteCartButton'
import { OnSiteRefreshCartButton } from '@/ui/pages/onsite/cart/components/OnSiteRefreshCartButton'
import { OnSiteRefreshCartDialog } from '@/ui/pages/onsite/cart/components/OnSiteRefreshCartDialog'
import { OnSiteCartPage } from '@/ui/pages/onsite/cart/OnSiteCartPage'
import { OnSiteCategoryPage } from '@/ui/pages/onsite/category/OnSiteCategoryPage'
import { OnSiteHomePage } from '@/ui/pages/onsite/OnSiteHomePage'

import { ExperienceSelector } from './experienceSelector/ExperienceSelector'

const buttonStyles = {
  WebkitTapHighlightColor: 'transparent',
  ':active': {
    background: 'transparent',
  },
}

export const OnSitePageTemplate: React.FC = () => {
  const isCartPage = useRouteMatch({ path: ON_SITE_ROUTES.CART, exact: false })
  const navigateHome = useOnClosePushHistory(ON_SITE_ROUTES.HOME)
  const { isOpen: isRefreshDialogOpen, onOpen: refreshDialogOnOpen, onClose: refreshDialogOnClose } = useDisclosure()
  const {
    isOpen: isExperienceSelectorOpen,
    onOpen: experienceSelectorOnOpen,
    onClose: experienceSelectorOnClose,
  } = useDisclosure()

  const experienceDGPage = useRouteMatch({ path: ON_SITE_ROUTES.EXPERIENCE_DG, exact: false })
  const experienceChefPage = useRouteMatch({ path: ON_SITE_ROUTES.EXPERIENCE_CHEF, exact: false })
  const { setDGExperience, setChefExperience } = useMenuExperience()

  React.useEffect(() => {
    if (experienceDGPage || experienceChefPage) {
      if (experienceChefPage) {
        const params = experienceChefPage.params as unknown as { categoryId: string }
        setChefExperience(params.categoryId)
      } else {
        setDGExperience()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Flex direction='column' minHeight='100vh'>
        <Header zIndex='10' padding={{ base: '4', md: '12' }} breakPointQGLogo={{ base: MobileLogo }}>
          <Box
            as='button'
            onDoubleClick={experienceSelectorOnOpen}
            w={24}
            h={16}
            sx={buttonStyles}
            data-testid='show-experience-selector'
          />
          <OnSiteRefreshCartButton onClick={refreshDialogOnOpen} />
          {isCartPage ? (
            <Box px={1}>
              <CloseButton onClick={navigateHome} />
            </Box>
          ) : (
            <OnSiteCartButton isInverted />
          )}
        </Header>
        <OnSiteRefreshCartDialog isOpen={isRefreshDialogOpen} onClose={refreshDialogOnClose} />
        <Switch>
          <Route path={ON_SITE_ROUTES.CART} component={OnSiteCartPage} />
          <>
            <Route path={ON_SITE_ROUTES.HOME} component={OnSiteHomePage} />
            <Route path={ON_SITE_ROUTES.CATEGORY} component={OnSiteCategoryPage} />
            <Route path={ON_SITE_ROUTES.SECTION_GROUP} component={OnSiteCategoryPage} />
          </>
        </Switch>
      </Flex>
      <ExperienceSelector isOpen={isExperienceSelectorOpen} onClose={experienceSelectorOnClose} />
    </>
  )
}
