import {
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { Site } from '@/app/context/Site'
import { useCurrentSite } from '@/app/context/SiteContext'
import { Experience } from '@/app/experience/Experience'
import { useMenuExperience } from '@/app/experience/ExperienceContext'
import { ItemDto } from '@/app/item/Item.dto'
import { buildModel, Item } from '@/app/item/Item.model'

export type UseItemsQueryOptions = UseAxiosQueryOptions<ItemDto[]>

export enum ItemFilter {
  DG = 'dg',
  CHEF = 'chef',
  ONLINE = 'online',
}

export const useItemsQuery = ({ ...options }: UseItemsQueryOptions = {}): UseQueryExtendedResult<
  ReadonlyArray<Item>,
  ItemDto[]
> => {
  const site = useCurrentSite()

  const { experience } = useMenuExperience()

  let filter: ItemFilter

  if (site === Site.ONLINE) {
    filter = ItemFilter.ONLINE
  } else if (experience === Experience.CHEF) {
    filter = ItemFilter.CHEF
  } else {
    filter = ItemFilter.DG
  }

  const mapper = useModelBuilderWithCurrentLocale(buildModel)

  return useQuery({
    path: '/v1/items',
    params: { filter },
    queryKey: ['items', filter],
    requireAuthentication: false,
    mapper,
    ...options,
  })
}
