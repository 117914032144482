import * as React from 'react'

import { Box, BoxProps, Divider, VStack } from '@chakra-ui/react'

export type ModalItemListProps = {
  outerDividers?: boolean
} & BoxProps

export const ModalItemListDivider: React.FC = () => <Divider borderColor='gray.800' />

export const ModalItemList: React.FC<ModalItemListProps> = ({ outerDividers, children, ...props }) => {
  return (
    <Box {...props} pb={{ base: 24, md: 0 }}>
      {outerDividers && <ModalItemListDivider />}
      <VStack divider={<ModalItemListDivider />} spacing='0' align='stretch'>
        {children}
      </VStack>
      {outerDividers && <ModalItemListDivider />}
    </Box>
  )
}
