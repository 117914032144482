import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import { Box, Button, Center, Flex, Spinner, Text, VStack } from '@chakra-ui/react'

import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'
import { OrderTimeRange } from '@/app/qg/QGConfig.model'
import { User } from '@/app/user/User.model'
import { ONLINE_ROUTES } from '@/routes'
import { OrderExpectedTimeInput } from '@/ui/pages/cart/components/form/OrderExpectedTimeInput'
import { OrderTypeSelector } from '@/ui/pages/cart/components/form/OrderTypeSelector'
import { PlaceOrderClientInformation } from '@/ui/pages/cart/components/form/PlaceOrderClientInformation'
import { ButtonVariants } from '@/ui/theme/components/button'

export type PlaceOrderFormProps = {
  deliveryEnabled: boolean
  user: User
  onSubmit: (request: PlaceOrderRequest) => void
  isPlacingOrder: boolean
  orderTimeRanges?: OrderTimeRange[]
}

export const PlaceOrderForm: React.FC<PlaceOrderFormProps> = ({ deliveryEnabled, orderTimeRanges, user }) => {
  const { t } = useTranslation(['cart'])

  return (
    <>
      <Flex justify='center' alignItems='centered' py='4'>
        <PlaceOrderClientInformation user={user} />
      </Flex>
      <VStack justify='stretch' backgroundColor='steel.700' color='peche.200' py='8' spacing='4'>
        {!_isEmpty(orderTimeRanges) && <OrderTypeSelector deliveryDisabled={!(user.isResident && deliveryEnabled)} />}
        <Center minH='4.25rem'>
          {_isUndefined(orderTimeRanges) && <Spinner />}
          {!_isUndefined(orderTimeRanges) &&
            (orderTimeRanges.length ? (
              <OrderExpectedTimeInput orderTimeRanges={orderTimeRanges} />
            ) : (
              <Box px={5} textAlign='center'>
                <Text fontWeight='700' color='error.dark'>
                  {t('cart:order.unavailableItemsError.title')}
                </Text>
                <Text>{t('cart:content.orderTimeRange.unavailableToday')}</Text>
                <Box mt='4'>
                  <Button variant={ButtonVariants.outlineOnDark} as={RouterLink} to={ONLINE_ROUTES.HOME}>
                    {t('cart:content.orderTimeRange.explore')}
                  </Button>
                </Box>
              </Box>
            ))}
        </Center>
      </VStack>
    </>
  )
}
