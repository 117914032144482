import React from 'react'
import { useCart } from 'react-use-cart'

import { Badge, BadgeProps } from '@chakra-ui/react'

export const CartTotalItemBadge: React.FC<BadgeProps> = ({ ...props }) => {
  const { totalItems } = useCart()

  if (totalItems === 0) return null

  return (
    <Badge
      alignItems='center'
      bg='creme.50'
      borderRadius='50%'
      boxShadow='0px 1px 3px rgba(0, 0, 0, 0.7)'
      color='aubergine.800'
      display='flex'
      fontSize='12'
      fontWeight='700'
      h='6'
      justifyContent='center'
      position='absolute'
      right='-10px'
      size='xs'
      top='-8px'
      w='6'
      {...props}
    >
      {totalItems}
    </Badge>
  )
}
