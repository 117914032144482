export enum NumberInputVariants {
  onLight = 'onLight',
  onDark = 'onDark',
}

export const NumberInput = {
  sizes: {
    xs: {
      field: { borderRadius: 'base' },
    },
    sm: {
      field: { borderRadius: 'base' },
    },
    md: {
      field: { borderRadius: 'base' },
    },
    lg: {
      field: { borderRadius: 'base' },
    },
  },
  variants: {
    onLight: {
      field: {
        border: '1px solid',
        borderColor: 'aubergine.400',
        bg: 'transparent',
        color: 'aubergine.400',
        _hover: {
          bg: 'whiteAlpha.700',
        },
        _placeholder: {
          color: 'aubergine.200',
        },
        _readOnly: {
          bg: 'creme.200',
          borderColor: 'creme.200',
          color: 'aubergine.500',
        },
        _disabled: {
          bg: 'creme.100',
          opacity: '.5',
        },
        _invalid: {
          borderColor: 'error.dark',
        },
        _focus: {
          bg: 'whiteAlpha.700',
        },
      },
      // addon: {
      // }
    },
    onDark: {
      field: {
        border: '1px solid',
        borderColor: 'peche.200',
        bg: 'transparent',
        color: 'peche.300',
        _hover: {
          bg: 'whiteAlpha.200',
        },
        _placeholder: {
          color: 'peche.200',
        },
        _readOnly: {
          bg: 'gray.800',
          borderColor: 'gray.800',
          color: 'peche.300',
        },
        _disabled: {
          bg: 'whiteAlpha.200',
          opacity: '.5',
        },
        _invalid: {
          bg: 'red.900',
          borderColor: 'error.dark',
        },
        _focus: {
          bg: 'whiteAlpha.200',
        },
      },
      // addon: {
      // }
    },
  },
  defaultProps: {
    variant: 'onLight',
  },
}
