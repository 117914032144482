export const payment = {
  error: { title: 'Erreur' },
  payment: 'Faire le paiement',
  success: 'Le paiement a été reçu avec succès',
  transactionNumber: 'N° de transaction',
  amount: {
    title: 'Montant',
    value: '{{ amount, currency(currency: CAD; currencyDisplay: narrowSymbol;) }}',
  },
}
