import React from 'react'
import { useTranslation } from 'react-i18next'

import _flatMapDeep from 'lodash/flatMapDeep'
import {
  Button,
  Center,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  SimpleGrid,
  Spinner,
  UseModalProps,
} from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { Experience } from '@/app/experience/Experience'
import { useMenuExperience } from '@/app/experience/ExperienceContext'
import { ItemFilter } from '@/app/item/useItemsQuery'
import { QG } from '@/app/qg/QG.model'
import { useQGQuery } from '@/app/qg/useQGQuery'
import { Modal, ModalContent, ModalOverlay } from '@/ui/components/modal/Modal'

export type ExperienceSelectorProps = UseModalProps

export const ExperienceSelector: React.FC<ExperienceSelectorProps> = ({ ...modalProps }) => {
  const { t } = useTranslation('common')
  const { data: qg } = useQGQuery({ itemFilter: ItemFilter.CHEF })
  const { setDGExperience, setChefExperience } = useMenuExperience()

  const getRestaurants = (Qg: QG): Category[] =>
    _flatMapDeep(Qg.zones.map((zone) => zone.sections.map((section) => section.categories.map((category) => category))))

  return (
    <Modal autoFocus={false} scrollBehavior='inside' {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        {qg ? (
          <ModalBody px={16} pt={8} pb={16}>
            <SimpleGrid columns={2} columnGap={16} rowGap={12}>
              <Button
                variant='solidOnLight'
                onClick={() => {
                  setDGExperience()
                  modalProps.onClose()
                }}
                data-testid='experience-qg'
              >
                {t('experience.title')} {t(`experience.${Experience.DG}`)}
              </Button>
            </SimpleGrid>
            <SimpleGrid columns={2} columnGap={16} rowGap={12} mt={16}>
              {getRestaurants(qg).map((restaurant) => (
                <Button
                  variant='solidOnDark'
                  key={restaurant.id}
                  onClick={() => {
                    setChefExperience(restaurant.id)
                    modalProps.onClose()
                  }}
                  data-testid='experience-chef'
                >
                  {restaurant.title}
                </Button>
              ))}
            </SimpleGrid>
          </ModalBody>
        ) : (
          <ModalBody display='flex'>
            <Center flex={1}>
              <Spinner />
            </Center>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}
