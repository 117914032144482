import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonProps } from '@chakra-ui/react'

import { useLoginWithRedirect } from '@/app/auth/useLoginWithRedirect'
import { ModalActionButton } from '@/ui/components/modal/ModalActionButton'

export const LoginButton: React.FC<Omit<ButtonProps, 'onClick'>> = ({ ...props }) => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useLoginWithRedirect(window.location.href)

  return (
    <ModalActionButton onClick={loginWithRedirect} {...props}>
      {t('user.profile.action.login')}
    </ModalActionButton>
  )
}
