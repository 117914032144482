import { useMemo } from 'react'
import { QueryFunction, QueryKey, useQuery as useQueryImpl, UseQueryOptions, UseQueryResult } from 'react-query'

import axios, { AxiosError } from 'axios'
import _isFunction from 'lodash/isFunction'
import { captureException } from '@sentry/react'

import { computeSentryFingerprint } from './computeSentryFingerPrint'
import { useAxiosRequestConfigBuilder } from './useAxiosRequestConfigBuilder'

export type UseAxiosQueryOptions<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TError = AxiosError
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'>

export type UseQueryExtendedOptions<
  Model,
  DTO,
  TQueryFnData = DTO,
  TQueryKey extends QueryKey = QueryKey,
  TError = AxiosError
> = UseAxiosQueryOptions<TQueryFnData, DTO, TQueryKey, TError> & {
  readonly mapper: (dto: DTO) => Model
  readonly path: string | (() => string)
  readonly params?: Record<string, unknown>
  readonly requireAuthentication?: boolean
  readonly expectedStatus?: number
}

export type UseQueryExtendedResult<Model, DTO, TError = AxiosError> = Omit<UseQueryResult<DTO, TError>, 'data'> & {
  readonly dto?: DTO
  readonly data?: Model
}

export function useQuery<Model, DTO, TQueryFnData = DTO, TError = AxiosError, TQueryKey extends QueryKey = QueryKey>({
  mapper,
  path,
  params,
  requireAuthentication = true,
  expectedStatus = 200,
  onError,
  ...options
}: UseQueryExtendedOptions<Model, DTO, TQueryFnData, TQueryKey, TError>): UseQueryExtendedResult<Model, DTO, TError> {
  const configBuilder = useAxiosRequestConfigBuilder({
    requireAuthentication,
    expectedStatus,
  })
  const url = _isFunction(path) ? path() : path
  const queryFn: QueryFunction<TQueryFnData> = async () => {
    const config = await configBuilder({ method: 'GET', url, params })
    const response = await axios.request<TQueryFnData>(config)
    return response.data
  }

  const onErrorWithSentryCapture = (error: TError) => {
    captureException(error, (scope) =>
      scope
        .setFingerprint(computeSentryFingerprint('get', url, options.queryKey))
        .setTag('query', 'query')
        .setContext('query', { params, expectedStatus, requireAuthentication })
    )
    return onError?.(error)
  }

  const defaultQuery = path as TQueryKey
  const result = useQueryImpl({
    queryKey: defaultQuery,
    ...options,
    queryFn,
    onError: onErrorWithSentryCapture,
  })
  const data = useMemo(() => result.data && mapper(result.data), [mapper, result.data])
  return { ...result, dto: result.data, data }
}
