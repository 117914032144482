import * as React from 'react'

import { Box } from '@chakra-ui/react'
import { QGImage, QGImageType } from '@district-qg/ui'

import { Item } from '@/app/item/Item.model'

export type GroupItemImageProps = {
  image: Item['mainImage']
}

export const GroupItemImage: React.FC<GroupItemImageProps> = ({ image }) => (
  <Box boxSize={{ base: '16', xs: '16', sm: '16', md: '32' }} borderRadius='base' overflow='hidden'>
    <QGImage
      type={QGImageType.Item}
      id={image.id}
      altText={image.altText}
      widthPxAtBreakpoints={{ base: 64, sm: 64, md: 128 }}
      ratioAtBreakpoints={[1]}
    />
  </Box>
)
