// eslint-disable-next-line import/no-extraneous-dependencies

export enum ButtonVariants {
  creme = 'creme',
  ghostOnDark = 'ghostOnDark',
  ghostOnLight = 'ghostOnLight',
  linkOnDark = 'linkOnDark',
  linkOnLight = 'linkOnLight',
  outlineOnDark = 'outlineOnDark',
  outlineFadeOnDark = 'outlineFadeOnDark',
  outlineOnLight = 'outlineOnLight',
  solidOnDark = 'solidOnDark',
  solidOnLight = 'solidOnLight',
}

export const Button = {
  baseStyle: {
    borderRadius: 'base',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  sizes: {
    xs: {
      fontSize: '.5rem',
    },
    sm: {
      fontSize: '.625rem',
      px: 4,
      h: 8,
      minW: 8,
    },
    md: {
      fontSize: '.75rem',
      px: 8,
      h: 12,
      minW: 12,
    },
    lg: {
      fontSize: '1rem',
    },
  },
  variants: {
    [ButtonVariants.solidOnLight]: {
      bg: 'peche.200',
      border: '1px solid',
      borderColor: 'peche.200',
      color: 'steel.700',
      _disabled: {
        bg: 'creme.200',
        borderColor: 'creme.200',
        color: 'peche.700',
      },
      _active: {
        bg: 'steel.200',
        borderColor: 'steel.200',
        color: 'steel.700',
      },
      _hover: {
        bg: 'steel.200',
        borderColor: 'steel.200',
        color: 'steel.700',
        _disabled: {
          bg: 'creme.200',
          borderColor: 'creme.200',
          color: 'peche.700',
        },
      },
    },
    [ButtonVariants.creme]: {
      bgColor: 'creme.50',
      color: 'aubergine.400',
      _disabled: {
        bg: 'creme.200',
        color: 'aubergine.700',
      },
      _active: {
        bgColor: 'creme.50',
        color: 'steel.700',
      },
      _hover: {
        bg: 'gray.100',
        color: 'steel.700',
        _disabled: {
          bg: 'creme.200',
          color: 'aubergine.700',
        },
      },
    },
    [ButtonVariants.solidOnDark]: {
      bg: 'steel.200',
      border: '1px solid',
      borderColor: 'steel.200',
      color: 'steel.700',
      _disabled: {
        bg: 'steel.500',
        borderColor: 'steel.500',
        color: 'steel.100',
      },
      _active: {
        bg: 'peche.200',
        borderColor: 'peche.200',
        color: 'steel.700',
      },
      _hover: {
        bg: 'peche.200',
        borderColor: 'peche.200',
        border: '1px solid',
        color: 'steel.700',
        _disabled: {
          bg: 'steel.500',
          borderColor: 'steel.500',
          color: 'steel.100',
        },
      },
    },
    [ButtonVariants.outlineOnLight]: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'steel.500',
      color: 'steel.500',
      _disabled: {
        borderColor: 'steel.200',
        color: 'steel.400',
      },
      _active: {
        bg: 'steel.500',
        borderColor: 'steel.500',
        color: 'bg.light',
      },
      _hover: {
        bg: 'steel.500',
        borderColor: 'steel.500',
        color: 'bg.light',
        _disabled: {
          borderColor: 'steel.200',
          color: 'steel.400',
        },
      },
    },
    [ButtonVariants.outlineOnDark]: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'peche.200',
      color: 'peche.200',
      _disabled: {
        borderColor: 'steel.200',
        color: 'steel.200',
      },
      _active: {
        bg: 'peche.200',
        borderColor: 'peche.200',
        color: 'bg.dark',
      },
      _hover: {
        bg: 'peche.200',
        borderColor: 'peche.200',
        color: 'bg.dark',
        _disabled: {
          borderColor: 'steel.200',
          color: 'steel.200',
        },
      },
    },
    [ButtonVariants.outlineFadeOnDark]: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'steel.200',
      color: 'steel.200',
      opacity: '0.4',
    },
    [ButtonVariants.ghostOnLight]: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      color: 'aubergine.500',
      _disabled: {
        color: 'aubergine.400',
      },
      _active: {
        bg: 'creme.200',
        color: 'aubergine.500',
      },
      _hover: {
        bg: 'creme.200',
        color: 'aubergine.500',
        _disabled: {
          bg: 'transparent',
          color: 'aubergine.400',
        },
      },
    },
    [ButtonVariants.ghostOnDark]: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      color: 'steel.200',
      _disabled: {
        color: 'steel.200',
      },
      _active: {
        bg: 'steel.700',
        color: 'steel.200',
      },
      _hover: {
        bg: 'steel.700',
        color: 'steel.200',
        _disabled: {
          bg: 'transparent',
          color: 'aubergine.200',
        },
      },
    },
    [ButtonVariants.linkOnLight]: {
      px: 0,
      height: 'auto',
      minHeight: 0,
      minWidth: 0,
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      borderWidth: 0,
      color: 'aubergine.500',
      _disabled: {
        color: 'aubergine.400',
      },
      _active: {
        color: 'aubergine.500',
      },
      _hover: {
        color: 'aubergine.500',
        _disabled: {
          color: 'aubergine.400',
        },
      },
    },
    [ButtonVariants.linkOnDark]: {
      px: 0,
      height: 'auto',
      minHeight: 0,
      minWidth: 0,
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      borderWidth: 0,
      color: 'steel.200',
      _disabled: {
        color: 'steel.200',
      },
      _active: {
        color: 'steel.200',
      },
      _hover: {
        color: 'steel.200',
        _disabled: {
          color: 'aubergine.200',
        },
      },
    },
  },
}
