import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export type SeoProps = {
  title?: string
  description?: string
  url?: string
  imageUrl?: string
}

export const Seo: React.FC<SeoProps> = ({ title = '', description = '', url = '', imageUrl = '' }) => {
  const { t, i18n } = useTranslation()

  const seoLang = i18n.language.substring(0, 2)
  const seoTitle = title ? `${title} : ${t('seo.title')}` : t('seo.title')
  const seoDescription = description || t('seo.description')
  const seoUrl = url ? t('seo.baseUrl') + url : t('seo.baseUrl')
  const seoImage = `${
    imageUrl || t('seo.baseUrl') + t('seo.image')
  }?dpr=1&w=1200&h=630&fit=crop&crop=focalpoint,entropy`

  return (
    <Helmet>
      <html lang={seoLang} />
      <title>{seoTitle}</title>
      <link rel='canonical' href={seoUrl} />

      <link rel='icon' href='/favicon.ico' />
      <link rel='icon' href='/icon.svg' type='image/svg+xml' />
      <link rel='apple-touch-icon' href='/apple-touch-icon.png' />

      <meta name='description' content={seoDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:locale' content={seoLang} />
      <meta property='og:title' content={seoTitle} />
      <meta property='og:description' content={seoDescription} />
      <meta property='og:url' content={seoUrl} />
      <meta property='og:image' content={`${seoImage}`} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />

      <meta name='twitter:text:title' content={seoTitle} />
      <meta name='twitter:image' content={`${seoImage}`} />
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  )
}
