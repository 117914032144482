import { useEffect } from 'react'
import { set } from 'react-ga'

import { useAuth0 } from '@auth0/auth0-react'

export function useAuth0UserWithAnalytic(): void {
  const { isLoading, isAuthenticated, user } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      set({ userId: isAuthenticated ? user?.sub : null })
    }
  }, [isLoading, isAuthenticated, user])
}
