import React from 'react'

import { Button, ButtonProps } from '@chakra-ui/react'

import { ButtonVariants } from '@/ui/theme/components/button'

export const ModalActionButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Button variant={ButtonVariants.solidOnDark} {...rest}>
    {children}
  </Button>
)
