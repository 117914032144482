import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from '@chakra-ui/react'
import { ClearIcon } from '@district-qg/ui'

export const PayFactoModalCloseButton: React.FC = () => {
  const { t } = useTranslation()
  return (
    <IconButton
      position='absolute'
      top='var(--chakra-space-4)'
      right='var(--chakra-space-4)'
      borderRadius='var(--chakra-radii-full)'
      padding={0}
      background='var(--chakra-colors-steel-700)'
      height='var(--chakra-sizes-8)'
      minWidth='var(--chakra-sizes-8)'
      aria-label={t('action.close')}
      icon={<ClearIcon margin='auto' color='white' boxSize='var(--chakra-sizes-6)' />}
      transitionProperty='var(--chakra-transition-property-common)'
      transitionDuration='var(--chakra-transition-duration-normal)'
      _hover={{ background: 'var(--chakra-colors-steel-600)' }}
    />
  )
}
