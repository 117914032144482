import React from 'react'
import { event } from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { ClearIcon } from '@district-qg/ui'

import { Item } from '@/app/item/Item.model'

export type DeleteCartItemButtonProps = {
  item: Item
}

export const DeleteCartItemButton: React.FC<DeleteCartItemButtonProps> = ({ item }) => {
  const { t } = useTranslation()
  const { getItem, updateItemQuantity } = useCart()

  const deleteItem = () => {
    const { quantity = 1 } = getItem(item.id)
    updateItemQuantity(item.id, quantity - 1)
    event({
      category: 'Cart Item',
      action: 'Delete',
    })
  }

  return (
    <ClearIcon
      color='peche.200'
      onClick={deleteItem}
      _hover={{ cursor: 'pointer' }}
      data-testid='DeleteCartItemButton'
      aria-label={t('action.delete')}
      float='right'
    />
  )
}
