import dayjs from 'dayjs'
import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { OrderRequest } from './Cart.model'
import { OrderDto } from './Order.dto'

export type PlaceOrderWithPaymentMutationRequest = OrderRequest & {
  readonly expectedDateTime?: Date
}

export type PlaceOrderWithPaymentMutationOptions = UseAxiosMutationOptions<
  OrderDto,
  PlaceOrderWithPaymentMutationRequest
>

export function usePlaceOrderWithPaymentMutation(
  options?: PlaceOrderWithPaymentMutationOptions
): UseMutationExtendedResult<OrderDto, PlaceOrderWithPaymentMutationRequest> {
  return useMutation({
    ...options,
    method: 'post',
    path: '/v1/orders',
    requireAuthentication: true,
    requestDataBuilder: ({ expectedDateTime, ...data }) => ({
      expectedDateTime: expectedDateTime && dayjs(expectedDateTime).toISOString(),
      ...data,
    }),
  })
}
