import React from 'react'
import { IconType } from 'react-icons'
import { BiBoltCircle, BiImageAdd, BiImageAlt } from 'react-icons/bi'
import { BsQuestionSquare, BsSearch } from 'react-icons/bs'
import { CgPlayListCheck } from 'react-icons/cg'
import { FaRegCalendarCheck, FaUserCircle } from 'react-icons/fa'
import { FiChevronRight, FiClock, FiInbox, FiMail, FiMapPin, FiPhone, FiUploadCloud } from 'react-icons/fi'
import { HiMenu } from 'react-icons/hi'
import { ImAirplane, ImCart } from 'react-icons/im'
import { IoRefreshCircle } from 'react-icons/io5'
import {
  MdAdd,
  MdAssignmentTurnedIn,
  MdClear,
  MdDragHandle,
  MdHelpOutline,
  MdLens,
  MdRemove,
  MdRemoveShoppingCart,
} from 'react-icons/md'

import { Icon, IconProps as ChakraIconProps } from '@chakra-ui/react'

export type IconProps = Omit<ChakraIconProps, 'as' | 'icon-name' | 'css'>

const icon =
  (as: IconType): React.FC<IconProps> =>
  // eslint-disable-next-line react/display-name
  (props) =>
    <Icon as={as} icon-name={as.name} {...props} />

// don't forget to add each icon component to mock: /frontend/shared/jest-mock-component.js

export const AddIcon = icon(MdAdd)
export const AddImageIcon = icon(BiImageAdd)
export const AirplaneIcon = icon(ImAirplane)
export const BurgerIcon = icon(HiMenu)
export const CalendarCheckIcon = icon(FaRegCalendarCheck)
export const CartIcon = icon(ImCart)
export const ChevronRightIcon = icon(FiChevronRight)
export const ClearIcon = icon(MdClear)
export const ClockIcon = icon(FiClock)
export const ConfigurationIcon = icon(BiBoltCircle)
export const DragHandleIcon = icon(MdDragHandle)
export const EllipseIcon = icon(MdLens)
export const EmptyCartIcon = icon(MdRemoveShoppingCart)
export const FileUploadCloudIcon = icon(FiUploadCloud)
export const QuestionMarkIcon = icon(MdHelpOutline)
export const SelectionIcon = icon(MdAssignmentTurnedIn)
export const ReloadIcon = icon(IoRefreshCircle)
export const ImageIcon = icon(BiImageAlt)
export const InboxIcon = icon(FiInbox)
export const ListCheckIcon = icon(CgPlayListCheck)
export const MailIcon = icon(FiMail)
export const MapIcon = icon(FiMapPin)
export const NotFoundImageIcon = icon(BsQuestionSquare)
export const RemoveIcon = icon(MdRemove)
export const SearchIcon = icon(BsSearch)
export const TelephoneIcon = icon(FiPhone)
export const UserIcon = icon(FaUserCircle)
