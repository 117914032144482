import * as React from 'react'

import { IconButton, IconButtonProps } from '@chakra-ui/react'

import { ButtonVariants } from '@/ui/theme/components/button'

type FooterIconButtonProps = {
  icon: IconButtonProps['icon']
  ariaLabel: IconButtonProps['aria-label']
}

export const FooterIconButton: React.FC<FooterIconButtonProps> = ({ icon, ariaLabel }) => (
  <IconButton
    variant={ButtonVariants.creme}
    borderRadius='base'
    icon={icon}
    aria-label={ariaLabel}
    h='9'
    w='10'
    minWidth='10'
  />
)
