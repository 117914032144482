import * as React from 'react'

import { Box } from '@chakra-ui/react'
import { QGImage, QGImageType } from '@district-qg/ui'
import { QGImageOverlay } from '@district-qg/ui/src/imgix/QGImageOverlay'

import { Section } from '@/app/section/Section.model'
import { RestaurantFlix } from '@/ui/components/flix/RestaurantFlix'

export type RestaurantSectionProps = {
  section: Section
}

export const RestaurantSection: React.FC<RestaurantSectionProps> = ({ section }) => {
  return (
    <Box position='relative' pt={16} pb={8}>
      <QGImage
        type={QGImageType.SectionBackground}
        id={section.imageId}
        altText={section.name}
        isBackground
        imageOverlay={QGImageOverlay.Background}
        data-testid='zone-restaurant'
      />

      <Box position='relative'>
        {section.categories.map((category) => (
          <Box mt={8} mb={10} key={category.id} data-testid='category'>
            <RestaurantFlix category={category} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
