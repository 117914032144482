import { useMemo } from 'react'
import { event } from 'react-ga'
import { useCart } from 'react-use-cart'

import { useAppFeatures, useCurrentLocale } from '@district-qg/frontend-common'

import { useCartItems } from '@/app/item/useCartItems'
import { OrderTimeRange } from '@/app/qg/QGConfig.model'
import { useQGCategoriesOperatingHoursQuery } from '@/app/qg/useQGCategoriesOperatingHoursQuery'

import { buildCartModel, OrderRequest } from './Cart.model'
import { DraftOrder } from './DraftOrder.model'
import { OrderDto } from './Order.dto'
import { buildOrderModel, Order } from './Order.model'
import { PlaceOrderMutationRequest, usePlaceOrderMutation, UsePlaceOrderMutationOptions } from './usePlaceOrderMutation'
import {
  PlaceOrderWithPaymentMutationRequest,
  usePlaceOrderWithPaymentMutation,
} from './usePlaceOrderWithPaymentMutation'
import { usePrepareDraftOrder } from './usePrepareDraftOrder'

export type PlaceOrderRequest = Omit<PlaceOrderMutationRequest, 'items'>

type UsePlaceOrderOptions = Omit<UsePlaceOrderMutationOptions, 'onSuccess'> & {
  type: OrderRequest['type']
  gratuity?: OrderRequest['gratuity']
  expectedDateTime?: OrderRequest['expectedDateTime']
  onSuccess?: (
    data: void | OrderDto,
    variables: PlaceOrderMutationRequest | PlaceOrderWithPaymentMutationRequest,
    context: unknown
  ) => void
}

export function usePlaceOrder({
  type,
  gratuity,
  expectedDateTime,
  onSuccess,
  onError,
  ...options
}: UsePlaceOrderOptions): {
  isLoadingDraftOrder: boolean
  isPlacingOrder: boolean
  draftOrder?: DraftOrder
  refreshDraftOrder: (expectedDateTime?: Date) => void
  placeOrder(request: PlaceOrderRequest): Promise<void | OrderDto>
  order?: Order
  orderTimeRanges?: OrderTimeRange[]
} {
  const locale = useCurrentLocale()
  const { items: cartItems, emptyCart } = useCart()
  const { paymentEnabled } = useAppFeatures()
  const cartModel = useMemo(() => buildCartModel(cartItems), [cartItems])

  const items = useCartItems()
  const categoryIds = items.filter((item) => !!item.categoryId).map((item) => item.categoryId as string)
  const { data: orderTimeRanges } = useQGCategoriesOperatingHoursQuery({
    categoryIds,
    keepPreviousData: true,
  })

  const {
    isLoading: isLoadingDraftOrder,
    draftOrder,
    forceRefresh,
  } = usePrepareDraftOrder({ type, gratuity, expectedDateTime, ...cartModel })

  const { mutateAsync, isLoading: isLoadingPlaceOrder } = usePlaceOrderMutation({
    ...options,
    onSuccess: (data, variables, context) => {
      event({
        category: 'Order',
        action: 'Confirm',
      })
      emptyCart()
      return onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      forceRefresh()
      return onError?.(error, variables, context)
    },
  })

  const {
    mutateAsync: mutateAsyncPayment,
    isLoading: isLoadingPlaceOrderWithPayment,
    data: orderDto,
  } = usePlaceOrderWithPaymentMutation({
    onSuccess: (data, variables, context) => {
      event({
        category: 'Order',
        action: 'Confirm',
      })
      return onSuccess?.(data, variables, context)
    },
    onError: (error, variables, context) => {
      forceRefresh()
      return onError?.(error, variables, context)
    },
  })

  const placeOrder = (form: Pick<PlaceOrderMutationRequest, 'expectedDateTime'>) => {
    if (paymentEnabled) {
      return mutateAsyncPayment({ type, gratuity, ...form, ...cartModel })
    }
    return mutateAsync({ type, gratuity, ...form, ...cartModel })
  }

  return {
    isLoadingDraftOrder,
    isPlacingOrder: isLoadingPlaceOrderWithPayment || isLoadingPlaceOrder,
    draftOrder,
    refreshDraftOrder: forceRefresh,
    placeOrder,
    order: orderDto && buildOrderModel(orderDto, locale),
    orderTimeRanges,
  }
}
