import * as React from 'react'

import { Text } from '@chakra-ui/react'

import { ModalItemList } from '@/ui/components/modal/ModalItemList'

export type CartMessageProps = {
  message: string
}

// TODO: decouple from ModalItemList
export const CartMessage: React.FC<CartMessageProps> = ({ message }) => (
  <ModalItemList outerDividers py='8'>
    <Text color='peche.200' textAlign='center' py='8'>
      {message}
    </Text>
  </ModalItemList>
)
