import { MutationFunction, useMutation as useMutationImpl, UseMutationOptions, UseMutationResult } from 'react-query'

import axios, { AxiosError, Method } from 'axios'
import _isString from 'lodash/isString'
import { captureException } from '@sentry/react'

import { computeSentryFingerprint } from './computeSentryFingerPrint'
import { useAxiosRequestConfigBuilder } from './useAxiosRequestConfigBuilder'

// TData = Type of the response body
// TVariables = Data type you pass to the mutation
// TRequestBody = Type of the request body
// TContext = Type of onMutate return

export type UseAxiosMutationOptions<TData = unknown, TVariables = void, TContext = unknown, TError = AxiosError> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
>

export type UseMutationExtendedOptions<
  TData = unknown,
  TVariables = void,
  TRequestBody = TVariables,
  TContext = unknown,
  TError = AxiosError
> = UseAxiosMutationOptions<TData, TVariables, TContext, TError> & {
  readonly path: string | ((variables: TVariables) => string)
  readonly requireAuthentication?: boolean
  readonly expectedStatus?: number
  readonly method?: Method
  readonly requestDataBuilder?: (variables: TVariables) => TRequestBody
}

export type UseMutationExtendedResult<
  TData = unknown,
  TVariables = void,
  TContext = unknown,
  TError = AxiosError
> = UseMutationResult<TData, TError, TVariables, TContext>

export function useMutation<
  TData = unknown,
  TVariables = void,
  TRequestBody = TVariables,
  TContext = unknown,
  TError = AxiosError
>({
  path,
  requireAuthentication = true,
  expectedStatus = 200,
  method = 'post',
  requestDataBuilder,
  onError,
  ...options
}: UseMutationExtendedOptions<TData, TVariables, TRequestBody, TContext, TError>): UseMutationExtendedResult<
  TData,
  TVariables,
  TContext,
  TError
> {
  const configBuilder = useAxiosRequestConfigBuilder({
    requireAuthentication,
    expectedStatus,
  })

  const computeUrl = (variables: TVariables) => (_isString(path) ? path : path(variables))
  const onErrorWithSentryCapture: UseMutationExtendedOptions<
    TData,
    TVariables,
    TRequestBody,
    TContext,
    TError
  >['onError'] = (error, variables, context) => {
    captureException(error, (scope) =>
      scope
        .setFingerprint(computeSentryFingerprint(method, computeUrl(variables), options.mutationKey))
        .setTag('query', 'mutation')
        .setContext('mutation', { variables, context, method, expectedStatus, requireAuthentication })
    )
    return onError?.(error, variables, context)
  }

  const mutationFn: MutationFunction<TData, TVariables> = async (variables) => {
    const data = requestDataBuilder ? requestDataBuilder(variables) : variables
    const config = await configBuilder({ method, url: computeUrl(variables), data })
    const response = await axios.request<TData>(config)
    return response.data
  }

  return useMutationImpl(mutationFn, { ...options, onError: onErrorWithSentryCapture })
}
