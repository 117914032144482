import * as React from 'react'

import { IconButton } from '@chakra-ui/react'
import { UserIcon } from '@district-qg/ui'

type ProfileIconButtonProps = {
  isAuthenticated?: boolean
  onClick: () => void
}

export const ProfileIconButton: React.FC<ProfileIconButtonProps> = ({ isAuthenticated, onClick }) => {
  return (
    <IconButton
      aria-label='profile-button'
      border='none'
      icon={
        <UserIcon
          w='6'
          h='auto'
          minW='0'
          aria-label='profile-button'
          data-testid='profileUserIcon'
          fill={isAuthenticated ? 'aubergine.dark' : 'aubergine.300'}
          _hover={{ fill: 'aubergine.700' }}
        />
      }
      variant='ghost'
      onClick={onClick}
      size='sm'
      isRound
      _hover={{ background: 'steel.100' }}
    />
  )
}
