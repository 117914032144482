import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { ArtisanDto } from '@/app/artisan/Artisan.dto'

export interface Artisan {
  readonly id: string
  readonly name: string
  readonly description: string
}

export class ArtisanModel implements Artisan {
  constructor(private readonly data: Readonly<ArtisanDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get description(): string {
    return localize(this.data.description, this.locale)
  }
}
