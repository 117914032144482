import { useEffect } from 'react'

import { useAuth0, User as Aut0User } from '@auth0/auth0-react'
import { captureException, setUser, User as SentryUser } from '@sentry/react'

function convertUser(user: Aut0User): SentryUser {
  return {
    email: user.email,
    username: user.preferred_username ?? user.name,
  }
}

export function useAuth0UserWithSentry(): void {
  const { isLoading, isAuthenticated, user, error } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      setUser(isAuthenticated && user ? convertUser(user) : null)
    }
  }, [isLoading, isAuthenticated, user])

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])
}
