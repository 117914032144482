import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'
import uniqueId from 'lodash/uniqueId'
import { useEventListener } from '@chakra-ui/react'
import { captureException, captureMessage, startTransaction } from '@sentry/react'

import './payFactoModalStyle.css'

import { iframeStyle } from '@/app/context/payFacto/payFactoIframeStyle'
import { PayFactoModalCloseButton } from '@/app/context/payFacto/PayFactoModalCloseButton'
import { PayFactoReturnCodes } from '@/app/context/payFacto/PayFactoReturnCodes'
import { PayfactoTransactionError } from '@/app/context/payFacto/PayfactoTransactionError'
import { usePayFactoConfiguration } from '@/app/context/payFacto/usePayFactoConfiguration'
import { Order } from '@/app/order/Order.model'
import { PayOrderResponse, usePayOrderMutation } from '@/app/order/usePayOrderMutation'
import { usePaymentSessionMutation } from '@/app/payment/usePaymentSessionMutation'

export type DisplayModalOptions = {
  data: Order
}

export type PayFactoContextData = {
  displayPayFactoModal: (order: Order) => unknown
  isOpen: boolean
  payOrderResponse?: PayOrderResponse
  isPaymentLoading: boolean
}

const PayFactoContext = React.createContext<PayFactoContextData>({
  displayPayFactoModal: /* istanbul ignore next */ () => {},
  isOpen: false,
  isPaymentLoading: false,
})

function isPayFactoError(error: unknown): error is PayFactoError {
  return 'errorDescription' in (error as never)
}

export type PayFactoPaymentTokenResponse = { token: string; returnCode: string; errorDescription?: string }

export type PayFactoProviderProps = {
  readonly onSuccess: () => void
  readonly onError: (error: Error | AxiosError) => void
}

export const PayFactoProvider: React.FC<PayFactoProviderProps> = ({ children, onError, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false)
  const payFactoConfiguration = usePayFactoConfiguration()
  const { i18n, t } = useTranslation('payfacto')

  useEventListener('keyup', (e) => {
    if (e.code === 'Escape') {
      setIsOpen(false)
    }
  })

  const {
    mutate: payOrder,
    isLoading: isPaymentLoading,
    data: payOrderResponse,
  } = usePayOrderMutation({
    onSuccess: () => {
      onSuccess()
    },
    onError,
  })

  const { mutateAsync: initializePaymentSession } = usePaymentSessionMutation({
    onSuccess: async (paymentSession) => {
      if (!PayFacto.initialized) {
        await PayFacto.init(paymentSession.paymentSecureId, {
          apiUrl: paymentSession.paymentApiBaseUrl,
          iframeUrl: paymentSession.paymentIframeBaseUrl,
          lang: i18n.language,
        })
      } else {
        PayFacto.secureID = paymentSession.paymentSecureId
      }
    },
  })

  const onPayFactoTokenResponse = (payFactoPaymentTokenResponse: PayFactoPaymentTokenResponse, order: Order) => {
    payOrder({ paymentToken: payFactoPaymentTokenResponse.token, orderId: order.id })
    const closeButton = document.getElementsByClassName('pf-modal-close-button')[0] as HTMLElement
    /* istanbul ignore next */
    if (closeButton) {
      closeButton.click()
    }
    setIsOpen(false)
  }

  const validateTokenResponse = (tokenResponse: PayFactoPaymentTokenResponse): void => {
    if (tokenResponse.returnCode.trim() !== PayFactoReturnCodes.TransactionApproved) {
      throw new PayfactoTransactionError('Payfacto transaction not approved', tokenResponse)
    }
  }

  const displayPayFactoModal = async (order: Order): Promise<void> => {
    const transaction = startTransaction({ name: 'PayFacto' })

    const transactionId = uniqueId('transaction')
    PayFacto.transactionId = transactionId
    await initializePaymentSession()

    const modal = PayFacto.modal(async (tokenPromise: Promise<PayFactoPaymentTokenResponse>) => {
      // this prevents potential deleterious multiple callback invocations from PayFacto modal (ex: with autoClose set to true)
      if (PayFacto.transactionId !== transactionId) {
        return
      }
      try {
        const tokenResponse = await tokenPromise
        validateTokenResponse(tokenResponse)
        onPayFactoTokenResponse(tokenResponse, order)
      } catch (e) {
        /* istanbul ignore else */
        if (isPayFactoError(e)) {
          captureMessage(e.errorDescription, (scope) => scope.setContext('payfactoResponse', e as never))
        } else {
          captureException(e)
        }
        modal.showError(t('errorMessage'))
      } finally {
        transaction.finish()
      }
    }, payFactoConfiguration(order.invoice.totalPrice))

    const closeButton = document.getElementsByClassName('pf-modal-close-button')[0]
    const orderTotalAmount = document.getElementsByClassName('pf-modal-header-subtitle')[0]
    const modalContent = document.getElementsByClassName('pf-modal-content')[0]
    /* istanbul ignore next */
    if (closeButton) {
      closeButton.addEventListener('click', () => setIsOpen(false))
      closeButton.innerHTML = ReactDOMServer.renderToStaticMarkup(<PayFactoModalCloseButton />)
    }
    /* istanbul ignore next */
    if (orderTotalAmount && modalContent) {
      modalContent.appendChild(orderTotalAmount)
    }
    setTimeout(() => setIsOpen(true), 200)
    return modal
  }

  return (
    <PayFactoContext.Provider
      value={{
        displayPayFactoModal,
        isOpen,
        isPaymentLoading,
        payOrderResponse,
      }}
    >
      <Helmet>
        <style id='payfacto-secure-fields-custom-style'>{iframeStyle()}</style>
      </Helmet>
      {children}
    </PayFactoContext.Provider>
  )
}

export const usePayFacto = (): PayFactoContextData => React.useContext(PayFactoContext)
