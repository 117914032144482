import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { OrderRequest } from './Cart.model'
import { DraftOrderDto } from './DraftOrder.dto'

export function usePrepareDraftOrderMutation(): UseMutationExtendedResult<DraftOrderDto, OrderRequest> {
  return useMutation({
    path: '/v1/orders/cart',
    requireAuthentication: false,
  })
}
