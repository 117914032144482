import * as React from 'react'

import { useOnClosePushHistory } from '@district-qg/frontend-common'

import { ONLINE_ROUTES } from '@/routes'
import { ProfileModal } from '@/ui/pages/profile/components/ProfileModal'

export const ProfilePage: React.FC = () => {
  const onCloseGoTo = useOnClosePushHistory(ONLINE_ROUTES.HOME)

  return <ProfileModal isOpen onClose={onCloseGoTo} />
}
