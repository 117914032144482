import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { formatPrice } from '@district-qg/frontend-common'

import { Site } from '@/app/context/Site'
import { useCurrentSite } from '@/app/context/SiteContext'
import { Item } from '@/app/item/Item.model'
import { OperatingHoursSchedule } from '@/app/types/operatingHours'

import { AddItemToCartButton } from './AddItemToCartButton'
import { GroupItemImage } from './GroupItemImage'

export type GroupItemProps = {
  item: Item
  categoryId: string
}

const defaultItemPadding = { base: '6', md: '8' }
const defaultItemDescriptionBoxMargin = { base: '4', md: '6' }
const defaultItemNameFontSize = { base: 'md', md: 'xl' }
const defaultItemPriceFontSize = { base: 'md', md: 'xl' }

export const GroupItem: React.FC<GroupItemProps> = ({ item, categoryId }) => {
  const { t } = useTranslation(['cart', 'common'])
  const { items } = useCart()
  const site = useCurrentSite()

  const getTranslatedOnlineSchedule = (dailySchedule: OperatingHoursSchedule[]): string => {
    if (dailySchedule.length === 0) {
      return t('cart:content.item.unavailableForTheDay')
    }
    return `${t('cart:content.item.availableFor')} ${t('common:schedule.list', {
      val: dailySchedule.map((schedule) =>
        t('common:schedule.slots', { returnObjects: true, open: schedule.open, close: schedule.close })
      ),
    })}`
  }

  const itemIsOutOfStock = () => {
    if (site === Site.ONSITE) return false

    if (item.stockManagement.active) {
      if (item.stockManagement.availableQuantity <= 0) return true

      const currentItemQuantity = items.find((x) => x.id === item.id)?.quantity
      return currentItemQuantity ? currentItemQuantity >= item.stockManagement.availableQuantity : false
    }
    return false
  }

  return (
    <Flex paddingBottom={defaultItemPadding} paddingTop={defaultItemPadding}>
      <GroupItemImage image={item.mainImage} />
      <HStack flex='1' spacing='0'>
        <VStack
          align='stretch'
          spacing='0.5'
          margin='auto'
          flex='1'
          marginLeft={defaultItemDescriptionBoxMargin}
          marginRight={defaultItemDescriptionBoxMargin}
        >
          <Flex flexWrap='wrap'>
            <Heading as='h3' fontSize={defaultItemNameFontSize} color='header.light' marginRight='4'>
              {item.name}
            </Heading>
            {site === Site.ONLINE && item.stockManagement.active && (
              <Text color='error.dark' fontSize='14' fontWeight='bold' lineHeight='5'>
                {itemIsOutOfStock() ? t('cart:content.item.unavailable') : t('cart:content.item.underStockManagement')}
              </Text>
            )}
          </Flex>
          <Text color='text.light' fontSize={defaultItemPriceFontSize}>
            {formatPrice(item.price.amount)}
          </Text>
          {item.description ? (
            <Text color='text.light' fontSize='xs'>
              {item.description}
            </Text>
          ) : null}
          {site === Site.ONLINE && item.operatingHours ? (
            <Text color='header.light' fontSize='xs'>
              {getTranslatedOnlineSchedule(item.dailySchedule)}
            </Text>
          ) : null}
        </VStack>
        {!itemIsOutOfStock() && <AddItemToCartButton item={item} categoryId={categoryId} />}
      </HStack>
    </Flex>
  )
}
