import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { ItemDto, Price } from '@/app/item/Item.dto'
import { extractCurrentOperatingSchedules } from '@/app/operatingHours/extractCurrentOperatingSchedules'

import { ItemOperatingHours, OperatingHoursSchedule } from '../types/operatingHours'

export interface Item {
  readonly isActiveOnline: boolean
  readonly categoryId?: string
  readonly createdAt: Date
  readonly id: string
  readonly name: string
  readonly description: string
  readonly price: Price
  readonly mainImage: {
    readonly id?: string
    readonly altText: string
  }
  readonly new: boolean
  readonly groupIds: string[]
  readonly stockManagement: ItemStockManagement
  readonly availabilities: ItemAvailabilities
  readonly operatingHours: ItemOperatingHours | null
  readonly dailySchedule: OperatingHoursSchedule[]
}

export type ItemStockManagement =
  | {
      readonly active: true
      readonly totalQuantity: number
      readonly availableQuantity: number
      readonly updatedAt?: Date
    }
  | {
      readonly active: false
      readonly totalQuantity?: number
      readonly availableQuantity?: number
      readonly updatedAt?: Date
    }

export type ItemAvailabilities = {
  startDate: Date | null
  endDate: Date | null
}

export class ItemModel implements Item {
  readonly createdAt = new Date(this.data.createdAt)

  readonly stockManagement = {
    ...this.data.stockManagement,
  }

  constructor(protected readonly data: Readonly<ItemDto>, protected readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get isActiveOnline(): boolean {
    return this.data.isActiveOnline
  }

  get new(): boolean {
    return this.data.new
  }

  get name(): string {
    return localize([this.data.name, this.data.external.name], this.locale)
  }

  get description(): string {
    return localize(this.data.description, this.locale)
  }

  get price(): Price {
    return this.data.external.price
  }

  get source(): string {
    return this.data.external.source
  }

  get categoryId(): string | undefined {
    return this.data.categoryId
  }

  get groupIds(): string[] {
    return this.data.groupIds ?? []
  }

  get availabilities(): ItemAvailabilities {
    return {
      startDate: this.data.availabilities.startDate ? new Date(this.data.availabilities.startDate) : null,
      endDate: this.data.availabilities.endDate ? new Date(this.data.availabilities.endDate) : null,
    }
  }

  get dailySchedule(): OperatingHoursSchedule[] {
    return extractCurrentOperatingSchedules(this.data.operatingHours)
  }

  get operatingHours(): ItemOperatingHours | null {
    return this.data.operatingHours
  }

  mainImage = {
    id: this.data.mainImage?.id,
    altText: localize([this.data.mainImage?.altText, this.data.name], this.locale),
  }
}

export function buildModel(data: Readonly<ItemDto>, locale: SupportedLocales): Item
export function buildModel(data: Readonly<ItemDto>[], locale: SupportedLocales): Item[]
export function buildModel(data: Readonly<ItemDto>[] | Readonly<ItemDto>, locale: SupportedLocales): Item[] | Item {
  return Array.isArray(data) ? data.map((item) => buildModel(item, locale)) : new ItemModel(data, locale)
}
