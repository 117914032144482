import * as React from 'react'

import { Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { formatPrice } from '@district-qg/frontend-common'

import { useSite } from '@/app/context/useSite'
import { OrderItem } from '@/app/order/OrderItem.model'

import { CartItemErrorMessage } from './CartItemErrorMessage'
import { CartItemImage } from './CartItemImage'
import { DeleteCartItemButton } from './DeleteCartItemButton'

export type CartItemProps = {
  orderItem: OrderItem
}

export const cartItemDefaultPadding = '8'

export const CartItem: React.FC<CartItemProps> = ({ orderItem }) => {
  const itemUnavailableText = useSite({
    onsite: null,
    online: (
      <CartItemErrorMessage
        error={orderItem.error}
        itemDailySchedule={orderItem.dailySchedule}
        categoryDailySchedule={orderItem.categoryDailySchedule}
      />
    ),
  })

  const formatPriceAndDeleteButton = useSite({
    onsite: (
      <>
        <Text color='creme.50' size='sm' lineHeight='shorter'>
          {formatPrice(orderItem.price.amount)}
        </Text>
        <DeleteCartItemButton item={orderItem} />
      </>
    ),
    online: (
      <>
        <Text
          color={orderItem.error ? 'error.dark' : 'creme.50'}
          textDecoration={orderItem.error ? 'line-through' : ''}
          size='sm'
          lineHeight='shorter'
        >
          {formatPrice(orderItem.price.amount)}
        </Text>
        <DeleteCartItemButton item={orderItem} />
      </>
    ),
  })

  return (
    <Flex py={cartItemDefaultPadding}>
      <CartItemImage image={orderItem.mainImage} />
      <HStack align='flex-start' flex='1' spacing='0'>
        <VStack align='stretch' spacing='1' flex='1' mx='4'>
          <HStack spacing='4' align='baseline'>
            <Heading as='h3' size='sm' color='peche.200'>
              {orderItem.name}
            </Heading>
            {itemUnavailableText}
          </HStack>
          <Text color='creme.50' fontSize='12'>
            {orderItem.description}
          </Text>
        </VStack>
        <VStack align='flex-end' spacing='1'>
          {formatPriceAndDeleteButton}
        </VStack>
      </HStack>
    </Flex>
  )
}
