import * as React from 'react'

import { Flex, Heading } from '@chakra-ui/react'
import { QGImage, QGImageType } from '@district-qg/ui'
import { QGImageOverlay } from '@district-qg/ui/src/imgix/QGImageOverlay'

import { Group } from '@/app/group/Group.model'

export type GroupTileProps = {
  group: Group
  imageSize: string
}

export const GroupTile: React.FC<GroupTileProps> = ({ group, imageSize }) => {
  return (
    <Flex alignItems='flex-end' position='relative' boxSize={imageSize}>
      <QGImage
        type={QGImageType.Group}
        id={group.imageId}
        altText={group.name}
        widthPxAtBreakpoints={[192]}
        ratioAtBreakpoints={[1]}
        isBackground
        imageOverlay={QGImageOverlay.Tile}
      />
      <Heading position='relative' fontSize='28' fontWeight='700' color='creme.50' p={2}>
        {group.name}
      </Heading>
    </Flex>
  )
}
