import * as React from 'react'

import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { useOnClosePushHistory } from '@district-qg/frontend-common'
import { ChevronRightIcon } from '@district-qg/ui'

import { User } from '@/app/user/User.model'
import { ONLINE_ROUTES, PROFILE_PAGE_PATH } from '@/routes'

type PlaceOrderClientInformationProps = {
  user: User
}

function userFullName(user: User) {
  return `${user.firstName}\u00A0${user.lastName}`
}

function userAddress(user: User) {
  if (user.isResident) {
    return `QG\u00A0Condo\u00A0${user.address.apartmentNumber}`
  }
  return `${user.address.civicNumber}\u00A0${user.address.streetAddress}${
    user.address.apartmentNumber ? ` (${user.address.apartmentNumber})` : ''
  }`
}

export const PlaceOrderClientInformation: React.FC<PlaceOrderClientInformationProps> = ({ user }) => {
  const onCloseGoTo = useOnClosePushHistory(PROFILE_PAGE_PATH, { onCloseGoTo: ONLINE_ROUTES.CART })

  return (
    <Flex
      pl='4'
      justify='center'
      align='center'
      width='20rem'
      onClick={onCloseGoTo}
      cursor='pointer'
      color='creme.50'
      _hover={{
        color: 'peche.200',
      }}
    >
      <Flex direction='column' justify='flex-start'>
        <Text fontWeight='bold'>{userFullName(user)}</Text>
        <Box>{userAddress(user)}</Box>
      </Flex>
      <Spacer />
      <ChevronRightIcon color='peche.200' h='8' w='8' />
    </Flex>
  )
}
