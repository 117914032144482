import dayjs from 'dayjs'
import _isEmpty from 'lodash/isEmpty'

import { QGConfigDto } from '@/app/qg/QGConfig.dto'

export type OrderTimeRange = {
  readonly start: Date
  readonly end: Date
}

export interface QGConfig {
  readonly deliveryEnabled: boolean
  readonly isClosed: boolean
  readonly orderTimeRanges: OrderTimeRange[]
  readonly earliestOrderTime: Date
  readonly latestOrderTime?: Date
}

export class QGConfigModel implements QGConfig {
  readonly deliveryEnabled = this.data.deliveryEnabled

  readonly isClosed = _isEmpty(this.data.orderTimeRanges)

  get orderTimeRanges(): OrderTimeRange[] {
    return this.data.orderTimeRanges.map((timeRange) => ({
      start: dayjs(timeRange.start).toDate(),
      end: dayjs(timeRange.end).toDate(),
    }))
  }

  get earliestOrderTime(): Date {
    return dayjs(this.data.earliestOrderTime).toDate()
  }

  get latestOrderTime(): Date | undefined {
    return this.data.latestOrderTime ? dayjs(this.data.latestOrderTime).toDate() : undefined
  }

  constructor(private readonly data: Readonly<QGConfigDto>) {}
}

export function buildModel(data: QGConfigDto): QGConfig {
  return new QGConfigModel(data)
}
