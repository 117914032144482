import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Flex, Heading } from '@chakra-ui/react'

import { useAppPaths } from '@/app/context/useAppPaths'
import { useSite } from '@/app/context/useSite'
import { useSelectedSection } from '@/app/selection/useSelectedSection'
import { ZoneType } from '@/app/zone/ZoneType'
import { DESTINATION_SELECTOR_HEIGHT } from '@/constants'
import { RestaurantSection } from '@/ui/components/zone/RestaurantSection'

const sectionMenuStyles = {
  height: 'var(--destination-selector-height)',
  mb: 'calc(var(--destination-selector-height) * -1)',
  px: { base: 0, md: 4 },
  alignItems: 'stretch',
  bgColor: 'overlay.black.60',
  left: 0,
  right: 0,
  zIndex: 2,
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollSnapType: 'x proximity',

  _after: {
    content: '""',
    width: '50vw',
    maxWidth: '10rem',
    flexShrink: 0,
  },
}

const sectionAnchorStyles = {
  display: 'flex',
  alignItems: 'center',
  px: 4,
  scrollSnapAlign: 'start',
  cursor: 'pointer',

  color: 'creme.50',
  '&.active': {
    color: 'peche.200',
  },
  '@media (hover: hover)': {
    _hover: { color: 'peche.200' },
  },
}

export const RestaurantZoneDesktop: React.FC = () => {
  const data = useSelectedSection(ZoneType.restaurant)
  const { push } = useHistory()
  const { sectionPath } = useAppPaths()
  const isOnline = useSite({ online: true, onsite: false })

  if (!data.zone) return null
  const { zone, section: selectedSection } = data

  return (
    <Box
      position='relative'
      sx={{
        '--destination-selector-height': `${DESTINATION_SELECTOR_HEIGHT}px`,
      }}
    >
      <Box
        position={isOnline ? { base: 'absolute', md: 'relative' } : 'relative'}
        top={0}
        height={{ base: 'calc(100% - var(--destination-selector-height))', md: 'auto' }}
        width='100%'
      >
        <Flex
          className='scrollbar-thin'
          sx={sectionMenuStyles}
          position={isOnline ? { base: 'sticky', md: 'absolute' } : 'relative'}
          top={isOnline ? { base: 'var(--header-height)', md: 0 } : 0}
        >
          {zone.sections.map((section, index) => {
            return (
              <Box
                key={section.id}
                data-testid={`section-tab-${index}`}
                className={section.id === selectedSection.id ? 'active' : ''}
                sx={sectionAnchorStyles}
                onClick={
                  /* istanbul ignore next */
                  () => push(sectionPath(section.id))
                }
              >
                <Heading whiteSpace='nowrap' fontSize='xl'>
                  {section.name}
                </Heading>
              </Box>
            )
          })}
        </Flex>
      </Box>

      <RestaurantSection section={selectedSection} />
    </Box>
  )
}
