import { matchPath } from 'react-router-dom'

import { History } from 'history'
import { BrowserOptions, reactRouterV5Instrumentation } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export function sentryConfig(
  history: History,
  routes: string[],
  {
    VITE_SENTRY_DSN,
    MODE,
    VITE_SENTRY_RELEASE,
  }: { VITE_SENTRY_DSN: string; MODE: string; VITE_SENTRY_RELEASE?: string }
): BrowserOptions {
  if (!VITE_SENTRY_RELEASE) {
    return {}
  }

  return {
    dsn: VITE_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(
          history,
          routes.map((path) => ({ path })),
          matchPath
        ),
      }),
    ],
    environment: MODE === 'development' ? 'dev' : MODE,
    release: VITE_SENTRY_RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
  }
}
