import * as React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Box, HStack, Link, StackProps } from '@chakra-ui/react'
import { SPACE } from '@district-qg/frontend-common'

import { Category } from '@/app/category/Category.model'
import { useAppPaths } from '@/app/context/useAppPaths'
import { GroupTile } from '@/ui/components/flix/GroupTile'

export type FlixGroupSliderProps = {
  category: Category
  itemBorderColor?: string
} & StackProps

export const FlixGroupSlider: React.FC<FlixGroupSliderProps> = ({
  category,
  itemBorderColor = 'transparent',
  px = 0,
  ...props
}) => {
  const { sectionGroupPath } = useAppPaths()

  const imageSize = '48'

  // Box hack below required for Safari compat

  return (
    <HStack spacing={0} pb={3} overflowX='auto' className='scrollbar-thin'>
      <Box flexShrink={0} w={px}>
        {SPACE.HAIR}
      </Box>
      <HStack spacing={8} {...props}>
        {category.groups.map((group) => (
          <Link
            as={ReactRouterLink}
            key={group.id}
            to={sectionGroupPath(category.sectionId, group.id)}
            display='block'
            overflow='hidden'
            border='1px solid'
            borderRadius='base'
            borderColor={itemBorderColor}
            flexShrink={0}
          >
            <GroupTile group={group} imageSize={imageSize} />
          </Link>
        ))}
      </HStack>
      <Box flexShrink={0} w={px}>
        {SPACE.HAIR}
      </Box>
    </HStack>
  )
}
