import React from 'react'

import { useBreakpointValue } from '@chakra-ui/react'

import { ZoneListDesktop } from './ZoneListDesktop'
import { ZoneListMobile } from './ZoneListMobile'

export const ZoneList: React.FC = () => {
  const Component = useBreakpointValue({
    base: ZoneListMobile,
    xs: ZoneListMobile,
    sm: ZoneListMobile,
    md: ZoneListDesktop,
  })
  if (!Component) {
    return null
  }
  return <Component />
}
