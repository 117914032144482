import React from 'react'

import { RestaurantZoneDesktop } from '@/ui/components/zone/RestaurantZoneDesktop'

import { Footer } from './components/Footer'

export const OnSiteHomePage: React.FC = () => {
  return (
    <>
      <RestaurantZoneDesktop />
      <Footer />
    </>
  )
}
