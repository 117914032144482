import dayjs from 'dayjs'

import { Day } from '../types/day'
import { ExceptionOperatingHours, OperatingHoursSchedule, RegularOperatingHours } from '../types/operatingHours'

export const EXCEPTIONS_DATE_FORMAT = 'YYYY-MM-DD'
const REGULAR_WEEK_DAY_FORMAT = 'ddd'

export const extractCurrentOperatingSchedules = (
  operatingHours?: {
    regular: RegularOperatingHours
    exceptions?: ExceptionOperatingHours
  } | null
): OperatingHoursSchedule[] => {
  if (!operatingHours) return []

  const today = dayjs()
  const exceptionKey = today.format(EXCEPTIONS_DATE_FORMAT)
  const regularKey = today.format(REGULAR_WEEK_DAY_FORMAT).toLowerCase() as Day

  if (!operatingHours.exceptions) return operatingHours.regular[regularKey]

  return operatingHours.exceptions[exceptionKey] ?? operatingHours.regular[regularKey]
}
