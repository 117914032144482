import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Heading } from '@chakra-ui/react'

import { useSite } from '@/app/context/useSite'

export const CartTitle: React.FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation(useSite({ onsite: 'selection', online: 'cart' }))

  return (
    <Box marginTop={10} marginBottom={4} padding={4} {...props}>
      <Heading textAlign='center' size='3xl' color='peche.200'>
        {t('header.title')}
      </Heading>
    </Box>
  )
}
