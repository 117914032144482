import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { IconButton, Link } from '@chakra-ui/react'
import { CartIcon } from '@district-qg/ui'

import { ONLINE_ROUTES } from '@/routes'
import { CartTotalItemBadge } from '@/ui/components/cartButton/CartTotalItemBadge'
import { ButtonVariants } from '@/ui/theme/components/button'

const iconProps = {
  _groupHover: { color: 'aubergine.base' },
  color: 'aubergine.400',
  w: '6',
  h: 'auto',
  marginRight: '3px',
}
const badgeProps = {
  _groupHover: { bg: 'peche.200' },
  bg: 'steel.100',
  color: 'steel.700',
}

export const OnlineCartButton: React.FC = () => {
  const { t } = useTranslation('cart')

  return (
    <Link as={RouterLink} to={ONLINE_ROUTES.CART}>
      <IconButton
        role='group'
        variant={ButtonVariants.creme}
        borderRadius='base'
        icon={
          <>
            <CartIcon {...iconProps} />
            <CartTotalItemBadge {...badgeProps} />
          </>
        }
        aria-label={t('title')}
        h='9'
        minW='9'
        _hover={{ background: 'steel.100' }}
      />
    </Link>
  )
}
