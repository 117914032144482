import React from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import i18n from 'i18next'

import * as en from '@/ui/locales/en'
import * as fr from '@/ui/locales/fr'

export const defaultNS = 'common'
export const resources = {
  fr,
  en,
}

export const init18n = (): void => {
  i18n
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      fallbackLng: 'fr',
      defaultNS,
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    })

  dayjs.locale('fr')
}

export const useSyncDocumentLocale = (): void => {
  const { i18n: i18nValue } = useTranslation()

  React.useEffect(() => {
    // set lang attribute on html element
    document.documentElement.lang = i18nValue.language
  }, [i18nValue.language])
}

export { i18n }
