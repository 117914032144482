import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import _find from 'lodash/find'
import _flatMap from 'lodash/flatMap'
import _flatMapDeep from 'lodash/flatMapDeep'
import _map from 'lodash/map'
import { captureException, Severity } from '@sentry/react'

import { Category } from '@/app/category/Category.model'
import { useLoadingQG } from '@/app/context/QGContext'
import { useSite } from '@/app/context/useSite'
import { Group } from '@/app/group/Group.model'
import { QG } from '@/app/qg/QG.model'
import { Section } from '@/app/section/Section.model'
import { Zone } from '@/app/zone/Zone.model'
import { ZoneType } from '@/app/zone/ZoneType'
import { ON_SITE_ROUTES, ONLINE_ROUTES } from '@/routes'

export type Selection =
  | {
      readonly group: Group
      readonly category: Category
      readonly section: Section
      readonly zone: Zone
      readonly zoneType: ZoneType
    }
  | {
      readonly group: undefined
      readonly category: undefined
      readonly section: Section
      readonly zone: Zone
      readonly zoneType: ZoneType
    }
  | {
      readonly group: undefined
      readonly category: undefined
      readonly section: undefined
      readonly zone: undefined
      readonly zoneType: ZoneType
    }
  | {
      readonly group: Group
      readonly category: Category
      readonly section: Section
      readonly zone: undefined
      readonly zoneType: undefined
    }
  | {
      readonly group: undefined
      readonly category: undefined
      readonly section: undefined
      readonly zone: undefined
      readonly zoneType: undefined
    }

export const EMPTY_SELECTION = {
  group: undefined,
  category: undefined,
  section: undefined,
  zone: undefined,
  zoneType: undefined,
}

const SelectionContext = React.createContext<Selection>(EMPTY_SELECTION)

function findZoneSectionGroup(
  qg: QG,
  { sectionId, groupId }: { sectionId: string; groupId: string }
): { group: Group; category: Category; section: Section; zone: Zone; zoneType: ZoneType } {
  const [selected] = _flatMap(qg.zones, (zone) =>
    _flatMap(
      zone.sections.filter(({ id }) => id === sectionId),
      (section) =>
        _flatMap(section.categories, (category) =>
          _flatMap(
            category.groups.filter(({ id }) => id === groupId),
            (group) => ({
              group,
              category,
              section,
              zone,
              zoneType: zone.type,
            })
          )
        )
    )
  )
  if (!selected) {
    throw new Error(`Invalid selected group ${groupId}`)
  }
  return selected
}

function findZoneSection(
  qg: QG,
  { sectionId }: { sectionId: string }
): { section: Section; zone: Zone; zoneType: ZoneType } {
  const [selected] = _flatMap(qg.zones, (zone) =>
    _map(
      zone.sections.filter(({ id }) => id === sectionId),
      (section) => ({ section, zone, zoneType: zone.type })
    )
  )
  if (!selected) {
    throw new Error(`Invalid selected section ${sectionId}`)
  }
  return selected
}

function findZoneType(qg: QG, { zoneType }: { zoneType: string }): { zoneType: ZoneType } {
  if (!qg.zones.some(({ type }) => type === zoneType)) {
    throw new Error(`Invalid selected zoneType ${zoneType}`)
  }
  return { zoneType: zoneType as ZoneType }
}

function findCategory(
  qg: QG,
  { categoryId }: { categoryId: string }
): { category: Category; group: Group; section: Section } {
  const restaurants: Category[] = _flatMapDeep(
    qg.zones.map((zone) => zone.sections.map((section) => section.categories.map((category) => category)))
  )
  const category = _find(restaurants, { id: categoryId })

  if (!category) {
    throw new Error(`Invalid selected categoryId ${categoryId}`)
  }

  const zoneSection = findZoneSection(qg, { sectionId: category.sectionId })
  return { category, group: category.groups[0], section: zoneSection.section }
}

export const SelectionProvider: React.FC = ({ children }) => {
  const {
    HOME: homePath,
    SECTION_GROUP: sectionGroupPath,
    SECTION: sectionPath,
    ZONE: zonePath,
    CATEGORY: categoryPath,
  } = useSite({
    online: ONLINE_ROUTES,
    onsite: ON_SITE_ROUTES,
  })

  const { replace } = useHistory()
  const data = useLoadingQG()
  const sectionGroupMatch = useRouteMatch<{ sectionId: string; groupId: string }>(sectionGroupPath)
  const sectionMatch = useRouteMatch<{ sectionId: string }>(sectionPath)
  const zoneMatch = useRouteMatch<{ zoneType: string }>(zonePath)
  const categoryMatch = useRouteMatch<{ categoryId: string }>(categoryPath)

  function contextValue(): Selection {
    if (!data.isReady) {
      return EMPTY_SELECTION
    }
    try {
      if (sectionGroupMatch) {
        return {
          ...sectionGroupMatch.params,
          ...findZoneSectionGroup(data.qg, sectionGroupMatch.params),
        }
      }
      if (sectionMatch) {
        return {
          ...EMPTY_SELECTION,
          ...sectionMatch.params,
          ...findZoneSection(data.qg, sectionMatch.params),
        }
      }
      if (zoneMatch) {
        return {
          ...EMPTY_SELECTION,
          ...findZoneType(data.qg, zoneMatch.params),
        }
      }
      if (categoryMatch) {
        return {
          ...EMPTY_SELECTION,
          ...findCategory(data.qg, categoryMatch.params),
        }
      }
    } catch (e) {
      captureException(e, { level: Severity.Info })
      replace(homePath)
    }
    return {
      ...EMPTY_SELECTION,
      zoneType: data.qg.zones[0]?.type,
    }
  }

  return <SelectionContext.Provider value={contextValue()}>{children}</SelectionContext.Provider>
}

export const useSelection = (): Selection => React.useContext(SelectionContext)
