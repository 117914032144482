export enum CheckboxVariants {
  onLight = 'onLight',
  onDark = 'onDark',
}

export const Checkbox = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderRadius: 'base',
    },
  },
  variants: {
    onLight: {
      control: {
        borderColor: 'aubergine.500',
        _checked: {
          bg: 'aubergine.500',
          borderColor: 'aubergine.500',
          color: 'bg.light',
          _hover: {
            bg: 'aubergine.500',
            borderColor: 'aubergine.500',
          },
        },
        _disabled: {
          bg: 'aubergine.500',
          borderColor: 'aubergine.500',
          opacity: '.3',
        },
      },
      label: {
        color: 'aubergine.500',
      },
    },
    onDark: {
      control: {
        borderColor: 'peche.200',
        _checked: {
          bg: 'peche.200',
          borderColor: 'peche.200',
          color: 'bg.dark',
          _hover: {
            bg: 'peche.200',
            borderColor: 'peche.200',
          },
        },
        _disabled: {
          bg: 'peche.200',
          borderColor: 'peche.200',
          opacity: '.3',
        },
      },
      label: {
        color: 'creme.100',
      },
    },
  },
  defaultProps: {
    variant: 'onLight',
  },
}
