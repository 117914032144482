import dayjs from 'dayjs'
import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { OrderRequest } from './Cart.model'

export type PlaceOrderMutationRequest = OrderRequest & {
  readonly expectedDateTime?: Date
}

export type UsePlaceOrderMutationOptions = UseAxiosMutationOptions<void, PlaceOrderMutationRequest>

export function usePlaceOrderMutation(
  options?: UsePlaceOrderMutationOptions
): UseMutationExtendedResult<void, PlaceOrderMutationRequest> {
  return useMutation({
    ...options,
    method: 'post',
    path: '/v1/orders/transactions',
    requireAuthentication: true,
    requestDataBuilder: ({ expectedDateTime, ...data }) => ({
      expectedDateTime: expectedDateTime && dayjs(expectedDateTime).toISOString(),
      ...data,
    }),
  })
}
