import { useAuth0 } from '@auth0/auth0-react'

import { User } from '@/app/user/User.model'
import { useUserQuery } from '@/app/user/useUserQuery'

export const useUser = (): {
  isLoading: boolean
  user?: User
} => {
  const { isLoading: isLoadingAuth0User, isAuthenticated } = useAuth0()
  const { isLoading: isLoadingUser, data: user } = useUserQuery({ enabled: isAuthenticated })

  return {
    isLoading: isLoadingAuth0User || isLoadingUser,
    user,
  }
}
