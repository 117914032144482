import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { DraftOrder } from '@/app/order/DraftOrder.model'
import { useRemoveUnavailableItemsFromCart } from '@/app/order/useRemoveUnavailableItemsFromCart'
import { ButtonVariants } from '@/ui/theme/components/button'

export type RemoveUnavailableItemWarningBannerProps = {
  draftOrder: DraftOrder
}

export const RemoveUnavailableItemWarningBanner: React.FC<RemoveUnavailableItemWarningBannerProps> = ({
  draftOrder,
}) => {
  const { t } = useTranslation('cart')
  const { removeUnavailableItemsFromCart } = useRemoveUnavailableItemsFromCart(draftOrder)

  return (
    <Box maxWidth='24rem' textAlign='center' margin='auto'>
      <Text fontWeight='700' color='error.dark'>
        {t('order.unavailableItemsError.title')}
      </Text>
      <Text color='error.dark'>{t('order.unavailableItemsError.description')}</Text>
      <Flex justifyContent='center' pt='6'>
        <Button
          data-testid='remove-unavailable-items'
          variant={ButtonVariants.solidOnDark}
          size='md'
          onClick={removeUnavailableItemsFromCart}
        >
          {t('order.unavailableItemsError.removeUnavailableItems')}
        </Button>
      </Flex>
    </Box>
  )
}
