import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { QGImageType, useImgixUrlBuilder } from '@district-qg/ui'

import { useQG } from '@/app/context/QGContext'
import { useAppPaths } from '@/app/context/useAppPaths'
import { useSelection } from '@/app/selection/SelectionContext'
import { Seo } from '@/ui/components/seo/Seo'

import { CategoryModal } from '../../category/components/CategoryModal'

export const OnlineCategoryPage: React.FC = () => {
  const { items } = useQG()
  const selection = useSelection()
  const { push, location } = useHistory()
  const urlBuilder = useImgixUrlBuilder(QGImageType.Category)
  const { sectionPath } = useAppPaths()

  if (!selection.group) {
    return null
  }

  const { section, category, group } = selection
  const currentItems = items.filter((item) => item.groupIds.includes(group.id))

  const imageUrl = category.mainImage.id && urlBuilder(category.mainImage.id)

  return (
    <>
      <Seo
        title={`${category.title} — ${category.subtitle}`}
        description={category.subtitle}
        url={location.pathname}
        imageUrl={imageUrl}
      />
      <CategoryModal
        isOpen
        onClose={() => {
          push(sectionPath(section.id))
        }}
        items={currentItems}
        category={category}
        groups={category.groups}
        selectedGroupId={group.id}
      />
    </>
  )
}
