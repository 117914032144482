import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FormatFunction } from 'i18next'
import _isDate from 'lodash/isDate'
import _isFinite from 'lodash/isFinite'

import 'dayjs/locale/fr-ca'
import 'dayjs/locale/en'

import { formatPrice } from '../format'

dayjs.extend(localizedFormat)

export const formatValue: FormatFunction = (value, format, /* istanbul ignore next */ language) => {
  if (_isDate(value)) {
    if (format) {
      return language ? dayjs(value).locale(language).format(format) : dayjs(value).format(format)
    }
    return value.toISOString()
  }
  if (_isFinite(value)) {
    if (format === 'currency') {
      return formatPrice(value, language)
    }
    return String(value)
  }

  return value
}
