import React from 'react'

import { Box } from '@chakra-ui/react'

import { CartTitle } from '@/ui/pages/cart/components/CartTitle'
import { OnSiteCartBody } from '@/ui/pages/onsite/cart/components/OnSiteCartBody'

export const OnSiteCartPage: React.FC = () => (
  <>
    <CartTitle marginTop={4} marginBottom={12} />
    <Box w='full' maxW='3xl' mx='auto' px={{ base: 2, md: 8 }}>
      <OnSiteCartBody />
    </Box>
  </>
)
