import React from 'react'

import { Site } from '@/app/context/Site'
import { useCurrentSite } from '@/app/context/SiteContext'
import { Experience } from '@/app/experience/Experience'
import { useMenuExperience } from '@/app/experience/ExperienceContext'
import { Item } from '@/app/item/Item.model'
import { ItemFilter, useItemsQuery } from '@/app/item/useItemsQuery'
import { QG } from '@/app/qg/QG.model'
import { useQGQuery } from '@/app/qg/useQGQuery'

export type CurrentQG = {
  readonly qg: QG
  readonly items: ReadonlyArray<Item>
}

export type QGContextData = { readonly isReady: false } | ({ readonly isReady: true } & CurrentQG)

const QGContext = React.createContext<QGContextData>({ isReady: false })

export const QGContextProvider: React.FC = ({ children }) => {
  const site = useCurrentSite()
  const { experience } = useMenuExperience()

  let filter: ItemFilter

  if (site === Site.ONLINE) {
    filter = ItemFilter.ONLINE
  } else if (experience === Experience.CHEF) {
    filter = ItemFilter.CHEF
  } else {
    filter = ItemFilter.DG
  }

  const { data: qg } = useQGQuery({ itemFilter: filter })
  const { data: items } = useItemsQuery()
  const value = !qg
    ? {
        isReady: false as const,
      }
    : {
        isReady: true,
        qg,
        items: items ?? [],
      }
  return <QGContext.Provider value={value}>{children}</QGContext.Provider>
}

export const useLoadingQG = (): QGContextData => React.useContext(QGContext)
export const useQG = (): CurrentQG => {
  const context = React.useContext(QGContext)
  if (!context.isReady) {
    throw new Error('QG is not loaded')
  }
  return context
}
