import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Text, TextProps } from '@chakra-ui/react'
import { formatPrice, useAppFeatures } from '@district-qg/frontend-common'

import { DraftOrder } from '@/app/order/DraftOrder.model'

export type CartInvoiceProps = {
  draftOrder: DraftOrder
}

type CartInvoiceTextProps = { label: string; value: string } & TextProps

const CartInvoiceText: React.FC<CartInvoiceTextProps> = ({ label, value, ...rest }) => (
  <>
    <Text color='creme.50' align='right' fontSize='14' {...rest} textTransform='capitalize'>
      {label}
    </Text>
    <Text color='creme.50' align='right' fontSize='14' {...rest}>
      {value}
    </Text>
  </>
)

export const CartInvoice: React.FC<CartInvoiceProps> = ({
  draftOrder: { subTotalPrice, deliveryFees, totalPrice, taxLines, gratuity },
}) => {
  const { t } = useTranslation('cart')
  const { paymentEnabled } = useAppFeatures()

  return (
    <Grid templateColumns='1fr minmax(5rem, auto)' columnGap='2' rowGap='4' py='8'>
      <CartInvoiceText label={t('invoice.subTotalPrice')} value={formatPrice(subTotalPrice)} />
      {deliveryFees > 0 && <CartInvoiceText label={t('invoice.delivery')} value={formatPrice(deliveryFees)} />}
      {taxLines.map(({ title, value }) => (
        <CartInvoiceText label={title} value={formatPrice(value)} key={title} />
      ))}
      {paymentEnabled && <CartInvoiceText label={t('invoice.gratuity')} value={formatPrice(gratuity.amount)} />}
      <CartInvoiceText label={t('invoice.totalPrice')} value={formatPrice(totalPrice)} fontWeight='bold' />
    </Grid>
  )
}
