import * as React from 'react'

import { Center, Spinner } from '@chakra-ui/react'

import { useUserQuery } from '@/app/user/useUserQuery'

import { ProfileModalFormProvider } from './ProfileModalFormProvider'

export const ProfileModalBody: React.FC = () => {
  const { data: user } = useUserQuery()

  if (!user) {
    return (
      <Center mt='16'>
        <Spinner size='xl' />
      </Center>
    )
  }

  return <ProfileModalFormProvider user={user} />
}
