import React from 'react'
import { Route } from 'react-router-dom'

import { useLoadingQG } from '@/app/context/QGContext'
import { Site } from '@/app/context/Site'
import { useCurrentSite } from '@/app/context/SiteContext'
import { ONLINE_ROUTES, PROFILE_PAGE_PATH } from '@/routes'
import { useAppLoader } from '@/ui/components/appLoader/useAppLoader'
import { OnlineCartPage } from '@/ui/pages/online/cart/OnlineCartPage'
import { OnlineCategoryPage } from '@/ui/pages/online/category/OnlineCategoryPage'
import { OnlineHomePage } from '@/ui/pages/online/OnlineHomePage'
import { OnSitePageTemplate } from '@/ui/pages/onsite/OnSitePageTemplate'
import { ProfileOrRedirectPage } from '@/ui/pages/profile/ProfileOrRedirectPage'

export const AppRouter: React.FC = () => {
  const { isReady } = useLoadingQG()
  useAppLoader(isReady)
  const site = useCurrentSite()

  if (!isReady) return null

  switch (site) {
    case Site.ONSITE:
      return <OnSitePageTemplate />

    case Site.ONLINE:
      return (
        <>
          <Route path={ONLINE_ROUTES.HOME} component={OnlineHomePage} />
          <Route path={ONLINE_ROUTES.CATEGORY} component={OnlineCategoryPage} />
          <Route path={ONLINE_ROUTES.SECTION_GROUP} component={OnlineCategoryPage} />
          <Route path={ONLINE_ROUTES.CART} component={OnlineCartPage} />
          <Route path={PROFILE_PAGE_PATH} component={ProfileOrRedirectPage} />
        </>
      )
    default:
      return null
  }
}
