// eslint-disable-next-line import/no-extraneous-dependencies

export enum FormLabelVariants {
  onDark = 'onDark',
  readonlyOnDark = 'readonlyOnDark',
}

export const FormLabel = {
  variants: {
    [FormLabelVariants.onDark]: {
      color: 'creme.50',
    },
    [FormLabelVariants.readonlyOnDark]: {
      color: 'gray.700',
    },
  },
}
