import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@chakra-ui/react'

import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'
import { ButtonVariants } from '@/ui/theme/components/button'

type CartModalPlaceOrderButtonProps = {
  isLoading: boolean
  onSubmit: (request: PlaceOrderRequest) => void
}

export const CartModalPlaceOrderButton: React.FC<CartModalPlaceOrderButtonProps> = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation('cart')
  const { handleSubmit } = useFormContext<PlaceOrderRequest>()

  return (
    <Button
      data-testid='submit-order'
      variant={ButtonVariants.solidOnDark}
      size='md'
      onClick={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      {t('order.submit')}
    </Button>
  )
}
