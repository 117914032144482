import { generatePath } from 'react-router-dom'

import { useSite } from '@/app/context/useSite'
import { ZoneType } from '@/app/zone/ZoneType'
import { AppRoutes, ON_SITE_ROUTES, ONLINE_ROUTES } from '@/routes'

export type AppPaths = {
  readonly homePath: string
  readonly cartPath: string
  zonePath(zoneType: ZoneType): string
  categoryPath(categoryId: string): string
  sectionPath(sectionId: string): string
  sectionGroupPath(sectionId: string, groupId: string): string
}

export const appPaths = ({
  HOME: homePath,
  CART: cartPath,
  ZONE: zonePath,
  CATEGORY: categoryPath,
  SECTION: sectionPath,
  SECTION_GROUP: sectionGroupPath,
}: AppRoutes): AppPaths => ({
  homePath,
  cartPath,
  zonePath: (zoneType) => generatePath(zonePath, { zoneType }),
  categoryPath: (categoryId) => generatePath(categoryPath, { categoryId }),
  sectionPath: (sectionId) => generatePath(sectionPath, { sectionId }),
  sectionGroupPath: (sectionId, groupId) => generatePath(sectionGroupPath, { sectionId, groupId }),
})

export const useAppPaths = (): AppPaths =>
  appPaths(
    useSite({
      online: ONLINE_ROUTES,
      onsite: ON_SITE_ROUTES,
    })
  )
