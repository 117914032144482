import * as React from 'react'

import { Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { formatPrice } from '@district-qg/frontend-common'

import { Item } from '@/app/item/Item.model'

import { CartItemImage } from './CartItemImage'
import { DeleteCartItemButton } from './DeleteCartItemButton'

export type CartItemProps = {
  item: Item
}

export const cartItemDefaultPadding = '8'

export const OnsiteCartItem: React.FC<CartItemProps> = ({ item }) => {
  return (
    <Flex py={cartItemDefaultPadding}>
      <CartItemImage image={item.mainImage} />
      <HStack flex='1' spacing='0' align='flex-start'>
        <VStack align='stretch' spacing='1' flex='1' mx='4'>
          <Flex flexWrap='wrap'>
            <Heading as='h3' size='sm' color='peche.200' marginRight='4'>
              {item.name}
            </Heading>
          </Flex>
          <Text color='creme.50' fontSize='12'>
            {item.description}
          </Text>
        </VStack>
        <VStack align='flex-end' spacing='1'>
          <>
            <Text color='creme.50' size='sm'>
              {formatPrice(item.price.amount)}
            </Text>
            <DeleteCartItemButton item={item} />
          </>
        </VStack>
      </HStack>
    </Flex>
  )
}
