import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { HStack, Link, Stack, StackDivider, Text } from '@chakra-ui/react'

import { useQG } from '@/app/context/QGContext'

export const FooterTermsAndConditions: React.FC = () => {
  const { t } = useTranslation()
  const { qg } = useQG()
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align={{ base: 'start', md: 'center' }}
      justify='space-between'
      color='text.dark'
      fontSize='xs'
      lineHeight='none'
      textTransform='uppercase'
    >
      <Link isExternal href={qg.legal.termsOfService}>
        {t('footer.termsOfUse')}
      </Link>
      <HStack divider={<StackDivider borderColor='text.dark' />}>
        <Text>
          {t('footer.copyright', {
            value: new Date(),
            formatParams: {
              value: { year: 'numeric' },
            },
          })}
        </Text>
        <Link isExternal href={qg.legal.privacyPolicy}>
          .{t('footer.privacyPolicy')}
        </Link>
      </HStack>
    </Stack>
  )
}
