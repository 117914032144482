import { Colors } from '@chakra-ui/react'

export const colors: Colors = {
  bg: {
    light: '#FEF5F1', // creme.50,
    dark: '#1D1F25', // gray.900,
  },

  overlay: {
    black: {
      20: 'rgba(0,0,0,.2)',
      60: 'rgba(0,0,0,.6)',
    },
    white: {
      60: 'rgba(255,255,255,.6)',
    },
  },

  error: {
    light: '#FC8181', // red.300
    dark: '#E53E3E', // red.500
  },

  header: {
    light: '#F9B080', // peche.200,
    dark: '#301F36', // aubergine.800,
  },

  text: {
    light: '#FEF5F1', // creme.50,
    dark: '#301F36', // aubergine.800,
  },

  aubergine: {
    light: '#9678a0',
    base: '#5F4867',
    dark: '#301F36',
    50: '#f7eefc',
    100: '#ded1e3',
    200: '#c6b4cc',
    300: '#ad96b6',
    400: '#9678a0', // light
    500: '#5F4867', // base
    600: '#503D57',
    700: '#45344c',
    800: '#301F36', // dark
    900: '#120715',
  },
  bordeaux: {
    light: '#B36E7D',
    base: '#783946',
    dark: '#5A202C',
    50: '#ffebf0',
    100: '#eacad1',
    200: '#d7a8b2',
    300: '#c68693',
    400: '#B36E7D', // light
    500: '#9b4b5b',
    600: '#783946', // base
    700: '#5A202C', // dark
    800: '#37171e',
    900: '#190409',
  },
  creme: {
    light: '#FEF5F1',
    base: '#EBDAD1',
    dark: '#DBBCA9',
    50: '#FEF5F1', // light
    100: '#EBDAD1', // base
    200: '#DBBCA9', // dark
    300: '#c69c86',
    400: '#b67f63',
    500: '#9d664a',
    600: '#7a4f39',
    700: '#583929',
    800: '#352217',
    900: '#170903',
  },
  epinard: {
    light: '#72876C',
    base: '#4E6148',
    dark: '#2D3B28',
    50: '#eaf7ea',
    100: '#d3ded0',
    200: '#bac7b6',
    300: '#9fb09b',
    400: '#859980',
    500: '#72876C', // light
    600: '#4E6148', // base
    700: '#3b4737',
    800: '#2D3B28', // dark
    900: '#061001',
  },
  peche: {
    light: '#FFC7A3',
    base: '#F9B080',
    dark: '#D69D77',
    50: '#ffeee0',
    100: '#FFC7A3', // light
    200: '#F9B080', // base
    300: '#D69D77', // dark
    400: '#c98050',
    500: '#af6636',
    600: '#895029',
    700: '#63391d',
    800: '#3d210f',
    900: '#1b0800',
  },
  steel: {
    light: '#A6B9BE',
    base: '#566878',
    dark: '#2A3B49',
    50: '#e8f3ff',
    100: '#cddae4',
    200: '#A6B9BE', // light
    300: '#96a7b5',
    400: '#798d9f',
    500: '#566878', // base
    600: '#4a5a69',
    700: '#2A3B49', // dark
    800: '#1c2831',
    900: '#010e18',
  },
  gray: {
    100: '#EAEAEB',
    200: '#E0E0E1',
    300: '#C3C4C6',
    400: '#A7A7AA',
    500: '#8D8E91',
    600: '#6E7077',
    700: '#54575F',
    800: '#36383F',
    900: '#1D1F25',
  },
}
