import { event } from 'react-ga'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0Audience } from '@district-qg/frontend-common'

export const useLoginWithRedirect = (redirectUri: string): { loginWithRedirect: () => Promise<void> } => {
  const audience = useAuth0Audience()
  const { loginWithRedirect } = useAuth0()
  const { i18n } = useTranslation()

  return {
    loginWithRedirect: () => {
      event({
        category: 'Authentication',
        action: 'Initiating',
      })
      return loginWithRedirect({ ui_locales: i18n.language, audience, redirectUri })
    },
  }
}
