import {
  QGFeatureConfigurationDto,
  UseAxiosQueryOptions,
  useModelBuilderWithCurrentLocale,
  useQuery,
  UseQueryExtendedResult,
} from '@district-qg/frontend-common'

import { ItemFilter } from '@/app/item/useItemsQuery'
import { QGConfigurationDto, QGDto } from '@/app/qg/QG.dto'
import { buildQGModel, QG } from '@/app/qg/QG.model'
import { useQGId } from '@/environment'

export type UseQgQueryOptions = UseAxiosQueryOptions<QGDto> & {
  itemFilter: ItemFilter
}

export type QGQueryDto = QGDto & QGConfigurationDto & QGFeatureConfigurationDto

export const useQGQuery = ({ itemFilter, ...options }: UseQgQueryOptions): UseQueryExtendedResult<QG, QGQueryDto> => {
  const mapper = useModelBuilderWithCurrentLocale(buildQGModel)

  const id = useQGId()
  return useQuery({
    path: `/v1/qgs/${id}`,
    params: { itemFilter },
    queryKey: ['qg', itemFilter, id],
    requireAuthentication: false,
    mapper,
    ...options,
  })
}
