import * as React from 'react'

type Action = {
  type: 'setSuccessfulUnpaidPlaceOrder'
  value: boolean
}
type Dispatch = (action: Action) => void
type State = { isSuccessfulUnpaidPlaceOrder: boolean }
type OnlineCartContextData = { state: State; dispatch: Dispatch }

const OnlineCartContext = React.createContext<OnlineCartContextData | undefined>(undefined)

const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setSuccessfulUnpaidPlaceOrder': {
      return { ...state, isSuccessfulUnpaidPlaceOrder: action.value }
    }
    /* istanbul ignore next */
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const OnlineCartProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(stateReducer, { isSuccessfulUnpaidPlaceOrder: false })
  const value = { state, dispatch }
  return <OnlineCartContext.Provider value={value}>{children}</OnlineCartContext.Provider>
}

export const useSuccessfulUnpaidPlaceOrder = (): OnlineCartContextData => {
  const context = React.useContext(OnlineCartContext)
  if (context === undefined) {
    throw new Error('useSuccessfulUnpaidPlaceOrder must be used within a OnlineCartProvider')
  }
  return context
}
