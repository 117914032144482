import React from 'react'
import { Helmet } from 'react-helmet'
import Imgix, { Background } from 'react-imgix'

import { Box, BoxProps, ThemeTypings, useBreakpointValue } from '@chakra-ui/react'

import { QGImageOverlay } from './QGImageOverlay'
import { QGImageType } from './QGImageType'
import { useImgixUrlBuilder } from './useImgixUrlBuilder'

type BreakpointObject = Partial<Record<ThemeTypings['breakpoints'], number>>

export type QGImageProps = Omit<BoxProps, 'width' | 'height' | 'children'> & {
  type: QGImageType
  id?: string
  altText?: string
  isBackground?: boolean
  widthPxAtBreakpoints?: BreakpointObject | Array<number | undefined>
  ratioAtBreakpoints?: BreakpointObject | Array<number | undefined>
  imageOverlay?: QGImageOverlay
}

export const QGImage = ({
  type,
  id,
  altText = '',
  isBackground = false,
  widthPxAtBreakpoints = [],
  ratioAtBreakpoints = [],
  imageOverlay,
  ...props
}: QGImageProps): JSX.Element => {
  const urlBuilder = useImgixUrlBuilder(type)
  const width = useBreakpointValue(widthPxAtBreakpoints)
  const ratio = useBreakpointValue(ratioAtBreakpoints)
  const height = width && ratio ? width / ratio : undefined

  const renderImgix = (imageId: string) => {
    const imageUrl = urlBuilder(imageId)
    const imgixProps = {
      src: imageUrl,
      width,
      height,
      disableSrcSet: true,
      imgixParams: {
        fit: 'crop',
        crop: 'focalpoint,entropy',
      },
    }

    const imgixHtmlAttrs = {
      alt: altText,
      width,
      height,
    }

    return width && height ? (
      <Imgix htmlAttributes={imgixHtmlAttrs} {...imgixProps} />
    ) : (
      <>
        <Helmet>
          <link rel='stylesheet' href={`${imageUrl}?palette=css&class=background`} />
        </Helmet>
        <Background
          className='background-bg-muted'
          src={imageUrl}
          imgixParams={{ dpr: 2, auto: 'compress', q: 30 }}
          htmlAttributes={{
            style: { width: '100%', height: '100%', backgroundPosition: '50% 50%' },
          }}
        />
      </>
    )
  }

  return (
    <>
      <Box
        position={isBackground ? 'absolute' : 'relative'}
        zIndex={0}
        top={0}
        left={0}
        width='100%'
        height='100%'
        bgColor='white'
        {...props}
      >
        {id && renderImgix(id)}
        {imageOverlay && (
          <Box position='absolute' zIndex={1} top={0} left={0} width='100%' height='100%' background={imageOverlay} />
        )}
      </Box>
    </>
  )
}
