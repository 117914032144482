import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { useQG } from '@/app/context/QGContext'
import { useAppPaths } from '@/app/context/useAppPaths'
import { useSelection } from '@/app/selection/SelectionContext'
import { CategoryModal } from '@/ui/pages/category/components/CategoryModal'

export const OnSiteCategoryPage: React.FC = () => {
  const { items } = useQG()
  const selection = useSelection()
  const { push } = useHistory()
  const { sectionPath } = useAppPaths()

  if (!selection.group) {
    return null
  }

  const { section, category, group } = selection
  const currentItems = items.filter((item) => item.groupIds.includes(group.id))

  return (
    <CategoryModal
      isOpen
      onClose={() => {
        push(sectionPath(section.id))
      }}
      items={currentItems}
      category={category}
      groups={category.groups}
      selectedGroupId={group.id}
    />
  )
}
