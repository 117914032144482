import { UnavailableItemErrorMessage } from '@/app/order/OrderItem.model'

export const cart = {
  content: {
    empty: 'Votre panier est vide.',
    orderTimeRange: {
      unavailable:
        'Nous sommes présentement en dehors des heures d’ouverture. Vous ne pouvez pas ajouter d’item au panier pour l’instant.',
      unavailableToday: 'Votre sélection n’est plus disponible aujourd’hui.',
      explore: 'Explorez nos\u00A0autres\u00A0terroirs',
    },
    item: {
      unavailable: 'N’est plus disponible',
      unavailableForTheDay: 'N’est pas disponible aujourd’hui',
      availableFor: 'Disponible de\u00A0:',
      underStockManagement: 'Disponible en quantité limitée',
      errors: {
        [UnavailableItemErrorMessage.ItemUnavailableError]: 'N’est plus disponible',
        unavailableToday: 'N’est pas disponible aujourd’hui',
      },
    },
    close: 'Terminer',
  },
  header: {
    title: 'Panier',
    subtitle: 'Bénéficiez d’avantages exclusifs lors de vos achats au District Gourmet.',
  },
  items: {
    availableFrom: 'Disponible seulement de\u00A0{{val, list}}',
    value: '{{open}}\u00A0à\u00A0{{close}}',
  },
  invoice: {
    subTotalPrice: 'Sous-total',
    delivery: 'Livraison',
    totalPrice: 'Total',
    gratuity: 'Pourboire',
  },
  title: 'Panier',
  order: {
    submit: 'Passez la commande',
    initError: 'Les commandes en ligne sont temporairement indisponibles',
    connectionRequired: 'Connectez-vous pour compléter votre achat.',
    selectTime: {
      takeout: 'Sélectionnez une heure de ramassage\u2009:',
      delivery: 'Sélectionnez une heure de livraison\u2009:',
    },
    type: {
      label: {
        takeout: 'Prête pour emporter à\u2009:',
        delivery: 'Prête pour livraison à\u2009:',
      },
      takeout: 'emporter',
      delivery: 'livraison',
    },
    gratuity: {
      label: 'Ajoutez un pourboire',
      amount: {
        button: 'En $',
        label: 'Entrer le montant',
      },
    },
    allergensWarning:
      'Compte tenu de la diversité de nos produits, <b>nous ne pouvons pas vous garantir que votre sélection d’items est exempte d’allergènes</b>. En passant une commande sur notre site, vous acceptez que nos plats peuvent contenir des allergènes.',
    unavailableItemsError: {
      title: 'Attention',
      description: 'Certains des items de votre sélection ne sont malheureusement plus disponibles.',
      removeUnavailableItems: 'Retirer les items indisponibles',
    },
    success: 'Nous avons bien reçu votre commande. Une confirmation vous sera envoyée par courriel.',
    error: {
      generic: 'Erreur',
      title: 'La commande ne peut pas être traitée.',
      description: 'Une erreur s’est produite lors de la confirmation de la commande. Veuillez réessayer à nouveau.',
    },
  },
}
