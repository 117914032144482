import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

export const OnSiteRefreshCartDialog: React.FC<{
  isOpen: boolean
  onClose(): void
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('refreshCart')
  const { emptyCart } = useCart()
  const cancelRef = React.useRef(null)
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent bg='bg.dark'>
          <AlertDialogHeader color='peche.200' fontSize='lg' fontWeight='bold'>
            {t('header')}
          </AlertDialogHeader>
          <AlertDialogBody>{t('body')}</AlertDialogBody>
          <AlertDialogFooter>
            <Button data-testid='cancelButton' onClick={onClose} ref={cancelRef}>
              {t('cancel')}
            </Button>
            <Button
              data-testid='refreshButton'
              bg='error.dark'
              onClick={() => {
                emptyCart()
                onClose()
              }}
              ml={3}
            >
              {t('refresh')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
