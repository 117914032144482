import _isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import { SupportedLocales } from '@district-qg/frontend-common'

import { Item, ItemModel } from '@/app/item/Item.model'
import { DraftOrderItemDto, OrderItemError } from '@/app/order/DraftOrder.dto'

import { extractCurrentOperatingSchedules } from '../operatingHours/extractCurrentOperatingSchedules'
import { OperatingHoursSchedule } from '../types/operatingHours'

export enum UnavailableItemErrorMessage {
  ItemUnavailableError = 'ItemUnavailableError',
  ItemCategoryOutOfScheduleError = 'ItemCategoryOutOfScheduleError',
  ItemOutOfScheduleError = 'ItemOutOfScheduleError',
}

export type ScheduleErrorMessage =
  | UnavailableItemErrorMessage.ItemCategoryOutOfScheduleError
  | UnavailableItemErrorMessage.ItemOutOfScheduleError

export interface OrderItem extends Item {
  readonly error?: UnavailableItemErrorMessage
  readonly dailySchedule: OperatingHoursSchedule[]
  readonly categoryDailySchedule: OperatingHoursSchedule[]
}

export class OrderItemModel extends ItemModel implements OrderItem {
  constructor(protected readonly data: Readonly<DraftOrderItemDto>, protected readonly locale: SupportedLocales) {
    super(data, locale)
  }

  get categoryDailySchedule(): OperatingHoursSchedule[] {
    return extractCurrentOperatingSchedules(this.data.categoryOperatingHours)
  }

  get dailySchedule(): OperatingHoursSchedule[] {
    return extractCurrentOperatingSchedules(this.data.operatingHours)
  }

  get error(): OrderItem['error'] | undefined {
    if (_isEmpty(this.data.errors)) return undefined

    if (some(this.data.errors, { name: OrderItemError.OutOfItemOperatingHoursError })) {
      return UnavailableItemErrorMessage.ItemOutOfScheduleError
    }
    if (some(this.data.errors, { name: OrderItemError.OutOfRestaurantOperatingHoursError })) {
      return UnavailableItemErrorMessage.ItemCategoryOutOfScheduleError
    }

    return UnavailableItemErrorMessage.ItemUnavailableError
  }
}

export function buildOrderItemModel(data: Readonly<DraftOrderItemDto>[], locale: SupportedLocales): OrderItem[] {
  const orderItems: OrderItem[] = []
  data.forEach((item) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= item.quantity; i++) {
      if (some(item.errors, { name: OrderItemError.StockManagementUnavailabilityError })) {
        if (item.stockManagement.availableQuantity && i <= item.stockManagement.availableQuantity) {
          const errorsWithoutStockManagementError = item.errors.filter(
            (error) => error.name !== OrderItemError.StockManagementUnavailabilityError
          )
          orderItems.push(new OrderItemModel({ ...item, errors: errorsWithoutStockManagementError }, locale))
        } else {
          orderItems.push(new OrderItemModel(item, locale))
        }
      } else {
        orderItems.push(new OrderItemModel(item, locale))
      }
    }
  })
  return orderItems
}
