import { useTranslation } from 'react-i18next'

export const usePayFactoConfiguration = (): ((orderTotal: number) => Record<string, unknown>) => {
  const { t } = useTranslation('payfacto')
  return (orderTotal) => ({
    id: 'payfacto-modal',
    header: {
      logo: '/payfacto.svg',
      title: false,
      subtitle: t('subtitle').concat(orderTotal.toFixed(2), ' $'),
    },
    sections: {
      address: false,
    },
    fields: {
      'card-number': {
        mask: { placeholder: t('card.placeholder') },
        title: {
          text: t('card.title'),
          floating: false,
        },
      },
      cvv: {
        mask: { placeholder: '123' },
        title: {
          text: t('code.title'),
          floating: false,
        },
      },
      'expiration-date': {
        mask: { placeholder: t('expiration.placeholder') },
        title: { text: t('expiration.title'), floating: false },
      },
      address: {
        mask: { placeholder: t('address.placeholder') },
        title: {
          text: t('address.title'),
          floating: false,
        },
      },
      name: {
        mask: { placeholder: t('name.placeholder') },
        title: {
          text: t('name.title'),
          floating: false,
        },
      },
      'postal-code': {
        mask: { placeholder: t('postalCode.placeholder') },
        title: {
          text: t('postalCode.title'),
          floating: false,
        },
      },
    },
    buttons: {
      submit: {
        text: t('button.title'),
      },
    },
    fade: false,
    onsubmitValidation: true,
    // Do not put autoclose to true, since it makes the modal recall all the callbacks it was invoked with
    autoClose: false,
  })
}
