import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { ClearIcon } from '@district-qg/ui'

export const CloseButton: React.FC<{ onClick: IconButtonProps['onClick'] }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <IconButton
      colorScheme='steel'
      aria-label={t('action.close')}
      rounded='full'
      minWidth='8'
      height='8'
      icon={<ClearIcon boxSize='6' color='white' />}
      onClick={onClick}
      bg='steel.700'
      data-testid='CloseButton'
    />
  )
}
