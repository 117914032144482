import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { BadgeProps, Box, IconButton, Link } from '@chakra-ui/react'
import { IconProps, SelectionIcon } from '@district-qg/ui'

import { ON_SITE_ROUTES } from '@/routes'
import { CartTotalItemBadge } from '@/ui/components/cartButton/CartTotalItemBadge'
import { ButtonVariants } from '@/ui/theme/components/button'

export const onSiteCartButtonPropsResolver = (
  isInverted: boolean
): { iconProps: IconProps; badgeProps: BadgeProps } => ({
  iconProps: {
    _groupHover: { color: isInverted ? 'steel.200' : 'steel.800' },
    color: isInverted ? 'white' : 'steel.700',
    w: 10,
    h: 'auto',
  },
  badgeProps: {
    _groupHover: { bg: 'peche.200' },
    bg: 'steel.100',
    color: 'steel.700',
  },
})

export const OnSiteCartButton: React.FC<{ isInverted?: boolean }> = ({ isInverted = false }) => {
  const { t } = useTranslation('cart')
  const { iconProps, badgeProps } = onSiteCartButtonPropsResolver(isInverted)

  return (
    <Link as={RouterLink} to={ON_SITE_ROUTES.CART}>
      <IconButton
        variant={ButtonVariants.linkOnDark}
        borderRadius='base'
        icon={
          <Box role='group'>
            <SelectionIcon {...iconProps} />
            <CartTotalItemBadge {...badgeProps} />
          </Box>
        }
        aria-label={t('title')}
        h={9}
        w={10}
        minWidth='10'
      />
    </Link>
  )
}
