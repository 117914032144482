import React from 'react'

import { useSelection } from '@/app/selection/SelectionContext'
import { ZoneType } from '@/app/zone/ZoneType'
import { RestaurantZoneMobile } from '@/ui/components/zone/RestaurantZoneMobile'

import { MarketZone } from './MarketZone'

export const ZoneListMobile: React.FC = () => {
  const { zoneType } = useSelection()

  switch (zoneType) {
    case ZoneType.restaurant:
      return <RestaurantZoneMobile />
    case ZoneType.market:
      return <MarketZone />
    default:
      return null
  }
}
