export const payfacto = {
  address: {
    placeholder: '12 3ième Avenue',
    title: 'Adresse du détenteur',
  },
  button: {
    title: 'Faire le paiement',
  },
  card: {
    placeholder: '1234 1234 1234 1234',
    title: 'Numéro de la carte',
  },
  code: {
    placeholder: '123',
    title: 'Code de vérification',
  },
  expiration: {
    placeholder: 'MM / YY',
    title: 'Date d’expiration',
  },
  name: {
    placeholder: 'Prénom et nom',
    title: 'Nom du détenteur',
  },
  postalCode: {
    placeholder: 'A1A 1A1 ou 12345',
    title: 'Code postal du détenteur',
  },
  subtitle: 'Total de la commande : ',
  errorMessage: 'La transaction a échoué. Veuillez réessayer plus tard.',
}
