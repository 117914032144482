import { useCallback } from 'react'

import { useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { QGConfigDto } from '@/app/qg/QGConfig.dto'
import { buildModel, QGConfig } from '@/app/qg/QGConfig.model'
import { useQGId } from '@/environment'

export const useQGConfigQuery = (): UseQueryExtendedResult<QGConfig, QGConfigDto> => {
  const mapper = useCallback((dto: QGConfigDto) => buildModel(dto), [])

  const id = useQGId()
  return useQuery({
    path: `/v1/qgs/${id}/config`,
    requireAuthentication: false,
    mapper,
  })
}
