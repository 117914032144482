import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Flex, Heading, HStack, Link, Text } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { useAppPaths } from '@/app/context/useAppPaths'
import { useSite } from '@/app/context/useSite'
import { Group } from '@/app/group/Group.model'
import { useQGConfigQuery } from '@/app/qg/useQGConfigQuery'
import { useUser } from '@/app/user/useUser'
import { ModalItemListDivider } from '@/ui/components/modal/ModalItemList'
import { CategoryAvailabilityWarningBanner } from '@/ui/pages/category/components/CategoryAvailabilityWarningBanner'
import { OnlineCartButton } from '@/ui/pages/online/cart/components/OnlineCartButton'
import { OnSiteCartButton } from '@/ui/pages/onsite/cart/components/OnSiteCartButton'
import { HeadingVariants } from '@/ui/theme/components/heading'

export type CategoryModalHeaderProps = {
  readonly category: Category
  readonly groups: ReadonlyArray<Group>
  readonly selectedGroupId: Group['id']
  readonly headerVisible: boolean
}

const defaultHorizontalPadding = { base: '4', md: '5' }

export const CategoryModalHeader: React.FC<CategoryModalHeaderProps> = ({
  category,
  groups,
  selectedGroupId,
  headerVisible,
}) => {
  const { t } = useTranslation('common')
  const { sectionGroupPath } = useAppPaths()
  const warningBanner = useSite({
    onsite: null,
    online: <CategoryAvailabilityWarningBanner categoryId={category.id} />,
  })
  const cartButton = useSite({ online: <OnlineCartButton />, onsite: <OnSiteCartButton isInverted /> })
  const { data: qgConfig } = useQGConfigQuery()
  const { user } = useUser()

  const onsiteScheduleList = category.dailySchedule.onsite.length
    ? t('schedule.list', {
        val: category.dailySchedule.onsite.map((schedule) =>
          t('schedule.slots', { returnObjects: true, open: schedule.open, close: schedule.close })
        ),
      })
    : t('schedule.closed')

  const onlineScheduleList = category.dailySchedule.online.length
    ? t('schedule.list', {
        val: category.dailySchedule.online.map((schedule) =>
          t('schedule.slots', { returnObjects: true, open: schedule.open, close: schedule.close })
        ),
      })
    : t('schedule.closed')

  return (
    <>
      <Heading
        px={defaultHorizontalPadding}
        variant={HeadingVariants.kicker}
        size='lg'
        as='div'
        transform='translateY(1rem)'
        position='relative'
        zIndex='15'
      >
        {category.subtitle}
      </Heading>
      <Box backgroundColor='bg.dark' zIndex='10' position='sticky' top='0'>
        <HStack justify='space-between' py='4' px={defaultHorizontalPadding}>
          <Heading
            transitionProperty='transform'
            transitionDuration='250ms'
            fontWeight='700'
            color='creme.50'
            transform={headerVisible ? 'scale(1)' : 'scale(.8)'}
            transformOrigin='left center'
            size='3xl'
            as='h1'
          >
            {category.title}
          </Heading>
          <Box
            transitionProperty='transform'
            transitionDuration='250ms'
            transform={headerVisible ? 'translateY(-0.75rem)' : 'translateY(0)'}
          >
            {cartButton}
          </Box>
        </HStack>
        <Flex overflowX='auto' backgroundColor='gray.800'>
          <HStack spacing='8' px={defaultHorizontalPadding}>
            {groups.map((group) => (
              <Link
                key={group.id}
                as={RouterLink}
                to={sectionGroupPath(category.sectionId, group.id)}
                paddingTop='1rem'
                paddingBottom='calc(1rem - 3px)'
                borderBottom='3px solid'
                textColor='gray.500'
                borderColor={group.id === selectedGroupId ? 'peche.200' : 'transparent'}
              >
                <Heading
                  whiteSpace='nowrap'
                  fontWeight='700'
                  _hover={{ color: 'peche.200' }}
                  color={group.id === selectedGroupId ? 'peche.200' : 'gray.500'}
                  size='sm'
                >
                  {group.name}
                </Heading>
              </Link>
            ))}
          </HStack>
        </Flex>
        {warningBanner}
        <Box px={defaultHorizontalPadding}>
          <Box py={defaultHorizontalPadding} textAlign='right' textColor='text.light'>
            <Heading as='h3' size='sm' mb={1}>
              {t('schedule.dailySchedule')}
            </Heading>
            <Text fontSize='xs'>{`${t('schedule.onsite').toUpperCase()}\u2009:\u2009${onsiteScheduleList}`}</Text>
            <Text fontSize='xs'>
              {`${t(
                `schedule.${
                  user && user.isResident && qgConfig && qgConfig.deliveryEnabled ? 'takeoutAndDelivery' : 'takeout'
                }`
              )}\u2009:\u2009${onlineScheduleList}`}
            </Text>
          </Box>
          <ModalItemListDivider />
        </Box>
      </Box>
    </>
  )
}
