import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { buildCategoryModel as buildCategoriesModel, Category } from '@/app/category/Category.model'
import { SectionDto } from '@/app/section/Section.dto'

export interface Section {
  readonly id: string
  readonly name: string
  readonly imageId: string
  readonly categories: ReadonlyArray<Category>
}

export class SectionModel implements Section {
  readonly categories = buildCategoriesModel(this.data.categories, this.locale)

  constructor(private readonly data: Readonly<SectionDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get imageId(): string {
    return this.data.imageId
  }
}

export function buildSectionModel(data: SectionDto, locale: SupportedLocales): Section
export function buildSectionModel(data: SectionDto[], locale: SupportedLocales): Section[]
export function buildSectionModel(data: SectionDto[] | SectionDto, locale: SupportedLocales): Section[] | Section {
  return Array.isArray(data)
    ? data.map((sectionDto) => buildSectionModel(sectionDto, locale))
    : new SectionModel(data, locale)
}
