import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Heading, HStack, Link, Stack, Text } from '@chakra-ui/react'
import { MailIcon, MapIcon, SvgImage } from '@district-qg/ui'

import { useQG } from '@/app/context/QGContext'
import { ReactComponent as QgLogo } from '@/ui/assets/images/le-qg-cadre.svg'
import { FooterIconButton } from '@/ui/pages/online/components/FooterIconButton'
import { FooterTermsAndConditions } from '@/ui/pages/online/components/FooterTermsAndConditions'

export const Footer: React.FC = () => {
  const { qg } = useQG()
  const { t } = useTranslation()

  return (
    <Stack py={{ base: '8', md: '12' }} px={{ base: '4', md: '12' }} spacing='12'>
      <Stack direction={{ base: 'column-reverse', md: 'row' }} justify='space-between' spacing={8}>
        <Stack spacing='4'>
          <HStack ml='-2' mb='-2'>
            <Link href={qg.address.mapUrl} isExternal>
              <FooterIconButton icon={<MapIcon color='aubergine.400' w='6' h='auto' />} ariaLabel='map' />
            </Link>
            <Link href={`mailto:${qg.customerService.email}`}>
              <FooterIconButton icon={<MailIcon color='aubergine.400' w='6' h='auto' />} ariaLabel='mail' />
            </Link>
          </HStack>
          <div>
            <Text fontSize='sm'>{qg.name}</Text>
            <Text fontSize='sm'>{qg.customerService.phoneNumber}</Text>
            <Text fontSize='sm'>
              <Link href={qg.address.mapUrl} isExternal>
                {qg.address.streetAddress}
              </Link>
            </Text>
            <Text fontSize='sm'>{qg.address.note}</Text>
          </div>
          <Text sx={{ whiteSpace: 'pre-line' }} fontSize='sm' lineHeight='short'>
            {qg.openingHours}
          </Text>
        </Stack>
        <Stack align='flex-end'>
          <Link href='https://le-qg.ca/'>
            <HStack spacing='4'>
              <Heading fontSize='16' fontWeight='700' lineHeight='none'>
                {t('footer.lifeStyle')}
              </Heading>
              <SvgImage svg={QgLogo} w='72px' h='auto' aria-label='Le QG' fill='aubergine.800' />
            </HStack>
          </Link>
        </Stack>
      </Stack>
      <FooterTermsAndConditions />
    </Stack>
  )
}
