import React from 'react'

import { useCartItems } from '@/app/item/useCartItems'
import { ModalItemList } from '@/ui/components/modal/ModalItemList'
import { OnsiteCartItem } from '@/ui/pages/cart/components/OnsiteCartItem'

export const OnSiteCartItemList: React.FC = () => {
  const items = useCartItems()
  return (
    <ModalItemList outerDividers>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <OnsiteCartItem item={item} key={`${item.id}-${index}`} />
      ))}
    </ModalItemList>
  )
}
