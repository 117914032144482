import { MutationKey, QueryKey } from 'react-query'

import { Method } from 'axios'
import _isString from 'lodash/isString'

export function computeSentryFingerprint<Key extends MutationKey | QueryKey>(
  method: Method,
  url: string,
  key?: Key
): string[] {
  if (Array.isArray(key)) {
    return [method, ...key.map((v) => String(v))]
  }
  if (_isString(key)) {
    return [method, key]
  }
  return [method, url]
}
