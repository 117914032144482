import { AxiosRequestConfig } from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

import { CommonEnvironmentKey, getEnvironment, useEnvironment } from '../environment'

export type UseAxiosRequestConfigBuilderOptions = {
  readonly requireAuthentication: boolean
  readonly expectedStatus: number
}

export type AxiosRequestConfigBuilder = (config?: AxiosRequestConfig) => Promise<AxiosRequestConfig>

export const useAxiosRequestConfigBuilder: (options: UseAxiosRequestConfigBuilderOptions) => AxiosRequestConfigBuilder =
  ({ requireAuthentication, expectedStatus }) => {
    const { getAccessTokenSilently } = useAuth0()
    const environment = useEnvironment()

    return async (config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      const baseURL = getEnvironment(environment, CommonEnvironmentKey.BASE_URL)
      const baseConfig: AxiosRequestConfig = {
        baseURL,
        validateStatus: (status) => status === expectedStatus,
        ...config,
      }

      if (!requireAuthentication) {
        return baseConfig
      }

      const audience = getEnvironment(environment, CommonEnvironmentKey.AUTH0_AUDIENCE)
      const token = await getAccessTokenSilently({ audience })
      return {
        ...baseConfig,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    }
  }
