import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import _isEmpty from 'lodash/isEmpty'
import { Box, Center, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import { useAppFeatures, useCurrentLocale } from '@district-qg/frontend-common'

import { useLoginWithRedirect } from '@/app/auth/useLoginWithRedirect'
import { useSuccessfulUnpaidPlaceOrder } from '@/app/context/OnlineCartContext'
import { usePayFacto } from '@/app/context/payFacto/PayFactoContext'
import { buildOrderModel } from '@/app/order/Order.model'
import { usePlaceOrder } from '@/app/order/usePlaceOrder'
import { useQGConfigQuery } from '@/app/qg/useQGConfigQuery'
import { useUser } from '@/app/user/useUser'
import { CartInvoice } from '@/ui/pages/cart/components/CartInvoice'
import { CartModalHeaderActions } from '@/ui/pages/cart/components/CartModalHeaderActions'
import { CartModalPlaceOrderButton } from '@/ui/pages/cart/components/CartModalPlaceOrderButton'
import { OrderGratuityValueSelector } from '@/ui/pages/cart/components/form/OrderGratuityValueSelector'
import { PlaceOrderForm } from '@/ui/pages/cart/components/form/PlaceOrderForm'
import { RemoveUnavailableItemWarningBanner } from '@/ui/pages/cart/components/RemoveUnavailableItemWarningBanner'
import { OnlineCartItemList } from '@/ui/pages/online/cart/components/OnlineCartItemList'

export const GRATUITY_VALUES = [10, 15, 20]

export const OnlineCartModalBody: React.FC = () => {
  const locale = useCurrentLocale()
  const { t } = useTranslation(['cart'])
  const { data: qgConfig } = useQGConfigQuery()
  const { loginWithRedirect } = useLoginWithRedirect(window.location.href)
  const { paymentEnabled } = useAppFeatures()
  const {
    formState: { isValid },
    watch,
  } = useFormContext()
  const { dispatch } = useSuccessfulUnpaidPlaceOrder()
  const toast = useToast()
  const { user, isLoading: isLoadingUser } = useUser()
  const { displayPayFactoModal, isPaymentLoading } = usePayFacto()

  const { draftOrder, refreshDraftOrder, placeOrder, isPlacingOrder, orderTimeRanges, isLoadingDraftOrder } =
    usePlaceOrder({
      type: watch('type', 'takeout'),
      gratuity: watch('gratuity'),
      expectedDateTime: watch('expectedDateTime'),
      onSuccess: async (order) => {
        if (order?.id) {
          await displayPayFactoModal(buildOrderModel(order, locale))
        } else {
          dispatch({ type: 'setSuccessfulUnpaidPlaceOrder', value: true })
        }
      },
      onError: () => {
        toast({
          title: t('cart:order.error.title'),
          description: t('cart:order.error.description'),
          status: 'error',
          isClosable: true,
          position: 'top',
        })
      },
    })

  React.useEffect(() => {
    const subscription = watch(({ expectedDateTime }) => {
      refreshDraftOrder(expectedDateTime)
    })
    return () => subscription.unsubscribe()
  }, [watch, refreshDraftOrder])

  if (!qgConfig || !draftOrder || isLoadingUser || isPaymentLoading) {
    return (
      <Center mt='16'>
        <Spinner size='xl' />
      </Center>
    )
  }
  const displayPlaceOrderButton =
    !draftOrder.containsUnavailableItems && isValid && !isLoadingDraftOrder && !_isEmpty(orderTimeRanges)
  const displayRemoveItemBanner = draftOrder.containsUnavailableItems && !_isEmpty(orderTimeRanges)

  if (!user?.isRegistered) {
    return (
      <Box flex='1'>
        <CartModalHeaderActions user={user} />
        <Box px='5'>
          <OnlineCartItemList draftOrder={draftOrder} />
          <CartInvoice draftOrder={draftOrder} />
          <Text
            onClick={loginWithRedirect}
            color='peche.base'
            _hover={{ color: 'peche.400' }}
            textAlign='center'
            cursor='pointer'
          >
            {t('cart:order.connectionRequired')}
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box flex='1'>
      <PlaceOrderForm
        deliveryEnabled={qgConfig.deliveryEnabled}
        isPlacingOrder={isPlacingOrder}
        onSubmit={placeOrder}
        orderTimeRanges={orderTimeRanges ?? []}
        user={user}
      />
      <Box px='5'>
        <OnlineCartItemList draftOrder={draftOrder} />
        <CartInvoice draftOrder={draftOrder} />
        {displayRemoveItemBanner && <RemoveUnavailableItemWarningBanner draftOrder={draftOrder} />}
        {displayPlaceOrderButton && (
          <Stack align='center' mx={{ md: 24 }} spacing='8'>
            <Text align='center' color='gray.100' fontSize='sm'>
              <Trans t={t} i18nKey='cart:order.allergensWarning' components={{ b: <strong /> }} />
            </Text>
            {paymentEnabled && <OrderGratuityValueSelector values={GRATUITY_VALUES} />}
            <CartModalPlaceOrderButton isLoading={isPlacingOrder} onSubmit={(order) => placeOrder(order)} />
          </Stack>
        )}
      </Box>
    </Box>
  )
}
