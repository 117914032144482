import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading } from '@chakra-ui/react'
import { useOnClosePushHistory } from '@district-qg/frontend-common'

import { User } from '@/app/user/User.model'
import { ONLINE_ROUTES, PROFILE_PAGE_PATH } from '@/routes'

import { CompleteProfileButton } from './CompleteProfileButton'
import { LoginButton } from './LoginButton'

export type CartModalHeaderActionsProps = {
  user?: User
}

export const CartModalHeaderActions: React.FC<CartModalHeaderActionsProps> = ({ user }) => {
  const { t } = useTranslation('cart')
  const onCloseGoTo = useOnClosePushHistory(PROFILE_PAGE_PATH, { onCloseGoTo: ONLINE_ROUTES.CART })
  const displayLoginButton = !user
  const displayCompleteProfileButton = user && !user.isRegistered

  if (!displayLoginButton && !displayCompleteProfileButton) return null

  const renderButton = () => {
    if (displayCompleteProfileButton)
      return <CompleteProfileButton onClick={onCloseGoTo} data-testid='cart-complete-profile-button' />
    return <LoginButton data-testid='cart-login-button' />
  }

  return (
    <Box maxWidth='28rem' textAlign='center' pt={4} pb={16} px={4} margin='auto'>
      <Heading as='h3' size='lg' fontWeight='400' color='steel.200' marginBottom='6'>
        {t('header.subtitle')}
      </Heading>
      {renderButton()}
    </Box>
  )
}
