import * as React from 'react'

import _defaults from 'lodash/defaults'

/** Feature switches for the application.
 *
 * Important: Should be kept in-sync with QGFeatureConfiguration in the backend
 * */
export type QGFeatureConfiguration = {
  readonly paymentEnabled: boolean
  readonly onSiteItemsActivationEnabled: boolean
}

export type QGFeatureConfigurationDto = {
  readonly features?: Partial<QGFeatureConfiguration>
}

const DEFAULT_QG_FEATURES: QGFeatureConfiguration = {
  paymentEnabled: false,
  onSiteItemsActivationEnabled: false,
}

const QGFeatureConfigurationContext = React.createContext<QGFeatureConfiguration>(DEFAULT_QG_FEATURES)

export const useAppFeatures = (): QGFeatureConfiguration => React.useContext(QGFeatureConfigurationContext)

export type QGFeatureConfigurationProviderProps = { value?: Partial<QGFeatureConfiguration> }

export const QGFeatureConfigurationProvider: React.FC<QGFeatureConfigurationProviderProps> = ({ value, children }) => (
  <QGFeatureConfigurationContext.Provider value={_defaults(value, DEFAULT_QG_FEATURES)}>
    {children}
  </QGFeatureConfigurationContext.Provider>
)
