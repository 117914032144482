import React, { useContext } from 'react'

export type ImportMetaEnv = Readonly<Record<string, string | boolean | undefined>>

export type EnvironmentProviderProps = {
  readonly environment: ImportMetaEnv
}

export type EnvironmentProviderData = EnvironmentProviderProps & {
  readonly isProduction: boolean
}

const EnvironmentContext = React.createContext<EnvironmentProviderData>({ environment: {}, isProduction: false })

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ environment, children }) => {
  return (
    <EnvironmentContext.Provider value={{ environment, isProduction: environment.MODE === 'production' }}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export const useIsProduction = (): boolean => useContext(EnvironmentContext).isProduction

export const useEnvironmentContext = (): ImportMetaEnv => useContext(EnvironmentContext).environment
