export const common = {
  action: {
    close: 'Close',
    delete: 'Delete',
  },
  login: 'Login',
  welcome: 'Welcome',
  nav: {
    main: {
      drink_eat: 'Boire et manger',
      market: 'Le marché',
      experience: 'Expérience',
      events: 'Événements',
      qg: 'Le QG',
    },
    util: {
      lang: 'Langue',
      profile: 'Profil',
    },
  },
}
