import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

export type UsePayOrderMutationRequest = {
  paymentToken: string
  orderId: string
}

export type PayOrderResponse = {
  orderId: string
  amount: string
  authorizationNumber: string
  transactionNumber: string
}

export type UsePayOrderMutationOptions = UseAxiosMutationOptions<PayOrderResponse, UsePayOrderMutationRequest>

export function usePayOrderMutation(
  options?: UsePayOrderMutationOptions
): UseMutationExtendedResult<PayOrderResponse, UsePayOrderMutationRequest> {
  return useMutation({
    ...options,
    method: 'post',
    path: ({ orderId }) => `/v1/orders/${orderId}/pay`,
    requireAuthentication: true,
    requestDataBuilder: ({ paymentToken }) => ({ paymentToken }),
  })
}
