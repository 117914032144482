import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { ReloadIcon } from '@district-qg/ui'

import { ButtonVariants } from '@/ui/theme/components/button'

export const OnSiteRefreshCartButton: React.FC<{ onClick: IconButtonProps['onClick'] }> = ({ onClick }) => {
  const { t } = useTranslation('refreshCart')
  return (
    <IconButton
      data-testid='RefreshCartButton'
      variant={ButtonVariants.linkOnDark}
      aria-label={t('refreshCartAriaLabel')}
      borderRadius='base'
      onClick={onClick}
      icon={<ReloadIcon w={10} h='auto' color='white' _hover={{ color: 'steel.200' }} />}
      h={9}
      w={10}
      minWidth='10'
    />
  )
}
