import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, HStack, Text, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react'

import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'

export const OrderTypeButton: React.FC<UseRadioProps> = ({ children, ...props }) => {
  const { t } = useTranslation()
  const { getInputProps, getCheckboxProps } = useRadio({ ...props })

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const { value, isDisabled } = props

  return (
    <Box as='label' position='relative'>
      <input data-testid={`OrderTypeInput-${value}`} {...input} />
      {isDisabled && (
        <Text
          transform='translate(-50%, -0.15rem)'
          textAlign='center'
          fontSize='sm'
          color='steel.200'
          position='absolute'
          bottom='100%'
          left='50%'
        >
          {t('feature.soon')}
        </Text>
      )}
      <Text
        data-testid={`OrderTypeButton-${value}`}
        {...checkbox}
        userSelect='none'
        fontWeight='bold'
        fontSize='xs'
        lineHeight='1'
        textTransform='uppercase'
        cursor='pointer'
        border='1px solid'
        borderRadius='base'
        bg='steel.500'
        borderColor='steel.500'
        color='steel.200'
        _checked={{
          bg: 'peche.200',
          borderColor: 'peche.200',
          color: 'steel.700',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _disabled={{
          bg: 'steel.400',
          borderColor: 'steel.400',
        }}
        px={8}
        py={4}
      >
        {children}
      </Text>
    </Box>
  )
}

export type OrderTypeSelectorProps = {
  deliveryDisabled?: boolean
}

export type OrderTypeFieldValues = Pick<PlaceOrderRequest, 'type'>

export const OrderTypeSelector: React.FC<OrderTypeSelectorProps> = ({ deliveryDisabled = false }) => {
  const { t } = useTranslation('cart')
  const {
    field: { onChange, value },
  } = useController<OrderTypeFieldValues>({ name: 'type', rules: { required: true } })

  const { getRootProps, getRadioProps } = useRadioGroup({ name: 'type', onChange, value })
  const group = getRootProps()

  return (
    <HStack {...group} alignItems='flex-end'>
      <OrderTypeButton id='takeout' value='takeout' {...getRadioProps({ value: 'takeout' })}>
        {t('order.type.takeout')}
      </OrderTypeButton>
      <OrderTypeButton
        id='delivery'
        value='delivery'
        {...getRadioProps({ value: 'delivery' })}
        isDisabled={deliveryDisabled}
      >
        {t('order.type.delivery')}
      </OrderTypeButton>
    </HStack>
  )
}
