import * as React from 'react'

import { ModalBody, UseModalProps } from '@chakra-ui/react'

import { OnlineCartProvider } from '@/app/context/OnlineCartContext'
import { usePayFacto } from '@/app/context/payFacto/PayFactoContext'
import { Modal, ModalContent, ModalFixedHeader, ModalOverlay } from '@/ui/components/modal/Modal'
import { ModalCloseButton } from '@/ui/components/modal/ModalCloseButton'
import { CartTitle } from '@/ui/pages/cart/components/CartTitle'

import { OnlineCartModalContent } from './components/OnlineCartModalContent'

export type OnlineCartModalProps = UseModalProps

export const OnlineCartModal: React.FC<OnlineCartModalProps> = ({ isOpen, ...modalProps }) => {
  const { isOpen: isPayFactoOpen } = usePayFacto()

  return (
    <Modal isOpen={isOpen && !isPayFactoOpen} {...modalProps} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalFixedHeader top='4' right='4'>
          <ModalCloseButton />
        </ModalFixedHeader>
        <ModalBody px='0' paddingBottom={{ base: 40, md: 16 }}>
          <OnlineCartProvider>
            <CartTitle />
            <OnlineCartModalContent />
          </OnlineCartProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
