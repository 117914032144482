import _some from 'lodash/some'
import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { GratuityType } from '@/app/order/Gratuity'
import { buildOrderItemModel, OrderItem } from '@/app/order/OrderItem.model'

import { DraftOrderDto } from './DraftOrder.dto'

export interface DraftOrder {
  readonly containsUnavailableItems: boolean
  readonly deliveryFees: number
  readonly items: ReadonlyArray<OrderItem>
  readonly subTotalPrice: number
  readonly taxLines: ReadonlyArray<{
    value: number
    title: string
  }>
  readonly totalPrice: number
  readonly totalTax: number
  readonly gratuity: {
    readonly type?: GratuityType
    readonly value?: number
    readonly amount: number
  }
}

export class DraftOrderModel implements DraftOrder {
  readonly items = buildOrderItemModel(this.data.items, this.locale)

  readonly taxLines = this.data.taxLines.map(({ value, title }) => ({
    value,
    title: localize(title, this.locale),
  }))

  readonly containsUnavailableItems = _some(this.items, (item) => !!item.error)

  constructor(private readonly data: Readonly<DraftOrderDto>, private readonly locale: SupportedLocales) {}

  get subTotalPrice(): number {
    return this.data.subTotalPrice
  }

  get deliveryFees(): number {
    return this.data.delivery.fees
  }

  get totalPrice(): number {
    return this.data.totalPrice
  }

  get totalTax(): number {
    return this.data.totalTax
  }

  get gratuity(): DraftOrder['gratuity'] {
    return this.data.gratuity
  }
}

export function buildDraftOrderModel(data: DraftOrderDto, locale: SupportedLocales): DraftOrder {
  return new DraftOrderModel(data, locale)
}
