import React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import _isUndefined from 'lodash/isUndefined'
import { Stack, Text } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'

import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'
import { OrderTimeRange } from '@/app/qg/QGConfig.model'
import { theme } from '@/ui/theme'

dayjs.extend(isSameOrBefore)

const steel700 = getColor(theme, 'steel.700')
const steel500 = getColor(theme, 'steel.500')
const peche200 = getColor(theme, 'peche.200')

export type OrderExpectedTimeInputProps = {
  orderTimeRanges: OrderTimeRange[]
}
export type OrderExpectedTimeFieldValues = Pick<PlaceOrderRequest, 'expectedDateTime'>

const intervalSplitTime = 15

const roundToNextQuarter = (date: Date) => {
  const remainder = intervalSplitTime - (dayjs(date).minute() % intervalSplitTime)
  return dayjs(date).add(remainder, 'minutes').startOf('minute').toDate()
}

const splitTime = (timeRange: OrderTimeRange) => {
  const start = dayjs(roundToNextQuarter(timeRange.start))
  const result = [start]
  let time = start.add(intervalSplitTime, 'minutes')
  while (time.isSameOrBefore(timeRange.end, 'minutes')) {
    result.push(time)
    time = time.add(intervalSplitTime, 'minutes')
  }
  return result
}

export const OrderExpectedTimeInput: React.FC<OrderExpectedTimeInputProps> = ({ orderTimeRanges }) => {
  const optionsGroups = orderTimeRanges.map((timeRange) => ({
    options: splitTime(timeRange).map((time) => ({ value: time.toDate(), label: time.format('HH:mm') })),
  }))
  const { t } = useTranslation(['cart'])
  const type: 'delivery' | 'takeout' = useWatch({ name: 'type' })
  const time = useWatch({ name: 'expectedDateTime' })

  return (
    <Stack spacing={2} alignItems='center'>
      <Text as='label' htmlFor='time-selector' fontSize='sm' textAlign='center' color='peche.200'>
        {time ? t(`cart:order.type.label.${type}` as const) : t(`cart:order.selectTime.${type}` as const)}
      </Text>
      <Controller
        name='expectedDateTime'
        rules={{
          validate: (val) => {
            return !_isUndefined(val)
          },
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <Select
              key='time-selector'
              inputId='time-selector'
              styles={{
                menu:
                  /* istanbul ignore next */
                  (provided) => ({ ...provided, width: '7rem', background: steel700 }),
                groupHeading:
                  /* istanbul ignore next */
                  (provided) => ({ ...provided, display: 'none' }),
                group:
                  /* istanbul ignore next */
                  (provided) => ({ ...provided, borderBottom: '1px solid' }),

                option:
                  /* istanbul ignore next */
                  (provided, { isFocused }) => ({
                    ...provided,
                    padding: '0.25rem 1rem 0.25rem calc(1rem + 2px)',
                    background: isFocused ? steel500 : steel700,
                  }),
                control:
                  /* istanbul ignore next */
                  (provided) => ({
                    ...provided,
                    width: '7rem',
                    margin: '0 auto',
                    paddingLeft: '0.5rem',
                    background: steel700,
                    outline: value ? '' : '2px solid',
                    outlineColor: value ? '' : peche200,
                  }),
                singleValue:
                  /* istanbul ignore next */
                  (provided) => ({ ...provided, color: peche200, background: steel700 }),
              }}
              options={optionsGroups}
              value={value ? { value, label: dayjs(value).format('HH:mm') } : undefined}
              onChange={
                /* istanbul ignore next */
                (newValue) => {
                  // we must override the onChange inferred type because we`re using groups and the type is not correct
                  const typedNewValue = newValue as unknown as { value: Date; label: string }
                  if (typedNewValue) onChange(typedNewValue.value)
                }
              }
              isSearchable={false}
              placeholder=''
            />
          )
        }}
      />
    </Stack>
  )
}
