import { UseMutationOptions, useQueryClient } from 'react-query'

import { AxiosError } from 'axios'
import { useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { UserDto } from './User.dto'
import { USER_QUERY_KEY } from './useUserQuery'

export type UpdateUserRequest = Pick<
  UserDto,
  | 'apartmentNumber'
  | 'city'
  | 'civicNumber'
  | 'firstName'
  | 'lastName'
  | 'memberships'
  | 'phoneNumber'
  | 'postalCode'
  | 'province'
  | 'streetAddress'
  | 'subscribed'
>

export const useUpdateUserMutation = (
  options: UseMutationOptions<void, AxiosError, UpdateUserRequest>
): UseMutationExtendedResult<void, UpdateUserRequest> => {
  const queryClient = useQueryClient()
  return useMutation<void, UpdateUserRequest>({
    ...options,
    path: '/v1/user',
    method: 'put',
    onSuccess: async (errors, variables, context) => {
      await queryClient.invalidateQueries(USER_QUERY_KEY)
      /* istanbul ignore next */
      return options?.onSuccess?.(errors, variables, context)
    },
  })
}
