import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { buildSectionModel, Section } from '@/app/section/Section.model'
import { ZoneDto } from '@/app/zone/Zone.dto'

import { ZoneType } from './ZoneType'

export interface Zone {
  readonly name: string
  readonly sections: ReadonlyArray<Section>
  readonly type: ZoneType
}

export class ZoneModel implements Zone {
  readonly sections = buildSectionModel(
    this.data.sections.filter((section) => section.categories.length > 0),
    this.locale
  )

  constructor(private readonly data: Readonly<ZoneDto>, private readonly locale: SupportedLocales) {}

  get name(): string {
    return localize(this.data.name, this.locale)
  }

  get type(): ZoneType {
    return this.data.type
  }
}

export function buildZoneModel(data: ZoneDto, locale: SupportedLocales): Zone
export function buildZoneModel(data: ZoneDto[], locale: SupportedLocales): Zone[]
export function buildZoneModel(data: ZoneDto[] | ZoneDto, locale: SupportedLocales): Zone[] | Zone {
  return Array.isArray(data) ? data.map((zoneDto) => buildZoneModel(zoneDto, locale)) : new ZoneModel(data, locale)
}
