import { PaymentSessionDto } from './PaymentSession.dto'

export interface PaymentSession {
  readonly secureId: string
  readonly apiUrl: string
  readonly iframeUrl: string
}

export class PaymentSessionModel implements PaymentSession {
  constructor(private readonly data: Readonly<PaymentSessionDto>) {}

  get secureId(): string {
    return this.data.paymentSecureId
  }

  get apiUrl(): string {
    return this.data.paymentApiBaseUrl
  }

  get iframeUrl(): string {
    return this.data.paymentIframeBaseUrl
  }
}

export function buildPaymentSession(data: PaymentSessionDto): PaymentSession {
  return new PaymentSessionModel(data)
}
