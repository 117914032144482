import { SupportedLocales } from '@district-qg/frontend-common'

import { UserDto } from '@/app/user/User.dto'

export enum UserRegistrationState {
  PROFILE_TO_COMPLETE = 'profileToComplete',
  REGISTERED = 'registered',
}

export enum UserMembership {
  RESIDENT = 'resident',
}

export interface User {
  readonly address: {
    readonly apartmentNumber: string
    readonly city: string
    readonly civicNumber: string
    readonly postalCode: string
    readonly province: string
    readonly streetAddress: string
  }
  readonly email: string
  readonly firstName: string
  readonly id: string
  readonly isRegistered: boolean
  readonly isResident: boolean
  readonly lastName: string
  readonly phoneNumber: string
  readonly subscribed: boolean
}

export class UserModel implements User {
  public readonly address = {
    apartmentNumber: this.data.apartmentNumber,
    city: this.data.city,
    civicNumber: this.data.civicNumber,
    postalCode: this.data.postalCode,
    province: this.data.province,
    streetAddress: this.data.streetAddress,
  }

  constructor(private readonly data: Readonly<UserDto>, private readonly locale: SupportedLocales) {}

  get email(): string {
    return this.data.email
  }

  get firstName(): string {
    return this.data.firstName
  }

  get id(): string {
    return this.data.id
  }

  get isRegistered(): boolean {
    return this.data.registrationState === UserRegistrationState.REGISTERED
  }

  get isResident(): boolean {
    return this.data.memberships.includes(UserMembership.RESIDENT)
  }

  get lastName(): string {
    return this.data.lastName
  }

  get phoneNumber(): string {
    return this.data.phoneNumber.charAt(0) === '+' ? this.data.phoneNumber : `+${this.data.phoneNumber}`
  }

  get subscribed(): boolean {
    return this.data.subscribed
  }
}

export function buildModel(data: UserDto, locale: SupportedLocales): User {
  return new UserModel(data, locale)
}
