import { useCart } from 'react-use-cart'

import _compact from 'lodash/compact'
import _constant from 'lodash/constant'
import _flatmap from 'lodash/flatMap'
import _times from 'lodash/times'

import { CurrentQG, useQG } from '@/app/context/QGContext'

export const useCartItems = (): CurrentQG['items'] => {
  const { items: cartItems } = useCart()
  const { items: QGItems } = useQG()

  return _compact(
    _flatmap(cartItems, (cartItem) => _times(cartItem.quantity ?? 0, _constant(cartItem))).map((cartItem) =>
      QGItems.find((qgItem) => qgItem.id === cartItem.id)
    )
  )
}
