import React from 'react'

import {
  Box,
  BoxProps,
  Modal as ChakraModal,
  ModalContent as ChakraModalContent,
  ModalContentProps,
  ModalOverlay as ChakraModalOverlay,
  ModalOverlayProps,
  ModalProps,
} from '@chakra-ui/react'

const overlaySx = { background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(20px)' }

export const ModalOverlay: React.FC<ModalOverlayProps> = ({ ...props }) => (
  <ChakraModalOverlay sx={overlaySx} {...props} />
)

export const ModalFixedHeader: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box position='absolute' top='0' zIndex='100' {...props}>
    {children}
  </Box>
)

const defaultMarginBottom = { base: '0', md: '14' }
const defaultMarginTop = { base: '0', md: '14' }
const defaultBorderRadius = { base: '0', md: 'md' }
const defaultMinHeight = { base: '100vh', md: 'calc(100vh - 7rem)' }

export const ModalContent: React.FC<ModalContentProps> = ({ children, ...props }) => (
  <ChakraModalContent
    backgroundColor={props.backgroundColor || 'bg.dark'}
    marginBottom={defaultMarginBottom}
    marginTop={defaultMarginTop}
    borderRadius={defaultBorderRadius}
    minHeight={defaultMinHeight}
    {...props}
  >
    {children}
  </ChakraModalContent>
)

export const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <ChakraModal size={props.size || '3xl'} {...props}>
      {children}
    </ChakraModal>
  )
}
