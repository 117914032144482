import { useHistory } from 'react-router-dom'

import { HistoryState } from './History'

export function useOnClosePushHistory(destination: string, nextState?: HistoryState): () => void {
  const {
    push,
    location: { state },
  } = useHistory<HistoryState>()

  if (nextState) return () => push(destination, nextState)
  return () => push(state?.onCloseGoTo ?? destination)
}
