import React from 'react'

import { Box, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { SPACE } from '@district-qg/frontend-common'

import districtPresentationImage from '@/ui/assets/images/la-grande-halle-gourmande.jpg'

export const QGPresentation: React.FC = () => (
  <Stack
    direction={{ base: 'column-reverse', md: 'row' }}
    py={{ base: '8', md: '16' }}
    px={{ base: '4', md: '8' }}
    spacing={8}
    flex={1}
  >
    <Box width={{ md: '50%', xl: '33%' }} flexShrink={0} mx={{ base: '-1rem', md: 0 }}>
      <Image src={districtPresentationImage} alt='La grande halle gourmande' />
    </Box>
    <Box>
      <Heading as='h1' fontSize={{ base: '5xl', md: '6xl' }} fontWeight='600'>
        La grande halle gourmande
      </Heading>
      <Heading as='h2' fontSize='2xl' fontWeight='normal' mt={1}>
        Où plaisir et gastronomie ne font qu’un!
      </Heading>
      <Stack mt={4} spacing={4}>
        <Text>
          <strong>Venez découvrir le District Gourmet</strong>, une immense halle gastronomique sur la route de l’Église
          où nous vous proposons un concept gourmand encore inédit dans{SPACE.NBSP}la{SPACE.NBSP}région.
        </Text>
        <Text>
          Dans la foulée de cette nouvelle tendance en restauration qui séduit actuellement les plus grandes villes du
          monde, la Capitale-Nationale a maintenant une halle alimentaire d’exception, une nouvelle destination
          épicurienne par excellence. Implanté dans un gigantesque atrium vitré, le{SPACE.NBSP}
          <strong>District{SPACE.NBSP}Gourmet</strong> vous propose une offre alimentaire complète et diversifiée qui
          regroupe de nombreux restaurateurs, des artisans, une école culinaire ainsi qu’un marché d’alimentation.
        </Text>
        <Text>
          Chefs de renom, mixologue et marché vous offrent un accès gastronomique à des produits issus de nombreux
          artisans locaux, mais aussi plusieurs produits d’importation.
        </Text>
      </Stack>
      <Stack mt={8} spacing={4}>
        <Heading as='h3' fontSize='xl' fontWeight='normal'>
          Une expérience culinaire à part
        </Heading>
        <Text>
          Ne prenant la forme ni d’un marché ni d’une aire de restauration traditionnelle, le{` `}
          <strong>District{SPACE.NBSP}Gourmet</strong> promet une offre culinaire hors du commun et un lieu de
          rassemblement animé où épicuriens et foodies se retrouvent pour y passer un moment agréable dans l’un des
          restaurants ou encore pour y acheter de savoureux produits prêt-à-manger.
        </Text>
      </Stack>
    </Box>
  </Stack>
)
