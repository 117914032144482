import React from 'react'

import { Flex } from '@chakra-ui/react'

import { Seo } from '@/ui/components/seo/Seo'
import { ZoneList } from '@/ui/components/zone/ZoneList'
import { ZoneSelector } from '@/ui/components/zone/ZoneSelector'
import { Header } from '@/ui/organisms/header/Header'
import { ProfileLink } from '@/ui/organisms/profile/ProfileLink'
import { ReservationLink } from '@/ui/organisms/reservation/ReservationLink'
import { OnlineCartButton } from '@/ui/pages/online/cart/components/OnlineCartButton'

import { Footer } from './components/Footer'
import { QGPresentation } from './components/QGPresentation'

export const OnlineHomePage: React.FC = () => {
  return (
    <>
      <Seo />
      <Flex direction='column' minHeight='100vh'>
        <Flex direction='column' position='sticky' top='0' zIndex='10'>
          <Header zIndex='10' padding={{ base: '4', md: '12' }}>
            <ReservationLink />
            <OnlineCartButton />
            <ProfileLink />
          </Header>
          <ZoneSelector />
        </Flex>

        <ZoneList />

        <QGPresentation />
        <Footer />
      </Flex>
    </>
  )
}
