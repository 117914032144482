import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { Box, Text, useModalContext, VStack } from '@chakra-ui/react'
import { SPACE, useAppFeatures } from '@district-qg/frontend-common'

import { useSuccessfulUnpaidPlaceOrder } from '@/app/context/OnlineCartContext'
import { usePayFacto } from '@/app/context/payFacto/PayFactoContext'
import { GratuityType } from '@/app/order/Gratuity'
import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'
import { useCanOrderNow } from '@/app/qg/useCanOrderNow'
import { useUser } from '@/app/user/useUser'
import { ModalActionButton } from '@/ui/components/modal/ModalActionButton'
import { CartMessage } from '@/ui/pages/cart/components/CartMessage'
import { CartModalHeaderActions } from '@/ui/pages/cart/components/CartModalHeaderActions'

import { GRATUITY_VALUES, OnlineCartModalBody } from './OnlineCartModalBody'

export const OnlineCartModalContent: React.FC = () => {
  const { t } = useTranslation(['cart', 'payment'])
  const { paymentEnabled } = useAppFeatures()
  const { items: cartItems } = useCart()
  const { user } = useUser()
  const { onClose } = useModalContext()
  const { payOrderResponse } = usePayFacto()
  const form = useForm<PlaceOrderRequest>({
    mode: 'onChange',
    defaultValues: {
      type: 'takeout',
      expectedDateTime: undefined,
      gratuity: paymentEnabled
        ? { type: GratuityType.percentage, value: GRATUITY_VALUES[1] }
        : { type: GratuityType.percentage, value: 0 },
    },
  })
  const {
    state: { isSuccessfulUnpaidPlaceOrder },
    dispatch,
  } = useSuccessfulUnpaidPlaceOrder()
  const canOrderNow = useCanOrderNow()

  React.useEffect(() => {
    return () => dispatch({ type: 'setSuccessfulUnpaidPlaceOrder', value: false })
  }, [dispatch])

  if (canOrderNow === false) {
    return (
      <Box px='5'>
        <CartMessage message={t('cart:content.orderTimeRange.unavailable')} />
      </Box>
    )
  }

  if (payOrderResponse) {
    return (
      <Box textAlign='center' color='peche.200'>
        <Text mb={8}>{t('cart:order.success')}</Text>
        <Text mb={2}>
          {`${t('payment:amount.title')}${SPACE.NNBSP}:`}{' '}
          <strong>
            {t('payment:amount.value', {
              amount: Number(payOrderResponse.amount),
            })}
          </strong>
        </Text>
        <Text mb={8}>
          {`${t('payment:transactionNumber')}${SPACE.NNBSP}:`} <strong>{payOrderResponse.transactionNumber}</strong>
        </Text>
        <Box mt={12}>
          <ModalActionButton data-testid='close-modal' onClick={onClose}>
            {t('cart:content.close')}
          </ModalActionButton>
        </Box>
      </Box>
    )
  }

  if (isSuccessfulUnpaidPlaceOrder) {
    return (
      <VStack spacing={8} justifyContent='center'>
        <Box px='5'>
          <CartMessage message={t('cart:order.success')} />
        </Box>
        <ModalActionButton data-testid='close-modal' onClick={onClose}>
          {t('cart:content.close')}
        </ModalActionButton>
      </VStack>
    )
  }

  if (cartItems.length === 0) {
    return (
      <Box flex='1'>
        <CartModalHeaderActions user={user} />
        <Box px='5'>
          <CartMessage message={t('cart:content.empty')} />
        </Box>
      </Box>
    )
  }

  return (
    <FormProvider {...form}>
      <OnlineCartModalBody />
    </FormProvider>
  )
}
