// global styles: https://chakra-ui.com/docs/features/global-styles

import { SystemStyleObject } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
// eslint-disable-next-line import/no-extraneous-dependencies
import type { Dict } from '@chakra-ui/utils'

import { HEADER_HEIGHT } from '../../constants'

export const styles = {
  global: (props: Dict): SystemStyleObject => ({
    ':root': {
      '--gutter': '2rem',
      '--header-height': `${HEADER_HEIGHT}px`,
    },
    body: {
      background: mode('bg.light', 'bg.dark')(props),
    },
    // fix an issue with Webkit iOS bottom toolbar; remove if this cause other issues
    '.chakra-modal__content-container.chakra-modal__content-container': {
      height: '100vh',
    },
    '.scrollbar-none': {
      scrollbarWidth: 'none',

      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '.scrollbar-thin': {
      scrollbarWidth: 'thin',
      scrollbarColor: `var(--chakra-colors-overlay-black-20) var(--chakra-colors-overlay-white-60)`,

      '::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
        backgroundColor: 'var(--chakra-colors-overlay-white-60)',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--chakra-colors-overlay-black-20)',
        borderRadius: '5px',
        border: '2px solid',
        borderColor: 'transparent',
      },
    },
  }),
}
