import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Heading, HStack, Link } from '@chakra-ui/react'

import { useQG } from '@/app/context/QGContext'
import { useAppPaths } from '@/app/context/useAppPaths'
import { useSelection } from '@/app/selection/SelectionContext'

export const ZoneSelector: React.FC = () => {
  const {
    qg: { zones },
  } = useQG()
  const { zoneType: currentZoneType } = useSelection()
  const { zonePath } = useAppPaths()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentZoneType])

  return (
    <HStack
      pt={4}
      spacing='8'
      overflowX='auto'
      justifyContent='center'
      align='top'
      bgColor='creme.50'
      display={{ base: 'flex', md: 'none' }}
    >
      {zones.map((zone, index) => (
        <Link key={zone.name} as={RouterLink} to={zonePath(zone.type)} data-testid={`zone-link-${index}`}>
          <Heading
            pb='2'
            data-testid={`zone-tab-${index}`}
            whiteSpace='nowrap'
            fontSize='24'
            fontWeight='400'
            lineHeight='none'
            cursor='pointer'
            _hover={{ color: 'aubergine.800' }}
            color={zone.type === currentZoneType ? 'aubergine.800' : 'aubergine.400'}
            borderBottom={zone.type === currentZoneType ? '8px solid' : ''}
            borderColor={zone.type === currentZoneType ? 'aubergine.800' : ''}
          >
            {zone.name}
          </Heading>
        </Link>
      ))}
    </HStack>
  )
}
