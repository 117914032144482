import * as React from 'react'

import { Box } from '@chakra-ui/react'
import { QGImage, QGImageType } from '@district-qg/ui'

import { Item } from '@/app/item/Item.model'

export type CartItemImageProps = {
  image: Item['mainImage']
}

export const CartItemImage: React.FC<CartItemImageProps> = ({ image }) => (
  <Box boxSize='16' borderRadius='base' overflow='hidden'>
    <QGImage
      type={QGImageType.Item}
      id={image.id}
      altText={image.altText}
      widthPxAtBreakpoints={[64]}
      ratioAtBreakpoints={[1]}
    />
  </Box>
)
