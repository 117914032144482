import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0Audience } from '@district-qg/frontend-common'

import { ProfilePage } from '@/ui/pages/profile/ProfilePage'

export const ProfileOrRedirectPage: React.FC = () => {
  const { i18n } = useTranslation()
  const audience = useAuth0Audience()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  if (!isLoading) {
    if (isAuthenticated) return <ProfilePage />
    loginWithRedirect({ ui_locales: i18n.language, audience })
  }

  return null
}
