import { User, UserMembership } from '@/app/user/User.model'
import { UpdateUserRequest } from '@/app/user/useUpdateUserMutation'

export type ProfileModalFormData = Omit<UpdateUserRequest, 'memberships' | 'phoneNumber'> & {
  isResident: boolean
  phoneNumber?: string
}

export function fromDto(user: User): ProfileModalFormData {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber === '+' ? undefined : user.phoneNumber,
    civicNumber: user.address.civicNumber,
    streetAddress: user.address.streetAddress,
    apartmentNumber: user.address.apartmentNumber,
    postalCode: user.address.postalCode,
    province: 'QC',
    city: user.address.city,
    subscribed: user.subscribed,
    isResident: user.isResident,
  }
}

export function toUpdateRequest({ isResident, ...data }: ProfileModalFormData): UpdateUserRequest {
  return {
    ...data,
    memberships: isResident ? [UserMembership.RESIDENT] : [],
    phoneNumber: data.phoneNumber ?? '',
  }
}
