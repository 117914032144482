import { Localizable, SupportedLocales } from '@district-qg/frontend-common'

import { OrderDto } from './Order.dto'

export interface Order {
  id: string
  createdAt: string
  invoice: {
    gratuity: { amount: number }
    totalPrice: number
    totalTax: number
    subTotalPrice: number
    delivery: { fees: number }
    taxLines: ReadonlyArray<{
      id: string
      title: Localizable
      value: number
    }>
  }
}

export class OrderModel implements Order {
  constructor(private readonly data: Readonly<OrderDto>, private readonly locale: SupportedLocales) {}

  public get id(): string {
    return this.data.id
  }

  public get createdAt(): string {
    return this.data.createdAt
  }

  public get invoice(): {
    gratuity: { amount: number }
    totalPrice: number
    totalTax: number
    subTotalPrice: number
    delivery: { fees: number }
    taxLines: readonly { id: string; title: Localizable; value: number }[]
  } {
    return this.data.invoice
  }
}

export function buildOrderModel(data: OrderDto, locale: SupportedLocales): Order {
  return new OrderModel(data, locale)
}
