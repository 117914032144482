import { useEffect, useState } from 'react'

import { useModelBuilderWithCurrentLocale } from '@district-qg/frontend-common'

import { OrderRequest } from './Cart.model'
import { buildDraftOrderModel, DraftOrder } from './DraftOrder.model'
import { usePrepareDraftOrderMutation } from './usePrepareDraftOrderMutation'

export function usePrepareDraftOrder({ type, gratuity, expectedDateTime, items }: OrderRequest): {
  isLoading: boolean
  isError: boolean
  draftOrder?: DraftOrder
  forceRefresh: (expectedDateTime?: Date) => void
} {
  const [draftOrder, setDraftOrder] = useState<DraftOrder>()
  const modelBuilder = useModelBuilderWithCurrentLocale(buildDraftOrderModel)

  const { mutate, isLoading, isError, data: dto } = usePrepareDraftOrderMutation()

  useEffect(() => {
    mutate({ type, gratuity, expectedDateTime, items })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, gratuity?.type, gratuity?.value, items, mutate])

  useEffect(() => {
    if (dto) {
      setDraftOrder(modelBuilder(dto))
    }
  }, [dto, modelBuilder, setDraftOrder])

  return {
    isLoading,
    isError,
    draftOrder,
    forceRefresh: (datetime) => mutate({ type, gratuity, expectedDateTime: datetime, items }),
  }
}
