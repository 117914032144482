import * as React from 'react'
import { useEffect } from 'react'
import { FieldName, useFormContext, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'

import _get from 'lodash/get'
import _has from 'lodash/has'
import {
  Checkbox,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  Grid,
  GridItem,
  GridItemProps,
  Input,
  Stack,
  Text,
  TextProps,
} from '@chakra-ui/react'

import { QG_STEFOY_ADDRESS } from '@/app/qg/QGAddress'
import { User } from '@/app/user/User.model'
import { CheckboxVariants } from '@/ui/theme/components/checkbox'
import { FormLabelVariants } from '@/ui/theme/components/formLabel'
import { InputVariants } from '@/ui/theme/components/input'

import { ProfileModalFormData } from './ProfileModalFormData'

export type ProfileModalContentProps = {
  user: User
}

type ProfileFormControlProps = {
  colSpan?: GridItemProps['colSpan']
  name: FieldName<ProfileModalFormData>
} & FormControlProps

const ProfileFormControl: React.FC<ProfileFormControlProps> = ({ colSpan, children, label, name, ...rest }) => {
  const { t } = useTranslation()
  const { errors, touchedFields } = useFormState<ProfileModalFormData>()
  const errorMessage = _get(errors, `${name}.message`)
  const isTouched = _has(touchedFields, name)
  return (
    <GridItem colSpan={colSpan ?? 4}>
      <FormControl {...rest}>
        <FormLabel
          fontSize='xs'
          marginBottom='1'
          whiteSpace='nowrap'
          variant={rest.isReadOnly ? FormLabelVariants.readonlyOnDark : FormLabelVariants.onDark}
        >
          {label}
        </FormLabel>
        {children}
        {
          /* istanbul ignore next */ isTouched && errors && errorMessage && (
            <FormErrorMessage fontSize='xs' lineHeight='none'>
              {t(errorMessage)}
            </FormErrorMessage>
          )
        }
      </FormControl>
    </GridItem>
  )
}

type ProfileCheckboxLabelAlignment = 'left' | 'right'

type ProfileFormCheckboxProps = {
  label: string
  labelFor: string
  labelAlignment: ProfileCheckboxLabelAlignment
  labelColor: TextProps['color']
  labelFontSize?: TextProps['fontSize']
}

const ProfileFormCheckboxControl: React.FC<ProfileFormCheckboxProps> = ({
  children,
  label,
  labelFor,
  labelAlignment,
  labelColor,
  labelFontSize,
}) => (
  <Stack direction={labelAlignment === 'left' ? 'row' : 'row-reverse'}>
    <Text
      as='label'
      htmlFor={labelFor}
      color={labelColor}
      fontSize={labelFontSize}
      flex={labelAlignment === 'left' ? 'initial' : '1'}
      cursor='pointer'
    >
      {label}
    </Text>
    <Flex alignItems='flex-start'>{children}</Flex>
  </Stack>
)

const CustomPhoneInput = forwardRef((props, ref) => {
  return <Input variant={InputVariants.onDark} data-testid='phoneNumber-input' maxLength={15} ref={ref} {...props} />
})

export const ProfileModalForm: React.FC<ProfileModalContentProps> = ({ user }) => {
  const { t } = useTranslation()
  const {
    register,
    setValue,
    trigger,
    watch,
    control,
    formState: { errors, touchedFields, isDirty },
  } = useFormContext<ProfileModalFormData>()
  const watchIsResident = watch('isResident', user.isResident)

  useEffect(() => {
    if (isDirty) {
      setValue('civicNumber', watchIsResident ? QG_STEFOY_ADDRESS.civicNumber : user.address.civicNumber)
      setValue('streetAddress', watchIsResident ? QG_STEFOY_ADDRESS.streetAddress : user.address.streetAddress)
      setValue('postalCode', watchIsResident ? QG_STEFOY_ADDRESS.postalCode : user.address.postalCode)
      setValue('city', watchIsResident ? QG_STEFOY_ADDRESS.city : user.address.city)
      trigger()
    }
  }, [watchIsResident, setValue, trigger, user, isDirty])

  const isInvalid = (field: keyof ProfileModalFormData): boolean => {
    return _has(touchedFields, field) && !!errors[field]
  }

  return (
    <Grid templateColumns='repeat(4, 1fr)' gap={4}>
      <GridItem colSpan={4}>
        <FormControl>
          <FormLabel fontSize='xs' marginBottom='1' variant={FormLabelVariants.readonlyOnDark}>
            {t('user.profile.content.email')}
          </FormLabel>
          <Input defaultValue={user.email} isReadOnly variant={InputVariants.onDark} />
        </FormControl>
      </GridItem>
      <ProfileFormControl
        name='firstName'
        isRequired
        label={t('user.profile.content.firstName')}
        isInvalid={isInvalid('firstName')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='firstName-input'
          autoFocus
          {...register('firstName', { required: 'user.profile.error.required' })}
        />
      </ProfileFormControl>
      <ProfileFormControl
        name='lastName'
        isRequired
        label={t('user.profile.content.lastName')}
        isInvalid={isInvalid('lastName')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='lastName-input'
          label={t('user.profile.content.lastName')}
          {...register('lastName', { required: 'user.profile.error.required' })}
        />
      </ProfileFormControl>
      <ProfileFormControl
        name='phoneNumber'
        isRequired
        colSpan={{ base: 4, sm: 3, md: 2 }}
        label={t('user.profile.content.phoneNumber')}
        isInvalid={isInvalid('phoneNumber')}
      >
        <PhoneInput
          name='phoneNumber'
          rules={{
            required: 'user.profile.error.required',
            pattern: {
              value: /^\+1\d{10}$/,
              message: 'user.profile.error.invalid',
            },
          }}
          country='CA'
          international
          withCountryCallingCode
          control={control}
          inputComponent={CustomPhoneInput}
        />
      </ProfileFormControl>
      <ProfileFormControl name='isResident'>
        <ProfileFormCheckboxControl
          label={t('user.profile.content.isResident')}
          labelFor='resident-checkbox'
          labelAlignment='left'
          labelColor='peche.200'
        >
          <Checkbox
            variant={CheckboxVariants.onDark}
            size='lg'
            id='resident-checkbox'
            data-testid='resident-checkbox'
            {...register('isResident')}
          />
        </ProfileFormCheckboxControl>
      </ProfileFormControl>
      <ProfileFormControl
        name='civicNumber'
        isReadOnly={watchIsResident}
        isRequired
        colSpan={1}
        label={t('user.profile.content.civicNumber')}
        isInvalid={isInvalid('civicNumber')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='civicNumber-input'
          {...register('civicNumber', { required: 'user.profile.error.required' })}
        />
      </ProfileFormControl>
      <ProfileFormControl
        name='streetAddress'
        isReadOnly={watchIsResident}
        isRequired
        colSpan={3}
        label={t('user.profile.content.streetAddress')}
        isInvalid={isInvalid('streetAddress')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='streetAddress-input'
          {...register('streetAddress', { required: 'user.profile.error.required' })}
        />
      </ProfileFormControl>
      <ProfileFormControl
        name='apartmentNumber'
        isRequired={watchIsResident}
        colSpan={1}
        label={t('user.profile.content.apartmentNumber')}
        isInvalid={isInvalid('apartmentNumber')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='apartmentNumber-input'
          {...register('apartmentNumber', {
            required: watchIsResident ? 'user.profile.error.required' : false,
          })}
        />
      </ProfileFormControl>
      <ProfileFormControl
        name='postalCode'
        isReadOnly={watchIsResident}
        isRequired
        colSpan={2}
        label={t('user.profile.content.postalCode')}
        isInvalid={isInvalid('postalCode')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='postalCode-input'
          {...register('postalCode', {
            required: 'user.profile.error.required',
            pattern: {
              value: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
              message: 'user.profile.error.invalid',
            },
          })}
        />
      </ProfileFormControl>

      <ProfileFormControl
        name='city'
        isRequired
        colSpan={{ base: 4, md: 3 }}
        label={t('user.profile.content.city')}
        isReadOnly={watchIsResident}
        isInvalid={isInvalid('city')}
      >
        <Input
          variant={InputVariants.onDark}
          data-testid='city-input'
          {...register('city', { required: 'user.profile.error.required' })}
        />
      </ProfileFormControl>
      <ProfileFormControl name='subscribed'>
        <ProfileFormCheckboxControl
          label={t('user.profile.content.subscribed')}
          labelFor='subscription-checkbox'
          labelAlignment='right'
          labelColor='gray.400'
          labelFontSize='14'
        >
          <Checkbox
            size='lg'
            variant={CheckboxVariants.onDark}
            id='subscription-checkbox'
            data-testid='subscription-checkbox'
            {...register('subscribed')}
          />
        </ProfileFormCheckboxControl>
      </ProfileFormControl>
    </Grid>
  )
}
