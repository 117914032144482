import * as React from 'react'

import { QGFeatureConfigurationProvider } from '@district-qg/frontend-common'

import { ItemFilter } from '@/app/item/useItemsQuery'
import { useQGQuery } from '@/app/qg/useQGQuery'

export const QGClientFeaturesProvider: React.FC = ({ children }) => {
  const { dto: qg } = useQGQuery({ itemFilter: ItemFilter.ONLINE })
  return <QGFeatureConfigurationProvider value={qg?.features}>{children}</QGFeatureConfigurationProvider>
}
