import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { AspectRatio, ModalBody, UseModalProps } from '@chakra-ui/react'
import { QGImage, QGImageType } from '@district-qg/ui'

import { Category } from '@/app/category/Category.model'
import { Group } from '@/app/group/Group.model'
import { Modal, ModalContent, ModalFixedHeader, ModalOverlay } from '@/ui/components/modal/Modal'
import { ModalCloseButton } from '@/ui/components/modal/ModalCloseButton'
import { CategoryModalHeader } from '@/ui/pages/category/components/CategoryModalHeader'
import { GroupItemList, GroupItemListProps } from '@/ui/pages/category/components/GroupItemList'

export type CategoryModalProps = {
  readonly category: Category
  readonly groups: ReadonlyArray<Group>
  readonly selectedGroupId: Group['id']
} & Pick<GroupItemListProps, 'items'> &
  UseModalProps

export const CategoryModal: React.FC<CategoryModalProps> = ({
  category,
  groups,
  selectedGroupId,
  items,
  ...modalProps
}) => {
  const { t } = useTranslation()
  const { ref, inView } = useInView({ threshold: 0 })

  return (
    <Modal autoFocus={false} {...modalProps} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent overflow='hidden' position='relative'>
        <ModalBody position='relative' px='0' py='0'>
          <ModalFixedHeader left='0' right='0' textAlign='right' p='4'>
            <ModalCloseButton>{t('nav.exploreTerroirs')}</ModalCloseButton>
          </ModalFixedHeader>
          <AspectRatio ref={ref} ratio={{ base: 10 / 6, md: 10 / 3.75 }}>
            <QGImage
              type={QGImageType.Category}
              id={category.mainImage.id}
              altText={category.mainImage.altText}
              isBackground
            />
          </AspectRatio>
          <CategoryModalHeader
            category={category}
            groups={groups}
            selectedGroupId={selectedGroupId}
            headerVisible={inView}
          />
          <GroupItemList items={items} categoryId={category.id} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
