import { useEffect } from 'react'

export const useAppLoader = (isAppReady: boolean): void => {
  const qgLoader = document.querySelector('#qg-loader')

  useEffect(() => {
    if (isAppReady) {
      qgLoader?.remove()
    }
  }, [isAppReady, qgLoader])
}
