import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { useAuth0UserWithSentry } from '@district-qg/frontend-common'

import { CartProviderWithAnalytic } from '@/app/analytics/CartProviderWithAnalytic'
import { useAuth0UserWithAnalytic } from '@/app/analytics/useAuth0UserWithAnalytic'
import { usePageTracking } from '@/app/analytics/usePageTracking'
import { QGContextProvider } from '@/app/context/QGContext'
import { useSite } from '@/app/context/useSite'
import { ExperienceContextProvider } from '@/app/experience/ExperienceContext'
import { QGClientFeaturesProvider } from '@/app/features/QGClientFeaturesProvider'
import { SelectionProvider } from '@/app/selection/SelectionContext'
import { AppRouter } from '@/AppRouter'
import { useSyncDocumentLocale } from '@/i18n'

import { theme } from './ui/theme'

type AppProps = {
  isDev: boolean
}

export const App: React.FC<AppProps> = ({ isDev }: AppProps) => {
  useSyncDocumentLocale()
  useAuth0UserWithAnalytic()
  useAuth0UserWithSentry()
  usePageTracking()

  const queryClient = React.useRef(new QueryClient())

  theme.config.initialColorMode = useSite({ onsite: 'dark', online: 'light' })

  return (
    <>
      <ColorModeScript />
      <ChakraProvider resetCSS theme={theme}>
        <CartProviderWithAnalytic>
          <QueryClientProvider client={queryClient.current}>
            <ExperienceContextProvider>
              <QGContextProvider>
                <SelectionProvider>
                  <QGClientFeaturesProvider>
                    <AppRouter />
                  </QGClientFeaturesProvider>
                </SelectionProvider>
              </QGContextProvider>
            </ExperienceContextProvider>
            {isDev && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </CartProviderWithAnalytic>
      </ChakraProvider>
    </>
  )
}
