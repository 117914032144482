import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import _isUndefined from 'lodash/isUndefined'

import { useQGConfigQuery } from './useQGConfigQuery'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const useCanOrderNow = (): boolean | null => {
  const { data: qgConfig } = useQGConfigQuery()

  if (!qgConfig) return null

  if (_isUndefined(qgConfig.latestOrderTime)) return false

  return dayjs().isSameOrAfter(qgConfig.earliestOrderTime) && dayjs().isSameOrBefore(qgConfig.latestOrderTime)
}
