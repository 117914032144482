import * as React from 'react'

import { Heading, Stack } from '@chakra-ui/react'

import { Category } from '@/app/category/Category.model'
import { useQGCategoriesOperatingHoursQuery } from '@/app/qg/useQGCategoriesOperatingHoursQuery'
import { FlixGroupSlider } from '@/ui/components/flix/FlixGroupSlider'

export type RestaurantFlixProps = {
  category: Category
}

export const RestaurantFlix: React.FC<RestaurantFlixProps> = ({ category }) => {
  // Prefetch order time ranges to boot the query cache, that will be used in CategoryModal
  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: orderTimeRanges } = useQGCategoriesOperatingHoursQuery({
    categoryIds: [category.id],
  })

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row-reverse' }}
        justifyContent='flex-end'
        alignItems='baseline'
        spacing={{ base: 0, md: 4 }}
        px={{ base: 4, md: 8 }}
        mb={3}
      >
        <Heading
          as='h3'
          mb={1}
          fontSize={{ base: 'base', md: '2xl' }}
          fontWeight={400}
          color='creme.50'
          letterSpacing='wide'
        >
          {category.subtitle}
        </Heading>
        <Heading fontSize='4xl' color='peche.200' textShadow='text-protect'>
          {category.title}
        </Heading>
      </Stack>

      <FlixGroupSlider category={category} itemBorderColor='peche.200' px={{ base: 4, md: 8 }} />
    </>
  )
}
