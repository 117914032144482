import { useTranslation } from 'react-i18next'

import { SupportedLocales } from './localizable'

export const currentLocale = (i18n: ReturnType<typeof useTranslation>['i18n']): SupportedLocales => {
  return i18n.language as SupportedLocales
}

export const useCurrentLocale = (): SupportedLocales => {
  const { i18n } = useTranslation()
  return currentLocale(i18n)
}
