import dayjs from 'dayjs'
import { UseAxiosQueryOptions, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { useQGId } from '@/environment'

import { OrderTimeRange } from './QGConfig.model'

type OrderTimeRangeDTO = { start: string; end: string }

export type UseQGCategoriesOperatingHoursOptions = UseAxiosQueryOptions<OrderTimeRangeDTO[]> & {
  categoryIds: string[]
}

export const useQGCategoriesOperatingHoursQuery = ({
  categoryIds,
  ...options
}: UseQGCategoriesOperatingHoursOptions): UseQueryExtendedResult<OrderTimeRange[], OrderTimeRangeDTO[]> => {
  const id = useQGId()
  return useQuery({
    path: `/v1/qgs/${id}/categories/operatingHours`,
    params: { categoryIds },
    queryKey: ['qg', 'categories', 'operatingHours', categoryIds],
    requireAuthentication: false,
    mapper: (orderTimeRanges) =>
      orderTimeRanges.map((orderTimeRange) => ({
        start: dayjs(orderTimeRange.start).toDate(),
        end: dayjs(orderTimeRange.end).toDate(),
      })),
    ...options,
  })
}
