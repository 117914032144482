import { useQG } from '@/app/context/QGContext'
import { Section } from '@/app/section/Section.model'
import { useSelection } from '@/app/selection/SelectionContext'
import { Zone } from '@/app/zone/Zone.model'
import { ZoneType } from '@/app/zone/ZoneType'

export type SelectedSection =
  | {
      readonly zone: Zone
      readonly section: Section
    }
  | {
      readonly zone: undefined
      readonly section: undefined
    }

export function useSelectedSection(zoneType: ZoneType): SelectedSection {
  const {
    qg: { zones },
  } = useQG()
  const zone = zones.find(({ type }) => type === zoneType)
  const { zoneType: selectedZoneType, section } = useSelection()
  if (!zone) {
    return { zone: undefined, section: undefined }
  }
  return { zone, section: (zoneType === selectedZoneType && section) || zone.sections[0] }
}
