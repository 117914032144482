import React, { useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _debounce from 'lodash/debounce'
import _isFinite from 'lodash/isFinite'
import {
  Box,
  Button,
  ButtonGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
} from '@chakra-ui/react'
import { SPACE } from '@district-qg/frontend-common/src'

import { Gratuity } from '@/app/order/Gratuity'
import { PlaceOrderRequest } from '@/app/order/usePlaceOrder'
import { ButtonVariants } from '@/ui/theme/components/button'
import { InputVariants } from '@/ui/theme/components/input'

const buttonCustomStyles = {
  padding: 2,
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'initial',
}

export type GratuityFieldValues = Pick<PlaceOrderRequest, 'gratuity'>

export type OrderGratuityValueSelectorProps = {
  values: Array<number>
}

export const OrderGratuityValueSelector: React.FC<OrderGratuityValueSelectorProps> = ({ values }) => {
  const { t } = useTranslation('cart')

  const {
    field: { onChange: setGratuity, value: gratuity },
  } = useController<GratuityFieldValues, 'gratuity'>({ name: 'gratuity' })

  const [customAmount, setCustomAmountState] = useState<string>(() => {
    return gratuity?.type === 'amount' && gratuity.value ? gratuity.value.toFixed(2) : ''
  })

  const debouncedSetGratuity = useMemo(
    () =>
      _debounce((amount: number) => {
        setGratuity({ value: amount, type: 'amount' })
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const setCustomAmount = (amount: string) => {
    const amountAsFloat = amount !== '' ? parseFloat(amount) : 0
    if (_isFinite(amountAsFloat) && amountAsFloat >= 0) {
      setCustomAmountState(amount)
      debouncedSetGratuity(amountAsFloat)
    } else {
      setCustomAmountState('')
    }
  }

  function isCurrentPercentValue(v: Gratuity['value']) {
    return gratuity?.value === v && gratuity.type === 'percentage'
  }

  const setPercent = (v: number) => {
    debouncedSetGratuity.cancel()
    setGratuity(isCurrentPercentValue(v) ? { value: 0, type: 'percentage' } : { value: v, type: 'percentage' })
  }

  const toggleAmount = () => {
    debouncedSetGratuity.cancel()
    setGratuity(gratuity?.type === 'amount' ? { value: 0, type: 'percentage' } : { value: 0, type: 'amount' })
  }

  return (
    <Stack align='center'>
      <Text fontSize='sm' fontWeight='bold' textAlign='center' mb='1' color='peche.200'>
        {t('order.gratuity.label')}
      </Text>
      <ButtonGroup size='sm'>
        {values.map((v) => (
          <Button
            data-testid={`GratuityValueSelectorButton-${v}`}
            key={v}
            variant={isCurrentPercentValue(v) ? ButtonVariants.solidOnLight : ButtonVariants.outlineOnDark}
            sx={buttonCustomStyles}
            onClick={() => setPercent(v)}
          >
            {`${v}${SPACE.THIN}%`}
          </Button>
        ))}
        <Button
          data-testid='GratuityValueSelectorButton-amount'
          key='amount'
          variant={gratuity?.type === 'amount' ? ButtonVariants.solidOnLight : ButtonVariants.outlineOnDark}
          sx={buttonCustomStyles}
          onClick={toggleAmount}
        >
          {t('order.gratuity.amount.button')}
        </Button>
      </ButtonGroup>
      {gratuity?.type === 'amount' && (
        <Box mt='2'>
          <Text fontSize='xs' fontWeight='bold' textAlign='center' mb='1' color='peche.200'>
            {t('order.gratuity.amount.label')}
          </Text>
          <NumberInput
            variant={InputVariants.onDark}
            size='sm'
            maxWidth='8rem'
            precision={2}
            min={0}
            value={customAmount}
            onChange={(valueAsString) => setCustomAmount(valueAsString)}
            onBlur={() => {
              debouncedSetGratuity.cancel()
              setGratuity({ value: Number(customAmount), type: 'amount' })
            }}
          >
            <NumberInputField autoFocus data-testid='GratuityValueSelector-Input' />
            <InputRightElement color='peche.200'>$</InputRightElement>
          </NumberInput>
        </Box>
      )}
    </Stack>
  )
}
