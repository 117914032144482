import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { BoxProps, Stack, Text } from '@chakra-ui/react'

import { useMenuExperience } from '@/app/experience/ExperienceContext'

export const Footer: React.FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation('common')
  const { experience } = useMenuExperience()

  return (
    <Stack py={{ base: '12' }} px={{ base: '4', md: '12' }} {...props}>
      <Text color='steel.base' fontSize='sm' textAlign='right'>
        {t('experience.title')} {t(`experience.${experience}`)}
      </Text>
    </Stack>
  )
}
