import * as React from 'react'
import { event } from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { IconButton, useToast } from '@chakra-ui/react'
import { AddIcon } from '@district-qg/ui'

import { Site } from '@/app/context/Site'
import { useCurrentSite } from '@/app/context/SiteContext'
import { Item } from '@/app/item/Item.model'
import { useQGCategoriesOperatingHoursQuery } from '@/app/qg/useQGCategoriesOperatingHoursQuery'
import { AlertVariants } from '@/ui/theme/components/alert'
import { ButtonVariants } from '@/ui/theme/components/button'

export type AddItemButtonProps = Readonly<{
  item: Item
  categoryId: string
}>

export const AddItemToCartButton: React.FC<AddItemButtonProps> = ({ item, categoryId }) => {
  const { t } = useTranslation()
  const { addItem } = useCart()
  const toast = useToast()
  const site = useCurrentSite()
  const { data: orderTimeRanges } = useQGCategoriesOperatingHoursQuery({
    categoryIds: [categoryId],
  })

  if (!orderTimeRanges) return null

  const isOnlineAndClosed = site === Site.ONLINE && !orderTimeRanges.length

  const onClick = () => {
    event({
      category: 'Cart Item',
      action: 'Add',
    })
    if (isOnlineAndClosed) {
      toast({
        title: t('warning.outsideOperatingHours.toast.title'),
        description: t('warning.outsideOperatingHours.toast.description'),
        status: 'warning',
        variant: AlertVariants.warning,
        isClosable: true,
        duration: 3000,
      })
    } else {
      addItem({ ...item, price: item.price.amount, id: item.id })
      toast({
        title: t('success.itemAddedToCart.title'),
        description: t('success.itemAddedToCart.description'),
        status: 'success',
        variant: AlertVariants.success,
        isClosable: true,
        duration: 3000,
      })
    }
  }

  return (
    <IconButton
      size='xs'
      borderRadius='base'
      data-testid='AddItemToCartButton'
      onClick={onClick}
      aria-label={t('action.add')}
      variant={isOnlineAndClosed ? ButtonVariants.outlineFadeOnDark : ButtonVariants.outlineOnDark}
      icon={<AddIcon boxSize='3' />}
    />
  )
}
