import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Text } from '@chakra-ui/react'

import { useQGCategoriesOperatingHoursQuery } from '@/app/qg/useQGCategoriesOperatingHoursQuery'

const defaultHorizontalPadding = { base: '4', md: '40' }

export type CategoryAvailabilityWarningBannerProps = Readonly<{
  categoryId: string
}>

export const CategoryAvailabilityWarningBanner: React.FC<CategoryAvailabilityWarningBannerProps> = ({ categoryId }) => {
  const { t } = useTranslation()
  const { data: orderTimeRanges } = useQGCategoriesOperatingHoursQuery({
    categoryIds: [categoryId],
  })

  if (!orderTimeRanges) return null

  return (
    <>
      {!orderTimeRanges.length && (
        <Box align='center'>
          <Text
            bg='peche.200'
            color='bordeaux.700'
            fontSize='14'
            px={defaultHorizontalPadding}
            lineHeight='4'
            paddingTop='1.5'
            paddingBottom='2'
          >
            {t('warning.outsideOperatingHours.banner')}
          </Text>
        </Box>
      )}
    </>
  )
}
