import { useCallback } from 'react'

import { UseAxiosQueryOptions, useCurrentLocale, useQuery, UseQueryExtendedResult } from '@district-qg/frontend-common'

import { UserDto } from '@/app/user/User.dto'
import { buildModel, User } from '@/app/user/User.model'

export const USER_QUERY_KEY = 'user'

export const useUserQuery = (options?: UseAxiosQueryOptions<UserDto>): UseQueryExtendedResult<User, UserDto> => {
  const locale = useCurrentLocale()
  const mapper = useCallback((dto: UserDto) => buildModel(dto, locale), [locale])

  return useQuery({
    queryKey: USER_QUERY_KEY,
    path: '/v1/user',
    mapper,
    ...options,
  })
}
