import React from 'react'

import { RestaurantZoneDesktop } from '@/ui/components/zone/RestaurantZoneDesktop'

import { MarketZone } from './MarketZone'

export const ZoneListDesktop: React.FC = () => {
  return (
    <>
      <RestaurantZoneDesktop />
      <MarketZone />
    </>
  )
}
