import { css } from '@emotion/react'

export const iframeStyle = (): string => {
  const documentStyle = getComputedStyle(document.documentElement)

  const fontColor = documentStyle.getPropertyValue('--chakra-colors-aubergine-800')
  const placeholderFontColor = documentStyle.getPropertyValue('--chakra-colors-aubergine-400')
  const height = documentStyle.getPropertyValue('--chakra-sizes-10')
  const transitionProperty = documentStyle.getPropertyValue('--chakra-transition-property-common')
  const transitionDuration = documentStyle.getPropertyValue('--chakra-transition-duration-normal')

  const borderColorOnError = documentStyle.getPropertyValue('--chakra-colors-error-dark')
  const backgroundColorOnError = documentStyle.getPropertyValue('--chakra-colors-red-100')
  const backgroundColorOnFocus = documentStyle.getPropertyValue('--chakra-colors-whiteAlpha-700')

  return css`
    .payfacto-object .payfacto-input-title {
      color: ${fontColor};
      font-style: normal;
    }

    .payfacto-object .payfacto-input-wrapper {
      border: 1px solid ${fontColor};
      background-color: transparent;
      padding: 0 1rem;
      height: ${height};
      transition-property: ${transitionProperty};
      transition-duration: ${transitionDuration};
    }

    .payfacto-object .payfacto-input-wrapper:hover,
    .payfacto-object .payfacto-input-wrapper:focus-within {
      background-color: ${backgroundColorOnFocus};
    }

    .payfacto-input-simple.invalid .payfacto-input-wrapper {
      border: 1px solid ${borderColorOnError};
      background-color: ${backgroundColorOnError};
    }

    .payfacto-input-simple.valid .payfacto-input-wrapper {
      border: 1px solid ${fontColor};
    }

    .payfacto-input-simple.invalid.focus .payfacto-input-wrapper,
    .payfacto-input-simple.valid.focus .payfacto-input-wrapper {
      box-shadow: none;
    }

    .payfacto-input-simple.invalid .payfacto-input-feedback-error-icon,
    .payfacto-input-simple.valid .payfacto-input-feedback-success-icon,
    .payfacto-object .payfacto-input-wrapper .payfacto-input-icon,
    .payfacto-object .payfacto-input-wrapper .payfacto-input-feedback {
      display: none;
    }

    .payfacto-object .payfacto-input-input {
      color: ${fontColor};
    }

    .payfacto-object .payfacto-input-input::placeholder {
      color: ${placeholderFontColor};
    }
  `.styles
}
