import { ImportMetaEnv } from './EnvironmentProvider'

export enum CommonEnvironmentKey {
  AUTH0_AUDIENCE = 'VITE_AUTH0_AUDIENCE',
  BASE_URL = 'VITE_QG_API_BASE_URL',
  IMAGE_SERVICE_BASE_URL = 'VITE_IMAGE_SERVICE_BASE_URL',
}

export function isCommonEnvironmentKey(key: string): key is CommonEnvironmentKey {
  return Object.values(CommonEnvironmentKey).some((e) => e === key)
}

export const getEnvironment = <T extends string | boolean = string>(
  environment: ImportMetaEnv,
  key: CommonEnvironmentKey
): T => {
  const value = environment[key]
  if (!value) {
    throw new Error(`Missing Environment Variable ${key}`)
  }
  return value as T
}
