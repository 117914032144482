import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonProps } from '@chakra-ui/react'

import { ModalActionButton } from '@/ui/components/modal/ModalActionButton'

export const CompleteProfileButton: React.FC<ButtonProps> = ({ ...props }) => {
  const { t } = useTranslation()

  return <ModalActionButton {...props}>{t('user.profile.action.complete')}</ModalActionButton>
}
