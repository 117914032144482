import { useMemo } from 'react'

import { ModelBuilder, SimpleModelBuilder } from '../model'

import { SupportedLocales } from './localizable'
import { useCurrentLocale } from './useCurrentLocale'

export type BuilderWithLocale<Model, DTO> = {
  (dto: DTO, locale: SupportedLocales): Model
  (dto: DTO[], locale: SupportedLocales): Model[]
}

export function useModelBuilderWithCurrentLocale<Model, DTO>(
  builder: (dto: DTO, locale: SupportedLocales) => Model
): SimpleModelBuilder<Model, DTO>
export function useModelBuilderWithCurrentLocale<Model, DTO>(
  builder: (dto: DTO[], locale: SupportedLocales) => Model[]
): SimpleModelBuilder<Model, DTO>
export function useModelBuilderWithCurrentLocale<Model, DTO>(
  builder: BuilderWithLocale<Model, DTO>
): ModelBuilder<Model, DTO>

export function useModelBuilderWithCurrentLocale<Model, DTO>(
  builder: (dto: DTO | DTO[], locale: SupportedLocales) => Model | Model[]
): (dto: DTO | DTO[]) => Model | Model[] {
  const locale = useCurrentLocale()
  return useMemo(() => (data: DTO | DTO[]) => builder(data, locale), [builder, locale])
}
