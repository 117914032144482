import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from '@chakra-ui/react'

export const ReservationLink: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <Link
      href={t('action.reserveUrl')}
      isExternal
      display='inline-flex'
      alignItems='center'
      h={9}
      px={3}
      mr={-2}
      borderRadius='base'
      color='aubergine.base'
      _hover={{ background: 'steel.100', color: 'aubergine.700' }}
      fontSize='xl'
      fontFamily='heading'
      fontWeight='600'
      textTransform='uppercase'
    >
      {t('action.reserve')}
    </Link>
  )
}
