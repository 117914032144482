import * as React from 'react'

import { Box, ModalFooter, UseModalProps } from '@chakra-ui/react'

import { Modal, ModalContent, ModalFixedHeader, ModalOverlay } from '@/ui/components/modal/Modal'
import { ModalCloseButton } from '@/ui/components/modal/ModalCloseButton'
import { ProfileModalBody } from '@/ui/pages/profile/components/ProfileModalBody'
import { ProfileModalHeader } from '@/ui/pages/profile/components/ProfileModalHeader'

export type ProfileModalProps = UseModalProps

const widthStyle = { base: 'xs', sm: 'sm', md: 'md' }

export const ProfileModal: React.FC<ProfileModalProps> = ({ ...modalProps }) => (
  <Modal autoFocus={false} {...modalProps}>
    <ModalOverlay />
    <ModalContent>
      <ModalFixedHeader top='4' right='4'>
        <ModalCloseButton />
      </ModalFixedHeader>
      <Box width={widthStyle} margin='auto' marginTop='12'>
        <ProfileModalHeader />
        <ProfileModalBody />
      </Box>
      <ModalFooter paddingTop='32' />
    </ModalContent>
  </Modal>
)
