import { useImageServiceURL } from '@district-qg/frontend-common'

import { QGImageType } from './QGImageType'

function path(type?: QGImageType) {
  switch (type) {
    case QGImageType.SectionBackground:
      return 'sections/background'
    case QGImageType.Category:
      return 'categories/main'
    case QGImageType.Item:
      return 'items/main'
    case QGImageType.Group:
      return 'groups/main'
    /* istanbul ignore next */
    default:
      throw new Error(`Unknown image type ${type}`)
  }
}

export function useImgixUrlBuilder(): (id: string, type: QGImageType) => string
export function useImgixUrlBuilder(defaultType: QGImageType): (id: string) => string
export function useImgixUrlBuilder(defaultType?: QGImageType): (id: string, type?: QGImageType) => string {
  const baseUrl = useImageServiceURL()
  return (id: string, type?: QGImageType): string => `${baseUrl}/images/${path(type ?? defaultType)}/${id}`
}
