import * as React from 'react'

import { Item } from '@/app/item/Item.model'
import { ModalItemList } from '@/ui/components/modal/ModalItemList'
import { GroupItem } from '@/ui/pages/category/components/GroupItem'

export type GroupItemListProps = {
  items?: ReadonlyArray<Item>
  categoryId: string
}

const defaultLeftMargin = { base: '4', md: '5' }
const defaultRightMargin = { base: '4', md: '6' }

export const GroupItemList: React.FC<GroupItemListProps> = ({ items, categoryId }) => (
  <ModalItemList marginLeft={defaultLeftMargin} marginRight={defaultRightMargin} my='4'>
    {items?.map((item) => (
      <GroupItem key={item.id} item={item} categoryId={categoryId} />
    ))}
  </ModalItemList>
)
