import { Localizable, SupportedLocales } from './localizable'

const getUndefinedMarker = (context?: string) => `${context ?? ''}`

export function localize(
  localizable?: Localizable | (Localizable | undefined)[],
  locale = SupportedLocales.fr,
  context?: string
): string {
  if (localizable == null) {
    return getUndefinedMarker(context)
  }
  if (Array.isArray(localizable)) {
    if (!localizable.length) {
      return getUndefinedMarker(context)
    }
    const [first, ...rest] = localizable
    return first?.[locale] ?? localize(rest, locale, context)
  }
  return localizable[locale] ?? getUndefinedMarker(context)
}
