import * as React from 'react'

import { HStack, StackProps, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import { SvgImage } from '@district-qg/ui'

import { ReactComponent as DesktopLogo } from '@/ui/assets/images/le-district-gourmet.svg'
import { ReactComponent as MobileLogo } from '@/ui/assets/images/le-qg-cadre.svg'

export type HeaderProps = Omit<StackProps, 'w' | 'width' | 'spacing' | 'position' | 'top'>

export const Header: React.FC<
  HeaderProps & {
    breakPointQGLogo?: Parameters<typeof useBreakpointValue>[0]
  }
> = ({
  breakPointQGLogo = {
    base: MobileLogo,
    xs: MobileLogo,
    sm: MobileLogo,
    md: DesktopLogo,
  },
  children,
  ...headerProps
}) => {
  const icon = useBreakpointValue(breakPointQGLogo) as React.ElementType

  return (
    <>
      <HStack
        w='full'
        spacing='4'
        bgColor={useColorModeValue('bg.light', 'bg.dark')}
        justifyContent='space-between'
        {...headerProps}
      >
        <SvgImage
          svg={icon}
          w={{ base: '14', md: '154px' }}
          h='auto'
          aria-label='Le District Gourmet'
          fill={useColorModeValue('text.dark', 'text.light')}
          data-testid='qg-logo'
        />
        <HStack spacing={{ base: 4, lg: 8 }}>{children}</HStack>
      </HStack>
    </>
  )
}
