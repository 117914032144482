import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@chakra-ui/react'

import { UnavailableItemErrorMessage } from '@/app/order/OrderItem.model'
import { OperatingHoursSchedule } from '@/app/types/operatingHours'
import { ItemAvailabilityMessage } from '@/ui/organisms/item/ItemAvailabilityMessage'

export type CartItemErrorMessageProps = {
  error?: UnavailableItemErrorMessage
  itemDailySchedule: OperatingHoursSchedule[]
  categoryDailySchedule: OperatingHoursSchedule[]
}

export const CartItemErrorMessage: React.FC<CartItemErrorMessageProps> = ({
  error,
  itemDailySchedule: dailySchedule,
  categoryDailySchedule,
}) => {
  const { t } = useTranslation(['cart'])

  if (!error) return null

  if (error === UnavailableItemErrorMessage.ItemCategoryOutOfScheduleError) {
    return <ItemAvailabilityMessage operatingHoursSchedule={categoryDailySchedule} />
  }

  if (error === UnavailableItemErrorMessage.ItemOutOfScheduleError) {
    return <ItemAvailabilityMessage operatingHoursSchedule={dailySchedule} />
  }

  return (
    <Text color='error.dark' fontSize='sm' fontWeight='bold'>
      {t(`cart:content.item.errors.${error}`)}
    </Text>
  )
}
