import * as React from 'react'

import { Button, useModalContext } from '@chakra-ui/react'

import { CloseButton } from '@/ui/components/CloseButton/CloseButton'
import { ButtonVariants } from '@/ui/theme/components/button'

export const ModalCloseButton: React.FC = ({ children }) => {
  const { onClose } = useModalContext()

  return children ? (
    <Button variant={ButtonVariants.solidOnLight} data-testid='CloseButton' onClick={onClose}>
      {children}
    </Button>
  ) : (
    <CloseButton data-testid='CloseButton' onClick={onClose} />
  )
}
