import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Box, Heading, HStack } from '@chakra-ui/react'

import { useAppPaths } from '@/app/context/useAppPaths'
import { useSelectedSection } from '@/app/selection/useSelectedSection'
import { ZoneType } from '@/app/zone/ZoneType'
import { DESTINATION_SELECTOR_HEIGHT, HEADER_HEIGHT } from '@/constants'
import { ONLINE_ROUTES } from '@/routes'
import { ScrollPositionSpy } from '@/ui/components/scrollPositionSpy/ScrollPositionSpy'

import { RestaurantSection } from './RestaurantSection'

const sectionMenuStyles = {
  height: 'var(--destination-selector-height)',
  mb: 'calc(var(--destination-selector-height) * -1)',
  px: { base: 0, md: 4 },
  alignItems: 'stretch',
  bgColor: 'overlay.black.60',
  position: { base: 'sticky', md: 'absolute' },
  top: { base: 'var(--header-height)', md: 0 },
  left: 0,
  right: 0,
  zIndex: 2,
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollSnapType: 'x proximity',

  _after: {
    content: '""',
    width: '85vw',
    flexShrink: 0,
  },
}

const sectionAnchorStyles = {
  display: 'flex',
  alignItems: 'center',
  px: 4,
  scrollSnapAlign: 'start',

  color: 'creme.50',
  '&.active': {
    color: 'peche.200',
  },
  '@media (hover: hover)': {
    _hover: { color: 'peche.200' },
  },
}

export const RestaurantZoneMobile: React.FC = () => {
  const { zone, section: selectedSection } = useSelectedSection(ZoneType.restaurant)
  const { sectionPath } = useAppPaths()
  const cartPage = useRouteMatch({
    path: ONLINE_ROUTES.CART,
    exact: false,
  })
  const categoryPage = useRouteMatch({
    path: ONLINE_ROUTES.SECTION_GROUP,
    exact: false,
  })
  const history = useHistory()

  const sectionMenuRef = React.useRef<HTMLDivElement>(null)
  const sectionMenuItemRefs = React.useRef(new Map<string, HTMLElement>())
  const sectionRefs = React.useRef(new Map<string, HTMLElement>())
  const isScrolling = React.useRef(false)

  const activeRestaurantSectionId = React.useRef(selectedSection?.id)

  const scrollToSection = (sectionId: string) => {
    const targetSection = sectionRefs.current.get(sectionId)
    /* istanbul ignore else */
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  /* istanbul ignore next */
  const scrollToSectionMenuItem = (sectionId: string) => {
    const sectionMenuItem = sectionMenuItemRefs.current.get(sectionId)
    if (sectionMenuItem && sectionMenuRef.current) {
      sectionMenuItemRefs.current.forEach((anchor) => anchor && anchor.classList.remove('active'))
      sectionMenuItem.classList.add('active')
      sectionMenuRef.current.scrollTo({
        top: 0,
        left: sectionMenuItem.offsetLeft,
        behavior: 'smooth',
      })
    }
  }

  /* istanbul ignore next */
  const handleGoToSection = (sectionId: string) => {
    isScrolling.current = true
    activeRestaurantSectionId.current = sectionId
    scrollToSectionMenuItem(sectionId)
    scrollToSection(sectionId)
    history.push(sectionPath(sectionId))
    setTimeout(() => {
      isScrolling.current = false
    }, 1000)
  }

  /* istanbul ignore next */
  const handleScrollToSection = (sectionId: string) => {
    if (!isScrolling.current && sectionId !== activeRestaurantSectionId.current) {
      activeRestaurantSectionId.current = sectionId
      scrollToSectionMenuItem(sectionId)
    }
  }

  /* istanbul ignore next */
  React.useLayoutEffect(() => {
    if (!isScrolling.current && !cartPage && !categoryPage) {
      if (selectedSection) {
        handleGoToSection(selectedSection.id)
      } else {
        activeRestaurantSectionId.current = zone?.sections[0].id
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection])

  if (!zone) {
    return null
  }

  return (
    <>
      <Box
        position='relative'
        sx={{
          '--destination-selector-height': `${DESTINATION_SELECTOR_HEIGHT}px`,
        }}
      >
        <Box
          position={{ base: 'absolute', md: 'relative' }}
          top={0}
          height={{ base: 'calc(100% - var(--destination-selector-height))', md: 'auto' }}
          width='100%'
        >
          <HStack ref={sectionMenuRef} spacing='0' className='scrollbar-thin' sx={sectionMenuStyles}>
            {zone.sections.map((section, index) => {
              const getRef = (element: HTMLDivElement) => sectionMenuItemRefs.current.set(section.id, element)

              return (
                <Box
                  ref={getRef}
                  key={section.id}
                  onClick={
                    /* istanbul ignore next */
                    () => handleGoToSection(section.id)
                  }
                  data-testid={`section-tab-${index}`}
                  className={section.id === activeRestaurantSectionId.current ? 'active' : ''}
                  sx={sectionAnchorStyles}
                >
                  <Heading whiteSpace='nowrap' fontSize='xl'>
                    {section.name}
                  </Heading>
                </Box>
              )
            })}
          </HStack>
        </Box>

        {zone.sections.map((section) => {
          const getRef = (element: HTMLDivElement) => sectionRefs.current.set(section.id, element)

          return (
            <div key={section.id} ref={getRef}>
              <ScrollPositionSpy
                callback={
                  /* istanbul ignore next */
                  () => handleScrollToSection(section.id)
                }
                offsetPx={HEADER_HEIGHT + DESTINATION_SELECTOR_HEIGHT}
                throttleMs={200}
              >
                <RestaurantSection section={section} />
              </ScrollPositionSpy>
            </div>
          )
        })}
      </Box>
    </>
  )
}
