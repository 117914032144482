import _get from 'lodash/get'
import _isObject from 'lodash/isObject'
import _mapValues from 'lodash/mapValues'

import { CommonEnvironmentKey, getEnvironment, isCommonEnvironmentKey } from './commonEnvironment'
import { ImportMetaEnv, useEnvironmentContext } from './EnvironmentProvider'

export function useEnvironment(): ImportMetaEnv
export function useEnvironment<T extends string | boolean = string>(key: CommonEnvironmentKey): T
export function useEnvironment<T extends string | boolean = string>(key: string): T | undefined
export function useEnvironment<T extends string | boolean>(key: string, defaultValue: T): T
export function useEnvironment<S extends Record<string, string | boolean>>(keysWithDefaultValues: S): S
export function useEnvironment(
  key?: string | ImportMetaEnv,
  defaultValue?: string
): ImportMetaEnv | string | boolean | undefined {
  const environment = useEnvironmentContext()
  if (!key) {
    return environment
  }
  if (_isObject(key)) {
    return _mapValues(key, (value, k) => _get(environment, k, value))
  }
  if (isCommonEnvironmentKey(key)) {
    return getEnvironment(environment, key)
  }
  return _get(environment, key, defaultValue)
}
