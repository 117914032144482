import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading, Icon } from '@chakra-ui/react'

import { ReactComponent as Logo } from '@/ui/assets/images/le-qg-cadre.svg'

export const ProfileModalHeader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box textAlign='center'>
      <Icon as={Logo} width='83px' height='auto' fill='creme.50' aria-label='Le QG' />
      <Heading size='xl' color='creme.50' marginTop='8'>
        {t('user.profile.header.title')}
      </Heading>
    </Box>
  )
}
