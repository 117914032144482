import React from 'react'
import ReactDOM from 'react-dom'
import { initialize } from 'react-ga'
import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import smoothscroll from 'smoothscroll-polyfill'
import { Auth0Provider } from '@auth0/auth0-react'
import { EnvironmentProvider, sentryConfig } from '@district-qg/frontend-common'
import { init as initSentry } from '@sentry/react'

import { App } from '@/App'
import { SiteProvider } from '@/app/context/SiteContext'
import { init18n } from '@/i18n'
import { ROUTES } from '@/routes'

smoothscroll.polyfill()

const {
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_ADMIN_APP_CLIENT_ID,
  VITE_AUTH0_ADMIN_DATABASE_NAME,
  VITE_AUTH0_AUDIENCE,
  VITE_GOOGLE_ANALYTICS_TRACKING_ID,
  MODE,
} = import.meta.env

initialize(VITE_GOOGLE_ANALYTICS_TRACKING_ID)

export const history = createBrowserHistory()
initSentry(sentryConfig(history, ROUTES, import.meta.env))
init18n()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const auth0CacheLocation = window.Cypress ? 'localstorage' : 'memory'

ReactDOM.render(
  <React.StrictMode>
    <EnvironmentProvider environment={import.meta.env}>
      <Auth0Provider
        domain={VITE_AUTH0_DOMAIN}
        clientId={VITE_AUTH0_ADMIN_APP_CLIENT_ID}
        redirectUri={window.location.origin}
        connection={VITE_AUTH0_ADMIN_DATABASE_NAME}
        audience={VITE_AUTH0_AUDIENCE}
        cacheLocation={auth0CacheLocation}
      >
        <Router history={history}>
          <SiteProvider>
            <App isDev={MODE === 'development'} />
          </SiteProvider>
        </Router>
      </Auth0Provider>
    </EnvironmentProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
if (import.meta.hot) {
  import.meta.hot?.accept?.()
}
