import { event } from 'react-ga'
import { useCart } from 'react-use-cart'

import { Item } from '@/app/item/Item.model'

import { DraftOrder } from './DraftOrder.model'

export function useRemoveUnavailableItemsFromCart(draftOrder: DraftOrder): {
  removeUnavailableItemsFromCart: () => void
} {
  const { getItem, updateItemQuantity } = useCart()

  const removeUnavailableItemsFromCart = () => {
    // The map exists because the item quantity is not updated after updateItemQuantity is called...
    const quantityMap: { [key: Item['id']]: number } = {}
    draftOrder.items.forEach((item) => {
      if (item.error) {
        const itemQuantity = quantityMap[item.id] ?? getItem(item.id).quantity
        updateItemQuantity(item.id, itemQuantity - 1)
        quantityMap[item.id] = itemQuantity - 1
        event({
          category: 'Cart Item',
          action: 'Remove unavailable items',
        })
      }
    })
  }

  return { removeUnavailableItemsFromCart }
}
