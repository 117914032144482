import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Site } from '@/app/context/Site'
import { ON_SITE_ROUTES } from '@/routes'

const SiteContext = React.createContext<Site>(Site.ONLINE)

export const SiteProvider: React.FC = ({ children }) => {
  const value = useRouteMatch({
    path: ON_SITE_ROUTES.HOME,
    exact: false,
  })
    ? Site.ONSITE
    : Site.ONLINE

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

export const useCurrentSite = (): Site => React.useContext(SiteContext)
