import * as React from 'react'
import { event } from 'react-ga'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, Stack, Text, useModalContext, useToast } from '@chakra-ui/react'

import { UpdateUserRequest, useUpdateUserMutation } from '@/app/user/useUpdateUserMutation'
import { ModalActionButton } from '@/ui/components/modal/ModalActionButton'
import { ProfileModalContentProps, ProfileModalForm } from '@/ui/pages/profile/components/ProfileModalForm'
import { fromDto, ProfileModalFormData, toUpdateRequest } from '@/ui/pages/profile/components/ProfileModalFormData'

export const ProfileModalFormProvider: React.FC<ProfileModalContentProps> = ({ user }) => {
  const { t } = useTranslation()

  const methods = useForm<UpdateUserRequest>({ defaultValues: fromDto(user), mode: 'onChange' })

  const toast = useToast()
  const onUpdateError = () => {
    toast({
      title: t('user.profile.error.title'),
      description: t('user.profile.error.description'),
      status: 'error',
      isClosable: true,
      duration: 9000,
    })
  }

  const { onClose } = useModalContext()
  const { mutate, isLoading } = useUpdateUserMutation({
    onError: onUpdateError,
    onSuccess: onClose,
  })

  const saveProfile = (profileData: ProfileModalFormData) => {
    mutate(toUpdateRequest(profileData))
    event({
      category: 'Profile',
      action: 'Save',
    })
  }

  return (
    <FormProvider {...methods}>
      <Stack as='form' spacing={8} marginTop={8}>
        <ProfileModalForm user={user} />
        <Box textAlign='center'>
          {!methods.formState.isValid && (
            <Text mb={4} fontSize='xs' color='white'>
              <Box as='span' mr={1} color='error.dark' aria-hidden>
                *
              </Box>
              {t('user.profile.content.instructions')}
            </Text>
          )}
          <ModalActionButton
            data-testid='save-user-profile'
            onClick={methods.handleSubmit(saveProfile)}
            isDisabled={!methods.formState.isValid}
            isLoading={isLoading}
          >
            {t('action.save')}
          </ModalActionButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
