import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { QGDto } from '@/app/qg/QG.dto'
import { buildZoneModel, Zone } from '@/app/zone/Zone.model'

export interface QG {
  readonly id: string
  readonly address: {
    readonly streetAddress: string
    readonly note: string
    readonly mapUrl: string
  }
  readonly customerService: {
    readonly email: string
    readonly phoneNumber: string
  }
  readonly name: string
  readonly openingHours: string
  readonly zones: ReadonlyArray<Zone>
  readonly legal: {
    readonly termsOfService: string
    readonly privacyPolicy: string
  }
}

export class QGModel implements QG {
  readonly zones = buildZoneModel(this.data.foodDistrict.zones, this.locale)

  readonly address = {
    streetAddress: localize(this.data.address.streetAddress, this.locale),
    note: localize(this.data.address.note, this.locale),
    mapUrl: localize(this.data.address.mapUrl, this.locale),
  }

  readonly legal = {
    termsOfService: localize(this.data.legal.termsOfService, this.locale),
    privacyPolicy: localize(this.data.legal.privacyPolicy, this.locale),
  }

  readonly openingHours = localize(this.data.openingHours, this.locale)

  readonly name = localize(this.data.name, this.locale)

  constructor(private readonly data: Readonly<QGDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get customerService(): QG['customerService'] {
    return this.data.customerService
  }
}

export function buildQGModel(data: QGDto, locale: SupportedLocales): QG {
  return new QGModel(data, locale)
}
