import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { Box } from '@chakra-ui/react'

import { CartMessage } from '@/ui/pages/cart/components/CartMessage'
import { OnSiteCartItemList } from '@/ui/pages/onsite/cart/components/OnSiteCartItemList'

export const OnSiteCartBody: React.FC = () => {
  const { t } = useTranslation('selection')
  const { isEmpty } = useCart()

  return (
    <Box flex='1'>
      <Box px='5'>{isEmpty ? <CartMessage message={t('content.empty')} /> : <OnSiteCartItemList />}</Box>
    </Box>
  )
}
