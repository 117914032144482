export const ZONE_TYPE = ':zoneType'
export const SECTION_ID = ':sectionId'
export const CATEGORY_ID = ':categoryId'
export const GROUP_ID = ':groupId'

export type AppRoutes = {
  readonly HOME: string
  readonly ZONE: string
  readonly CATEGORY: string
  readonly SECTION: string
  readonly SECTION_GROUP: string
  readonly CART: string
}

export const ONLINE_ROUTES = {
  HOME: '/',
  CATEGORY: `/restaurant/${CATEGORY_ID}`,
  ZONE: `/zones/${ZONE_TYPE}`,
  SECTION: `/terroirs/${SECTION_ID}`,
  SECTION_GROUP: `/terroirs/${SECTION_ID}/specialites/${GROUP_ID}`,
  CART: '/panier',
}

export const ON_SITE_ROUTES = {
  HOME: '/menu',
  CATEGORY: `/menu/restaurant/${CATEGORY_ID}`,
  ZONE: `/menu/zones/${ZONE_TYPE}`,
  SECTION: `/menu/terroirs/${SECTION_ID}`,
  SECTION_GROUP: `/menu/terroirs/${SECTION_ID}/specialites/${GROUP_ID}`,
  CART: '/menu/panier',
  EXPERIENCE_DG: `/menu/experience/dg`,
  EXPERIENCE_CHEF: `/menu/experience/chef/${CATEGORY_ID}`,
}

export const PROFILE_PAGE_PATH = '/profil'

export const ROUTES = [...Object.values(ONLINE_ROUTES), ...Object.values(ON_SITE_ROUTES), PROFILE_PAGE_PATH]
