import React from 'react'
import { event } from 'react-ga'
import { CartProvider } from 'react-use-cart'

export const CartProviderWithAnalytic: React.FC = ({ children }) => {
  const onItemAdd = () => {
    // TODO: track item added to cart
    event({
      category: 'Cart',
      action: 'Add',
    })
  }
  const onItemRemove = () => {
    // TODO: track item removed to cart
    event({
      category: 'Cart',
      action: 'Remove',
    })
  }
  const onItemUpdate = () => {
    // TODO: track quantity change
    event({
      category: 'Cart',
      action: 'Change Quantity',
    })
  }

  return (
    <CartProvider onItemAdd={onItemAdd} onItemRemove={onItemRemove} onItemUpdate={onItemUpdate}>
      {children}
    </CartProvider>
  )
}
