import { useCart } from 'react-use-cart'

import { Gratuity } from '@/app/order/Gratuity'

export type Cart = {
  readonly items: ReadonlyArray<{
    readonly id: string
    readonly quantity: number
  }>
}

export type OrderRequest = Cart & {
  readonly type: 'delivery' | 'takeout'
  readonly gratuity?: Gratuity
  readonly expectedDateTime?: Date
}

type UseCartItems = ReturnType<typeof useCart>['items']

export function buildCartModel(items: UseCartItems): Cart {
  return { items: items.map(({ id, quantity = 1 }) => ({ id, quantity })) }
}
