import React, { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useCart } from 'react-use-cart'

import useLocalStorage from 'use-local-storage'

import { Category } from '@/app/category/Category.model'
import { Experience } from '@/app/experience/Experience'
import { ON_SITE_ROUTES } from '@/routes'

export type CurrentExperience = {
  readonly experience: Experience
  readonly setDGExperience: () => void
  readonly setChefExperience: (categoryId: string) => void
}

export type ExperienceContextData = CurrentExperience

const ExperienceContext = React.createContext<ExperienceContextData>({
  experience: Experience.DG,
  setDGExperience: /* istanbul ignore next */ () => undefined,
  setChefExperience: /* istanbul ignore next */ () => undefined,
})

export const ExperienceContextProvider: React.FC = ({ children }) => {
  const [storageExperience, setStorageExperience] = useLocalStorage('experience', Experience.DG)
  const [experience, setExperience] = useState(storageExperience)
  const { emptyCart } = useCart()
  const { push } = useHistory()

  const setExperienceAndNavigate = async (newExperience: Experience, categoryId?: Category['id']) => {
    setExperience(newExperience)
    setStorageExperience(newExperience)
    emptyCart()

    if (categoryId) {
      const routePath: string = generatePath(ON_SITE_ROUTES.CATEGORY, { categoryId })
      return push(routePath)
    }
    return push(ON_SITE_ROUTES.HOME)
  }
  const value = {
    experience,
    setDGExperience: () => setExperienceAndNavigate(Experience.DG),
    setChefExperience: (categoryId: string) => setExperienceAndNavigate(Experience.CHEF, categoryId),
  }
  return <ExperienceContext.Provider value={value}>{children}</ExperienceContext.Provider>
}

export const useMenuExperience = (): ExperienceContextData => React.useContext(ExperienceContext)
