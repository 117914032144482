import { Localizable } from '@district-qg/frontend-common'

import { ItemDto } from '@/app/item/Item.dto'
import { GratuityType } from '@/app/order/Gratuity'

import { OperatingHours } from '../types/operatingHours'

export enum OrderItemError {
  ItemDateUnavailabilityError = 'ItemDateUnavailabilityError',
  ItemNotActiveOnlineError = 'ItemNotActiveOnlineError',
  OutOfItemOperatingHoursError = 'OutOfItemOperatingHoursError',
  OutOfRestaurantOperatingHoursError = 'OutOfRestaurantOperatingHoursError',
  StockManagementUnavailabilityError = 'StockManagementUnavailabilityError',
}

export type DraftOrderItemDto = ItemDto & {
  readonly quantity: number
  readonly errors: { name: OrderItemError }[]
  readonly categoryOperatingHours?: OperatingHours
}

export type DraftInvoiceDto = {
  readonly delivery: {
    readonly fees: number
  }
  readonly taxLines: ReadonlyArray<{
    readonly value: number
    readonly title: Localizable
  }>
  readonly subTotalPrice: number
  readonly totalTax: number
  readonly totalPrice: number
  readonly gratuity: {
    readonly type?: GratuityType
    readonly value?: number
    readonly amount: number
  }
}

export type DraftOrderDto = DraftInvoiceDto & { readonly items: Readonly<DraftOrderItemDto>[] }
