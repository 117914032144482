import { useEffect } from 'react'
import { pageview } from 'react-ga'
import { useLocation } from 'react-router-dom'

export const usePageTracking = (): void => {
  const location = useLocation()

  useEffect(() => {
    pageview(location.pathname + location.search)
  }, [location])
}
