import { localize, SupportedLocales } from '@district-qg/frontend-common'

import { Artisan, ArtisanModel } from '@/app/artisan/Artisan.model'
import { CategoryDto } from '@/app/category/Category.dto'
import { Group, GroupModel } from '@/app/group/Group.model'

import { extractCurrentOperatingSchedules } from '../operatingHours/extractCurrentOperatingSchedules'
import { OperatingHoursSchedule } from '../types/operatingHours'

export interface Category {
  readonly artisans: ReadonlyArray<Artisan>
  readonly groups: ReadonlyArray<Group>
  readonly id: string
  readonly sectionId: string
  readonly mainImage: {
    readonly id?: string
    readonly altText: string
  }
  readonly subtitle: string
  readonly title: string
  readonly dailySchedule: {
    online: OperatingHoursSchedule[]
    onsite: OperatingHoursSchedule[]
  }
}

export class CategoryModel implements Category {
  readonly groups = this.data.groups.map((groupDto) => new GroupModel(groupDto, this.locale))

  readonly artisans = this.data.artisans.map((artisanDto) => new ArtisanModel(artisanDto, this.locale))

  readonly mainImage = {
    id: this.data.mainImage?.id,
    altText: localize([this.data.mainImage?.altText, this.data.title], this.locale),
  }

  constructor(private readonly data: Readonly<CategoryDto>, private readonly locale: SupportedLocales) {}

  get id(): string {
    return this.data.id
  }

  get sectionId(): string {
    return this.data.sectionId
  }

  get title(): string {
    return localize(this.data.title, this.locale)
  }

  get subtitle(): string {
    return localize(this.data.subtitle, this.locale, 'category subtitle')
  }

  get order(): number {
    return this.data.order
  }

  get dailySchedule(): Category['dailySchedule'] {
    return {
      online: extractCurrentOperatingSchedules(this.data.operatingHours.online),
      onsite: extractCurrentOperatingSchedules(this.data.operatingHours.onsite),
    }
  }
}

export function buildCategoryModel(data: CategoryDto, locale: SupportedLocales): Category
export function buildCategoryModel(data: CategoryDto[], locale: SupportedLocales): Category[]
export function buildCategoryModel(data: CategoryDto[] | CategoryDto, locale: SupportedLocales): Category[] | Category {
  return Array.isArray(data)
    ? data.map((category) => buildCategoryModel(category, locale))
    : new CategoryModel(data, locale)
}
