import React from 'react'

import { DraftOrder } from '@/app/order/DraftOrder.model'
import { ModalItemList } from '@/ui/components/modal/ModalItemList'
import { CartItem } from '@/ui/pages/cart/components/CartItem'

export type OnlineCartItemListProps = {
  draftOrder: DraftOrder
}

export const OnlineCartItemList: React.FC<OnlineCartItemListProps> = ({ draftOrder }) => (
  <ModalItemList outerDividers>
    {draftOrder.items.map((item, index) => (
      <CartItem
        orderItem={item}
        // eslint-disable-next-line react/no-array-index-key
        key={`${item.id}-${index}`}
      />
    ))}
  </ModalItemList>
)
