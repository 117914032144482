import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCart } from 'react-use-cart'

import { useToast } from '@chakra-ui/react'
import { errorMessage, useOnClosePushHistory } from '@district-qg/frontend-common'

import { PayFactoProvider } from '@/app/context/payFacto/PayFactoContext'
import { ONLINE_ROUTES } from '@/routes'
import { OnlineCartModal } from '@/ui/pages/online/cart/OnlineCartModal'

export const OnlineCartPage: React.FC = () => {
  const toast = useToast()
  const { t } = useTranslation('payment')
  const onClose = useOnClosePushHistory(ONLINE_ROUTES.HOME)
  const { emptyCart } = useCart()

  return (
    <PayFactoProvider
      onError={(error) => {
        toast({
          title: t('error.title'),
          description: errorMessage(error),
          status: 'error',
          isClosable: true,
          position: 'top',
        })
      }}
      onSuccess={() => {
        emptyCart()
      }}
    >
      <OnlineCartModal isOpen onClose={onClose} />
    </PayFactoProvider>
  )
}
