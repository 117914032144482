import * as React from 'react'

import { Box, Flex, Heading, HStack } from '@chakra-ui/react'

import { useSelectedSection } from '@/app/selection/useSelectedSection'
import { ZoneType } from '@/app/zone/ZoneType'
import { FlixGroupSlider } from '@/ui/components/flix/FlixGroupSlider'

export const MarketZone: React.FC = () => {
  const { section } = useSelectedSection(ZoneType.market)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(0)

  if (!section) return null

  return (
    <Box pt={8} pb={14} backgroundColor='creme.100' data-testid='zone-market'>
      <Flex px={{ base: '4', md: '8' }}>
        <HStack spacing={8} pb={2} mb={3} borderBottom='1px solid' borderColor='gray.400'>
          {section.categories.map((category, index) => (
            <Heading
              data-testid={`category-tab-${index}`}
              key={category.title}
              whiteSpace='nowrap'
              fontSize='xl'
              _hover={{ color: 'aubergine.800' }}
              color={index === selectedCategoryIndex ? 'aubergine.800' : 'gray.400'}
              cursor={index === selectedCategoryIndex ? 'inherit' : 'pointer'}
              onClick={() => setSelectedCategoryIndex(index)}
            >
              {category.title}
            </Heading>
          ))}
        </HStack>
      </Flex>

      <FlixGroupSlider category={section.categories[selectedCategoryIndex]} px={{ base: '4', md: '8' }} />
    </Box>
  )
}
