import React from 'react'
import { useTranslation } from 'react-i18next'

import _isEmpty from 'lodash/isEmpty'
import { Text } from '@chakra-ui/react'

import { OperatingHoursSchedule } from '@/app/types/operatingHours'

export const ItemAvailabilityMessage: React.FC<{ operatingHoursSchedule: OperatingHoursSchedule[] }> = ({
  operatingHoursSchedule,
}) => {
  const { t } = useTranslation(['cart'])

  if (_isEmpty(operatingHoursSchedule))
    return (
      <Text color='error.dark' fontSize='sm' fontWeight='bold'>
        {t(`cart:content.item.errors.unavailableToday`)}
      </Text>
    )

  return (
    <Text color='error.dark' fontSize='sm' fontWeight='bold'>
      {t('cart:items.availableFrom', {
        val: operatingHoursSchedule.map((schedule) =>
          t('cart:items.value', { returnObjects: true, open: schedule.open, close: schedule.close })
        ),
      })}
    </Text>
  )
}
