import { useMemo } from 'react'

import { UseAxiosMutationOptions, useMutation, UseMutationExtendedResult } from '@district-qg/frontend-common'

import { PaymentSessionDto } from './PaymentSession.dto'
import { buildPaymentSession, PaymentSession } from './PaymentSession.model'

export type UsePaymentSessionMutationOptions = UseAxiosMutationOptions<PaymentSessionDto>

export const usePaymentSessionMutation = (
  options?: UsePaymentSessionMutationOptions
): UseMutationExtendedResult<PaymentSessionDto> & { paymentSession?: PaymentSession } => {
  const mutation = useMutation({
    ...options,
    method: 'post',
    path: '/v1/payment',
  })
  const paymentSession = useMemo(() => mutation.data && buildPaymentSession(mutation.data), [mutation.data])

  return { ...mutation, paymentSession }
}
