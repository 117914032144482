import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { HistoryState, useAuth0Audience } from '@district-qg/frontend-common'

import { PROFILE_PAGE_PATH } from '@/routes'
import { ProfileIconButton } from '@/ui/organisms/profile/ProfileIconButton'

export const ProfileLink: React.FC = () => {
  const { i18n } = useTranslation()
  const audience = useAuth0Audience()
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const { push } = useHistory<HistoryState>()

  const onProfileClick = () => {
    if (!isAuthenticated) loginWithRedirect({ ui_locales: i18n.language, audience })
    else push(PROFILE_PAGE_PATH)
  }

  return <ProfileIconButton data-testid='profile' isAuthenticated={isAuthenticated} onClick={onProfileClick} />
}
