import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import { Alert } from './components/alert'
import { Button } from './components/button'
import { Checkbox } from './components/checkbox'
import { FormLabel } from './components/formLabel'
import { Heading } from './components/heading'
import { Input } from './components/input'
import { Link } from './components/link'
import { NumberInput } from './components/numberInput'
import { colors } from './colors'
import { styles } from './styles'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  config,

  fonts: {
    body: 'Inter, "Trebuchet MS", Tahoma, sans-serif',
    heading: '"Barlow Condensed", "Arial Narrow", sans-serif',
  },

  breakpoints: createBreakpoints({
    xs: '20em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }),

  colors,
  styles,
  shadows: {
    'text-protect': '0px 1px 3px rgba(0, 0, 0, 0.7)',
  },

  components: {
    Button,
    Checkbox,
    FormLabel,
    Heading,
    Input,
    NumberInput,
    Link,
    Alert,
  },
})
