import { AxiosError } from 'axios'

import { isAxiosError } from './isAxiosError'

export function errorMessage(error: Error | AxiosError): string {
  if (isAxiosError(error)) {
    return error.response?.data?.message ?? error.message
  }
  return error.message
}
