export enum HeadingVariants {
  kicker = 'kicker',
}

export const Heading = {
  baseStyle: {
    textTransform: 'uppercase',
  },
  sizes: {
    xs: {
      fontSize: '.875rem',
      lineHeight: 'none',
    },
    sm: {
      fontSize: '1rem',
      lineHeight: 'none',
    },
    md: {
      fontSize: '1.25rem',
      lineHeight: 'none',
    },
    lg: {
      fontSize: '1.5rem',
      lineHeight: 'none',
    },
    xl: {
      fontSize: '1.75rem',
      lineHeight: 'none',
    },
    '2xl': {
      fontSize: '2.25rem',
      lineHeight: 'none',
    },
    '3xl': {
      fontSize: '2.625rem',
      lineHeight: 'none',
    },
    '4xl': {
      fontSize: '3rem',
      lineHeight: 'none',
    },
  },
  variants: {
    [HeadingVariants.kicker]: {
      fontWeight: '400',
      color: 'peche.200',
    },
  },
}
