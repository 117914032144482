import * as React from 'react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

type ScrollPositionSpyProps = {
  callback: () => void
  offsetPx?: number
  throttleMs?: number
}

export const ScrollPositionSpy: React.FC<ScrollPositionSpyProps> = ({
  callback,
  offsetPx = 0,
  throttleMs,
  children,
}) => {
  const elementRef = React.useRef() as React.MutableRefObject<HTMLDivElement>

  /* istanbul ignore next */
  useScrollPosition(
    () => {
      const elementRect = elementRef.current.getBoundingClientRect()
      if (elementRect.top - offsetPx <= 0 && elementRect.bottom - offsetPx > 0) {
        callback()
      }
    },
    [],
    undefined,
    true,
    throttleMs
  )

  return <div ref={elementRef}>{children}</div>
}
